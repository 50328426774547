import React from "react";
import { Link } from "react-router-dom";
import { LinkTagsProps } from "../../../core/models/interface";

const LinkTags = (props: LinkTagsProps) => {

    return (
        <Link to={props.to} className={props.className} style={props.style}>{props.children}</Link>
    )
};

export default LinkTags;