import React, { useEffect, useRef, useState } from "react";
import * as Icon from 'react-feather';
import en from "../../../en/en";
import { searchBoxProps } from "../../../core/models/interface";
import colors from "../../../colors/colors";
import { GOOGLE_MAPS_PLACES_API } from "../../../constants/constants";
import { useLoadGoogleMapAPI } from "../../../hooks/useLoadGoogleMapAPI";
import { all_routes } from "../../../core/data/routes/all_routes";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../core/data/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSearchRequestData } from "../../../core/data/redux/action";
import './searchstyle.css';

const SearchBox = (props: searchBoxProps) => {

    const routes = all_routes;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchRequestData = useSelector((state: RootState) => state.searchRequestData);
    const inputRef = useRef<HTMLInputElement>(null);

    const [placeName, setPlaceName] = useState<any>(searchRequestData?.place_name ?? '');
    const [lattitude, setLattitude] = useState<number>(searchRequestData?.latitude ?? 0);
    const [longitude, setLongitude] = useState<number>(searchRequestData?.longitude ?? 0);
    const [queryValue, setQueryValue] = useState<string>(searchRequestData?.query ?? '');

    const isLoaded = useLoadGoogleMapAPI(GOOGLE_MAPS_PLACES_API);

    useEffect(() => {

        setLattitude(searchRequestData?.latitude ?? '');
        setLongitude(searchRequestData?.longitude ?? '');
        setPlaceName(searchRequestData?.place_name ?? '');
    }, [searchRequestData]);

    useEffect(() => {

        if (isLoaded && inputRef?.current && (window as any).google && (window as any).google.maps && (window as any).google.maps) {
            const searchBox = new (window as any).google.maps.places.SearchBox(inputRef.current);

            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places != null && places.length > 0) {

                    const place = places[0];
                    const formattedAddress = place.formatted_address || '';
                    const placeName = place.name || '';
                    const lat = place.geometry?.location?.lat();
                    const lng = place.geometry?.location?.lng();

                    if (lat && lng) {
                        setLattitude(lat);
                        setLongitude(lng);
                    }

                    setPlaceName(formattedAddress || placeName);
                }
            });
        }
    }, [isLoaded, inputRef?.current?.value]);

    const handleCloseButton = () => {
        setLattitude(0);
        setLongitude(0);
        setPlaceName('');

        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(0, 0);
        }
    }

    const handleKeywordCloseButton = () => {
        setQueryValue('');
    }

    const handleSubmit = () => {

        dispatch(setSearchRequestData({
            ...searchRequestData,
            latitude: lattitude,
            longitude: longitude,
            place_name: placeName,
            query: queryValue
        }));

        navigate(routes.search, { state: { lat: lattitude, lng: longitude, query: queryValue, locationValue: placeName } });
    }

    const handleKeyDown = (event: any) => {

        if (event.key === 'Enter') {

            handleSubmit;
        }
    }

    return (
        <React.Fragment>
            <div className="search-box">
                {!isLoaded ? (
                    <div>{en.LOADING}</div>
                ) : (
                    <form>
                        <div className="search-input line yourloc-custom">
                            <div className="search-group-icon">
                                <Icon.MapPin className="standard-feather" style={{ color: colors.web_primary_color }} />
                            </div>
                            <div className="form-group mb-0" style={{ position: 'relative' }}>
                                <label>{en.YOUR_LOCATION}</label>
                                <input
                                    ref={inputRef}
                                    type="text"
                                    className="form-control"
                                    placeholder="All Countries"
                                    onChange={e => setPlaceName(e.target.value)}
                                    value={placeName}
                                    style={{ paddingRight: '35px' }}
                                />
                                {placeName && (
                                    <Icon.XCircle
                                        className="close-icon-1"
                                        onClick={handleCloseButton}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="search-input whattolook-custom">
                            <div className="search-group-icon search-icon">
                                <Icon.Search className="react-feather-custom" style={{ color: colors.web_primary_color }} />
                            </div>
                            <div className="form-group mb-0">
                                <label>{en.WHAT_TO_LOOK}</label>
                                <input
                                    type="text"
                                    value={queryValue}
                                    className="form-control"
                                    placeholder="Car Repair Services"
                                    onChange={e => setQueryValue(e.target.value)}
                                />
                                {queryValue && (
                                <Icon.XCircle
                                        className="close-icon-1"
                                        onClick={handleKeywordCloseButton}
                                />
                                )}
                            </div>
                        </div>
                        <div className="search-btn">
                            <button className="btn btn-primary flex-row-reverse" type="submit" onKeyDown={handleKeyDown} onClick={handleSubmit}>
                                {en.SEARCH}
                                <Icon.Search className="reaact-feather-custom standard-feather me-2" />
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </React.Fragment>
    )
};

export default SearchBox;