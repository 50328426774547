import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Nullable } from 'primereact/ts-helpers';
import {
    ImageWithBasePathWithUrl,
} from '../../../../core/img/ImageWithBasePath';
import {
    TKN_InputPlaceholderEdit,
    TKN_DropdownInputvalueedit,
    PhoneInputComponent,
    TKN_TextInput,
    TKN_LocationMap
} from '../../../components/inputfields/input';
import {
    ButtonTextOnly_Colored,
} from '../../../components/buttons/button';
import { ChevronDown, MapPin } from 'react-feather';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import apiService from '../../../../api/authentication-service';
import { setVendorDetails } from '../../../../core/data/redux/action';
import {
    CHAR_LIMIT_ADDRESS,
    CHAR_LIMIT_BUSINESS_NAME,
    CHAR_LIMIT_CATEGORY,
    CHAR_LIMIT_DESC,
    CHAR_LIMIT_EMAIL_WEBSITE,
    CHAR_LIMIT_FIRST_NAME,
    CHAR_LIMIT_PLACE,
    CHAR_ZIPCODE,
    GOOGLE_MAPS_API_KEY,
    WEB_BASE_URL
} from '../../../../constants/constants';
import {
    validateAddress,
    validateDropdown,
    validateEmail,
    validateEmptyValue,
    validateMobileNumber,
    validateUsername,
    validateBusinessName,
    validateZipCodeByCountry
} from '../../../../helper/validate';
import { useNavigate } from 'react-router-dom';
import { CountryDropdown } from 'react-country-region-selector';
import countries from 'world-countries/countries.json';
import imageCompression from 'browser-image-compression';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Spinner } from 'react-bootstrap';
import { TKN_Danger_message } from '../../../components/alert_messages/alert_messages';
import en from '../../../../en/en';

type Option = {
    label: string;
    value: number;
};

type ErrorMessages = {
    general?: string;
    location?: string;
};

const ProviderBusinessProfile = () => {
    const routes = all_routes;
    const [date, setDate] = useState<Nullable<Date>>(null);
    const [allCategory, setAllCategory] = useState<Option[]>([]);
    const [errors, setErrors] = useState<ErrorMessages>({});
    const [categoryName, setCategoryName] = useState<string>('');
    const [allSubCategory, setAllSubCategory] = useState<Option[]>([]);
    const [subcatoptions, setSubcatoptions] = useState<Option[]>([]);
    const [subCategoryName, setSubCategoryName] = useState<string>('');
    const [initialCategory, setInitialCategory] = useState<string | null>(null);
    const [initialSubCategory, setInitialSubCategory] = useState<string | null>(null);
    const [selected, setSelected] = useState('');
    const [selectedsub, setSelectedsub] = useState('');
    const [isSubCategoryValue, setSubCategoryValue] = useState(false);
    const [categorytextError, setCategorytextError] = useState('');
    const [categorysubtextError, setCategorysubtextError] = useState('');
    const [businessNameError, setBusinessNameError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [stateError, setStateError] = useState('');
    const [cityError, setCityError] = useState('');
    const [postalcodeError, setPostalCodeError] = useState('');
    const [dropdownIndustryError, setDropdownIndustryError] = useState('');
    const [dropdownSubCategoryError, setDropdownSubCategoryError] = useState('');
    const [ownernameError, setOwnernameError] = useState('');
    const [mobilenumberError, setMobileNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [address2Error, setAddress2Error] = useState('');
    const [websiteError, setWebsiteError] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [catoptions, setCatoptions] = useState<Option[]>([]);

    const user_obj = useSelector((state: RootState) => state.user);
    const vendor_obj = useSelector((state: RootState) => state.vendor);

    const country_details_json_value = vendor_obj.country_details;
    const country_details_parsed_obj = country_details_json_value ? JSON.parse(country_details_json_value) : null;
    const Country_Name = country_details_parsed_obj?.name;
    const Category_id = vendor_obj.category_id;

    const [businessName, setBusinessName] = useState(vendor_obj.bussiness_name)
    const [ownerName, setOwnerName] = useState(vendor_obj.owner_name)
    const [website, setWebsite] = useState(vendor_obj.website)
    const [bemail, setbEmail] = useState(vendor_obj.vendor_email)
    const [description, setDescription] = useState(vendor_obj.description)
    const [phonenumber, setPhoneNumber] = useState(vendor_obj.mobile)
    const [countrycode, setCountryCode] = useState(vendor_obj.country_code)
    const [baddress1, setbAddress1] = useState(vendor_obj.bussiness_address)
    const [baddress2, setbAddress2] = useState(vendor_obj.address)
    const [bstate, setbState] = useState(vendor_obj.State)
    const [bcity, setbCity] = useState(vendor_obj.city)
    const [postalcode, setPostalcode] = useState(vendor_obj.Zipcode)
    const [bcountry, setbCountry] = useState(vendor_obj.country)
    const [category, setCategory] = useState(vendor_obj.category_id)
    const [subcategory, setSubCategory] = useState(vendor_obj.subcategory_id)
    const [otherCategory, setOtherCategory] = useState('');
    const [otherSubCategory, setOtherSubCategory] = useState('');
    const selectDropdownRef = useRef<any>(null);
    const [countryDetails, setCountryDetails] = useState<any>();
    const [phoneCountryName, setPhoneCountryName] = useState<any>(Country_Name);
    const [businessSelectedImage, setBusinessSelectedImage] = useState<string[] | null>(null);
    const [region, setRegion] = useState(vendor_obj.country_details ? JSON.parse(vendor_obj.country_details).region : null);
    const [serverError, setServerError] = useState<string | null>('');
    const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);
    const [selectedOptionvalue, setSelectedOptionvalue] = useState<number | null>(null);
    const [selectedsubOption, setSelectedsubOption] = useState<Option | undefined>(undefined);
    const [selectedsubOptionvalue, setSelectedsubOptionvalue] = useState<number | null>(null);
    const [maplatitude, setMaplatitude] = useState<number>(0);
    const [maplongitude, setMaplongitude] = useState<number>(0);
    //const [timezone, setTimezone] = useState<any>(null);
    const [timezone, setTimezone] = useState(vendor_obj.timezone);

    const URL_REGEX = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#?&=_-]*)?$/;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user_obj.dob) {
            setDate(new Date(user_obj.dob));
        }
    }, [user_obj.dob]);

    //console.error('VENDOR time ', vendor_obj.timezone);


    useEffect(() => {
        setBusinessName(vendor_obj.bussiness_name)
        setOwnerName(vendor_obj.owner_name)
        setWebsite(vendor_obj.website)
        setbEmail(vendor_obj.vendor_email)
        setDescription(vendor_obj.description)
        setPhoneNumber(vendor_obj.mobile)
        setCountryCode(vendor_obj.country_code)
        setbAddress1(vendor_obj.bussiness_address)
        setbAddress2(vendor_obj.address)
        setbState(vendor_obj.State)
        setbCity(vendor_obj.city)
        setPostalcode(vendor_obj.Zipcode)
        setbCountry(vendor_obj.country)
        setCategory(vendor_obj.category_id)
        setOtherCategory(vendor_obj.category_name)
        setSubCategory(vendor_obj.subcategory_id)
        setCountryDetails(country_details_parsed_obj)
        setMaplatitude(vendor_obj.latitude)
        setMaplongitude(vendor_obj.longitude)
        setTimezone(vendor_obj.timezone)
    }, [user_obj]);

    useEffect(() => {
        sendGetAllCategoryRequest();
    }, [setAllCategory]);

    useEffect(() => {

        if (Category_id) {
            sendGetAllSubCategoryRequest(Category_id);
        };
    }, [Category_id]);

    useEffect(() => {
        listCategory(category);
    }, [allCategory]);

    useEffect(() => {
        listSubCategory(subcategory)
    }, [allSubCategory]);

    useEffect(() => {

        if (selected.toLowerCase() === 'other') {
            setCategory(0);
        };
    }, [selected]);

    useEffect(() => {

        if (selectedsub.toLowerCase() === 'other') {
            setSubCategory(0);
        };
    }, [selectedsub]);

    useEffect(() => {

        if (initialSubCategory === null && isSubCategoryValue === true) {
            resetDropdown();
        };
    }, [category]);

    const resetResponseMessage = () => {
        setResponseMessage
    };

    const resetDropdown = () => {
        if (selectDropdownRef?.current) {
            selectDropdownRef?.current.reset();
            setSubCategoryValue(false);
        };
    };

    const getUpdatePayload = () => ({
        bussiness_name: businessName,
        bussiness_address: baddress1,
        address: baddress2,
        country: bcountry,
        State: bstate,
        city: bcity,
        Zipcode: postalcode,
        website: website,
        category_id: category,
        subcategory_id: subcategory,
        category_name: otherCategory,
        subcategory_name: otherSubCategory,
        owner_name: ownerName,
        mobile: phonenumber,
        country_code: countrycode,
        country_details: JSON.stringify(countryDetails),
        vendor_email: bemail.toLowerCase(),
        description: description,
        vendor_id: vendor_obj.id,
        business_id: vendor_obj.id ?? '',
        photo: businessSelectedImage ? JSON.stringify(businessSelectedImage.map(image => image.split(',')[1])) : '',
        timezone: timezone ? timezone : null,
        latitude: maplatitude ? maplatitude : null,
        longitude: maplongitude ? maplongitude : null,
        region: region
    });

    const handleUpdateResponse = (response: any) => {
        setIsLoading(false);
        if (response?.data?.status === false) {
            console.error('Update failed:', response.data.message);
            setServerError('Failed to update business details. Please try again later')
        } else if (response?.data?.status && response?.data?.status === true) {
            console.log('Update successful:', response.data.data);
            navigate(routes.vendorAppointmentSettings)
        } else {
            console.error('Unexpected response status:', response.status);
            setErrors({ general: response?.message });
            setServerError('Failed to update business details. Please try again later')
        }
    };

    const sendUpdateRequest = () => {

        const payload = getUpdatePayload();
        console.error('PAYLOAD', payload);

        apiService.updateBusinessDetails(payload)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setVendorDetails(response.data.data));
                    handleUpdateResponse(response);
                } else {
                    console.error('Unexpected response status:', response.status);
                }
            }).catch((error: any) => {
                console.error('Error updating personal details:', error);
                setErrors({ general: 'Failed to update business details' });
                setServerError('Failed to update business details')
            });
    };

    const handleSave = () => {
        let isValid = true;
        const mobileNumber = phonenumber.startsWith(countrycode) ? phonenumber.slice(countrycode.length) : phonenumber;

        const fieldsToValidate = [
            { value: businessName, errorSetter: setBusinessNameError, validationFunction: [validateEmptyValue, validateBusinessName], fieldName: 'Business name' },
            { value: ownerName, errorSetter: setOwnernameError, validationFunction: [validateEmptyValue, validateUsername], fieldName: 'Owner name' },
            { value: baddress1, errorSetter: setAddressError, validationFunction: [validateEmptyValue, validateAddress], fieldName: 'Address' },
            { value: bcountry, errorSetter: setCountryError, validationFunction: [validateEmptyValue], fieldName: 'Country' },
            { value: bstate, errorSetter: setStateError, validationFunction: [validateEmptyValue], fieldName: 'State' },
            { value: bcity, errorSetter: setCityError, validationFunction: [validateEmptyValue], fieldName: 'City' },
            { value: category, errorSetter: setDropdownIndustryError, validationFunction: [validateDropdown], fieldName: 'Category' },
            { value: subcategory, errorSetter: setDropdownSubCategoryError, validationFunction: [validateDropdown], fieldName: 'Subcategory' },
            { value: bemail.toLowerCase(), errorSetter: setEmailError, validationFunction: [validateEmptyValue, validateEmail], fieldName: 'Email' },
            { value: description, errorSetter: setDescriptionError, validationFunction: [validateEmptyValue,], fieldName: 'Description' },
            ...(category === 0 ? [{
                value: otherCategory || '', // Ensuring it's a string
                errorSetter: setCategorytextError,
                validationFunction: [validateEmptyValue],
                fieldName: 'OtherCategory'
            }] : []),

            ...(subcategory === 0 ? [{
                value: otherSubCategory || '', // Ensuring it's a string
                errorSetter: setCategorysubtextError,
                validationFunction: [validateEmptyValue],
                fieldName: 'OtherSubCategory'
            }] : []),

            // ...(subcategory === 0 ? [{ value: otherSubCategory, errorSetter: setCategorysubtextError, validationFunction: [validateEmptyValue], fieldName: 'OtherSubCategory' }] : []),

        ];

        fieldsToValidate.forEach((field => {
            for (const validationFunction of field.validationFunction) {
                const validationResult = validationFunction(field.value, field.fieldName);

                if (validationResult.status === 'false') {
                    field.errorSetter(validationResult.error);
                    isValid = false;
                    break;
                }
            }
        }));

        const mobileNumberValidateResult = validateMobileNumber(mobileNumber, countrycode, 'Country code and phone number mismatch. Please verify.');
        console.log("MObile validate1", mobileNumberValidateResult.status);

        if (mobileNumberValidateResult.status === 'false') {
            setMobileNumberError(mobileNumberValidateResult.error);
            isValid = false;
        } else {
            setMobileNumberError('');
        };

        const zipCodeValidationResult = validateZipCodeByCountry(bcountry, postalcode);
        if (zipCodeValidationResult.status === 'false') {
            setPostalCodeError(zipCodeValidationResult.error);
            isValid = false;
        } else {
            setPostalCodeError('');
        };

        if (bcountry && phoneCountryName) {
            if (bcountry != phoneCountryName) {
                setMobileNumberError("Country and phone number mismatch. Please verify.")
                isValid = false;
            };
        }

        if (isValid) {
            setIsLoading(true);
            sendUpdateRequest();

        };
    };

    const sendGetAllCategoryRequest = () => {
        apiService.listCategories().then((response) => {
            const categoryid = vendor_obj.category_id;

            getAllCategoryResponse(response?.data, categoryid);
        }).catch((error) => {
            console.log('error', error);
        });
    };

    const getAllCategoryResponse = (response: any, id: any) => {
        if (response) {
            const status = response?.status;

            if (status && status === true) {
                const responseData = response?.data;

                if (responseData) {

                    const categoryOptions = Object.entries(responseData).map(([category_name, category_id]) => ({
                        label: category_name,
                        value: category_id as number,
                    }));
                    setCatoptions(categoryOptions);

                    const matchingCategory = categoryOptions.find(option => option.value === Number(id));

                    if (matchingCategory) {
                        // Set the matching category name in state or wherever needed
                        setCategoryName(matchingCategory.label);
                        console.log("Matching Category Name:", matchingCategory.label);
                    } else {
                        console.log("No matching category found.");
                    }

                    setAllCategory(categoryOptions);


                };
            };
        };
    };



    const sendGetAllSubCategoryRequest = (category_id: any) => {
        const payload = { category_id };
        console.log('C ID', payload);

        apiService.getAllSubcategories(payload).then((response) => {

            console.log('SUB ID', vendor_obj.subcategory_id);
            let subcategoryid = 0;

            if (category_id > 0) {
                subcategoryid = vendor_obj.subcategory_id;
            }

            getAllSubCategoryResponse(response?.data, subcategoryid);
        }).catch((error) => {
            console.log('error', error);
        });
    };

    const getAllSubCategoryResponse = (response: any, id: any) => {
        if (response) {
            const status = response?.status;
            setSubcatoptions([]);

            if (status && status === true) {
                const responseData = response?.data;

                if (responseData) {

                    const subcategoryOptions = Object.entries(responseData).map(([subcategory_name, subcategory_id]) => ({
                        label: subcategory_name,
                        value: subcategory_id as number,
                    }));

                    setSubcatoptions(subcategoryOptions);

                    const matchingCategory = subcategoryOptions.find(option => option.value === Number(id));
                    if (matchingCategory) {
                        // Set the matching category name in state or wherever needed
                        setSubCategoryName(matchingCategory.label);
                        console.log("Matching SUB Category Name:", matchingCategory.label);
                    } else {
                        console.log("No matching sub category found.");
                    }



                };
            };
        };
    };

    const combinedOptions = [
        ...catoptions,
        { label: 'Others', value: 0 },
    ];

    const combinedsubOptions = [
        ...subcatoptions,
        { label: 'Others', value: 0 },
    ];

    const listCategory = (targetValue: any) => {
        for (const [Category, value] of Object.entries(allCategory)) {

            if (value === targetValue) {
                setInitialCategory(Category);
                return ('Result from listCategory');
            }
        }
    };

    const listSubCategory = (targetValue: any) => {
        if (allSubCategory) {

            for (const [Subcategory, value] of Object.entries(allSubCategory)) {

                if (value === targetValue) {
                    setInitialSubCategory(Subcategory);
                    return 'Result from listSubCategory';
                }
            }
        } else {
            console.warn('allSubCategory is empty or undefined.');
        };
    };

    /* const setCategoryId = (selectedCategory: any) => {
        if (selectedCategory) {
            const selectedCategoryId = allCategory[selectedCategory];

            if (selectedCategoryId) {
                setCategory(selectedCategoryId);
                setAllSubCategoryLabel([]);
                sendGetAllSubCategoryRequest(selectedCategoryId);
            }
            else {
                setCategory(0);
                sendGetAllSubCategoryRequest(0);
                setAllSubCategoryLabel([]);
            }
        };
    }; */

    /* const setSubCategoryId = (selectedSubCategory: any) => {
        if (selectedSubCategory) {
            const selectedSubCategoryId = allSubCategory[selectedSubCategory];

            if (selectedSubCategoryId) {
                setSubCategory(selectedSubCategoryId);
            }
            else {
                setSubCategory(0);
            };
        };
    }; */

    const handleCategoryotherChange = (value: string) => {
        setOtherCategory(value);
    };

    const handleSubcategoryotherChange = (value: string) => {
        setOtherSubCategory(value);
    };

    const handleSelect = (selectedItem: Option) => {

        setSelectedOption(selectedItem);
        setSelectedOptionvalue(selectedItem.value);
        setCategory(selectedItem.value);
        setCategoryName(selectedItem.label);
        console.log("select other.", selectedItem.value);

        sendGetAllSubCategoryRequest(selectedItem.value);

    };

    const handleSelectsub = (selectedItem: Option) => {

        setSelectedsubOption(selectedItem);
        setSelectedsubOptionvalue(selectedItem.value);
        setSubCategory(selectedItem.value);
        setSubCategoryName(selectedItem.label);

    };

    const handleBusinessNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;

        if (text.length <= CHAR_LIMIT_BUSINESS_NAME - 1) {
            setBusinessName(text);
            setBusinessNameError('');
        } else {
            setBusinessNameError(en.CHAR_LIMIT);
        };
    };

    const handleOwnerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;

        if (text.length <= CHAR_LIMIT_FIRST_NAME - 1) {
            setOwnerName(text);
            setOwnernameError('');
        } else {
            setOwnernameError(en.CHAR_LIMIT);
        }
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;

        if (text.length <= CHAR_LIMIT_DESC - 1) {
            setDescription(text);
            setDescriptionError('');
        } else {
            setDescriptionError(en.CHAR_LIMIT);
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;

        if (text.length <= CHAR_LIMIT_EMAIL_WEBSITE - 1) {
            setbEmail(text);
            setEmailError('');
        } else {
            setEmailError(en.CHAR_LIMIT);
        }
    };

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        if (text.length <= CHAR_LIMIT_ADDRESS - 1) {
            setbAddress1(text)
            setAddressError('');
        } else {
            setAddressError(en.CHAR_LIMIT);
        }
    };

    const handleAddressChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        if (text.length <= CHAR_LIMIT_ADDRESS - 1) {
            setbAddress2(text)
            setAddress2Error('');
        } else {
            setAddress2Error(en.CHAR_LIMIT);
        }
    };

    const handleWebsiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let text = event.target.value;

        if (text.length > CHAR_LIMIT_EMAIL_WEBSITE - 1) {
            setWebsiteError(en.CHAR_LIMIT);
            return;
        }

        if (text && !/^https?:\/\//i.test(text)) {
            text = `https://${text}`;
        }

        if (text && !URL_REGEX.test(text)) {
            setWebsiteError('Invalid website URL format');
            return;
        }

        setWebsite(text);
        setWebsiteError('');
    };


    const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        if (text.length <= CHAR_LIMIT_PLACE - 1) {
            setbState(text)
            setStateError('');
        } else {
            setStateError(en.CHAR_LIMIT);
        };
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        if (text.length <= CHAR_LIMIT_PLACE - 1) {
            setbCity(text)
            setCityError('');
        } else {
            setCityError(en.CHAR_LIMIT);
        };
    };
    const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        if (text.length <= CHAR_ZIPCODE - 1) {
            setPostalcode(text)
            setPostalCodeError('');
        } else {
            setPostalCodeError(en.CHAR_LIMIT);
        };
    };

    const handleCountryChange = (val: string) => {
        resetResponseMessage();
        if (!val) {
            setCountryError('Unable to select country. Please try again.');
            setbCountry(null);
        } else {
            setCountryError('');
            setbCountry(val);
        };
    };

    const handlePhoneChange = (value: any, country: any) => {

        const dialCode = country.dialCode;
        const formattedDialCode = `+${dialCode}`;
        const CountryCode = country.countryCode;
        const countryCodeUppercase = CountryCode.toUpperCase();
        const CountryDetails = countries.find(country => country.cca2 === countryCodeUppercase);

        if (CountryDetails) {

            const transformedCountryDetails = {
                cca2: CountryDetails.cca2,
                currency: Object.keys(CountryDetails.currencies),
                callingCode: dialCode,
                region: CountryDetails.region,
                subregion: CountryDetails.subregion,
                flag: `flag-${CountryDetails.cca2.toLowerCase()}`,
                name: CountryDetails.name.common
            };
            //const newcountrydetails = JSON.stringify(transformedCountryDetails);

            //setCountryDetails(newcountrydetails);
            setCountryDetails(transformedCountryDetails);
            setRegion(transformedCountryDetails.region);
        }

        setPhoneNumber(`+${value}`);
        setCountryCode(formattedDialCode);
        setPhoneCountryName(country.name);
    };

    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        if (text.length <= CHAR_LIMIT_CATEGORY - 1) {
            setCategory(text)
            setCategorytextError('');
        } else {
            setCategorytextError(en.CHAR_LIMIT);
        }
    };

    const handleClose = () => {
        navigate('/vendors/edit-profile')
    };

    const handleBusinessFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setBusinessSelectedImage([]);
            const files = Array.from(event.target.files).slice(0, 3);

            for (const file of files) {
                try {
                    const options = {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true,
                    };
                    const compressedFile = await imageCompression(file, options);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const imageData = reader.result as string;
                        setBusinessSelectedImage(prevImages => prevImages ? [...prevImages, imageData] : [imageData]);
                    };
                    reader.readAsDataURL(compressedFile);
                    event.target.value = '';
                } catch (error) {
                    console.error('Error during compression: ', error)
                }
            };
        } else {
            console.error("You can select a maximum of 3 files.");
        };
    };

    const handlebusinessimagecancel = () => {
        setBusinessSelectedImage(null)
    };

    const handleServerError = () => {
        setServerError('')
    }

    const fetchTimezone = async (lat: number, lng: number) => {
        const timestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
        const apiKey = GOOGLE_MAPS_API_KEY;

        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${apiKey}`
            );

            if (response.data && response.data.timeZoneId) {
                const timezoneData = {
                    dstOffset: response.data.dstOffset.toString(),
                    rawOffset: response.data.rawOffset.toString(),
                    status: response.data.status,
                    timeZoneId: response.data.timeZoneId,
                    timeZoneName: response.data.timeZoneName,
                };
                setTimezone(timezoneData);
            } else {
                console.log('No timezone data found');
            }
        } catch (error) {
            console.error('Error fetching timezone:', error);
        }
    };

    const handleLocationSelect = (lat: number, lng: number) => {
        setMaplatitude(lat);
        setMaplongitude(lng);
        fetchTimezone(lat, lng);

        if (errors.location) {
            setErrors((prevErrors) => ({ ...prevErrors, location: undefined }));
        }

    };




    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {serverError && (<TKN_Danger_message text={serverError} onClick={handleServerError} />)}
                        <div className="widget-title">
                            <h4>Business Profile</h4>
                        </div>
                        <h6 className="user-title">Business Information</h6>
                        <div className="general-info">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Business Name <span className="text-danger">*</span>
                                        </label>
                                        <TKN_InputPlaceholderEdit
                                            placeholderText={'Business name'}
                                            value={vendor_obj.bussiness_name}
                                            onChange={handleBusinessNameChange}
                                            maxLength={CHAR_LIMIT_BUSINESS_NAME}
                                        />
                                        {businessNameError !== '' && (
                                            <div className='errorText'>{businessNameError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Owner Name <span className="text-danger">*</span>
                                        </label>
                                        <TKN_InputPlaceholderEdit
                                            placeholderText={'Owner name'}
                                            value={vendor_obj.owner_name}
                                            onChange={handleOwnerNameChange}
                                            maxLength={CHAR_LIMIT_FIRST_NAME}
                                        />
                                        {ownernameError !== '' && (
                                            <div className='errorText'>{ownernameError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Category <span className="text-danger">*</span>
                                        </label>
                                        <TKN_DropdownInputvalueedit
                                            placeholderText={'Category'}
                                            //options={catoptions}
                                            options={combinedOptions}
                                            onSelect={handleSelect}
                                            selectedValue={categoryName}
                                        />
                                        {categorytextError !== '' && <div className='errorText'>{categorytextError}</div>}

                                        {selectedOptionvalue === 0 && (
                                            <TKN_TextInput
                                                onChange={handleCategoryotherChange}
                                                label=""
                                                placeholder="Category"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Subcategory <span className="text-danger">*</span>
                                        </label>
                                        <TKN_DropdownInputvalueedit
                                            placeholderText={'Subcategory'}
                                            //options={subcatoptions}
                                            options={combinedsubOptions}
                                            onSelect={handleSelectsub}
                                            selectedValue={subCategoryName}
                                        />
                                        {categorysubtextError !== '' && <div className='errorText'>{categorysubtextError}</div>}
                                        {selectedsubOptionvalue === 0 && (
                                            <TKN_TextInput
                                                onChange={handleSubcategoryotherChange}
                                                label=""
                                                placeholder="Sub Category"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Website
                                        </label>
                                        <TKN_InputPlaceholderEdit
                                            placeholderText={'www.example.com'}
                                            value={vendor_obj.website}
                                            onChange={handleWebsiteChange}
                                            maxLength={CHAR_LIMIT_EMAIL_WEBSITE}
                                        />
                                        {websiteError !== '' && (
                                            <div style={{ color: 'red' }}>{websiteError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">Email <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-icon cus-profile">
                                            <TKN_InputPlaceholderEdit
                                                placeholderText={'Email'}
                                                value={vendor_obj.vendor_email}
                                                onChange={handleEmailChange}
                                                maxLength={CHAR_LIMIT_EMAIL_WEBSITE}
                                            />
                                            {emailError !== '' && (
                                                <div className='errorText'>{emailError}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Description <span className="text-danger">*</span>
                                        </label>
                                        <TKN_InputPlaceholderEdit
                                            placeholderText={'Description'}
                                            value={vendor_obj.description}
                                            onChange={handleDescriptionChange}
                                            maxLength={CHAR_LIMIT_DESC}
                                        />
                                        {descriptionError !== '' && (
                                            <div className='errorText'>{descriptionError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <PhoneInputComponent
                                            handlePhoneChange={handlePhoneChange}
                                            required={true}
                                            value={phonenumber}
                                            readOnly={false}
                                            error={mobilenumberError}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 className="user-title">Address </h6>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Address 1 <span className="text-danger">*</span>
                                    </label>
                                    <TKN_InputPlaceholderEdit
                                        placeholderText={'Address1'}
                                        value={vendor_obj.bussiness_address}
                                        onChange={handleAddressChange}
                                        maxLength={CHAR_LIMIT_ADDRESS}
                                    />
                                    {addressError !== '' && (
                                        <div className='errorText'>{addressError}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">Address 2 </label>
                                    <TKN_InputPlaceholderEdit
                                        placeholderText={'Address2'}
                                        value={vendor_obj.address}
                                        onChange={handleAddressChange2}
                                        maxLength={CHAR_LIMIT_ADDRESS}
                                    />
                                    {address2Error !== '' && (
                                        <div style={{ color: 'red' }}>{address2Error}</div>
                                    )}
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">
                                        State <span className="text-danger">*</span>
                                    </label>
                                    <TKN_InputPlaceholderEdit
                                        placeholderText={'State'}
                                        value={vendor_obj.State}
                                        onChange={handleStateChange}
                                        maxLength={CHAR_LIMIT_PLACE}
                                    />
                                    {stateError !== '' && (
                                        <div className='errorText'>{stateError}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">
                                        City <span className="text-danger">*</span>
                                    </label>
                                    <TKN_InputPlaceholderEdit
                                        placeholderText={'City'}
                                        value={vendor_obj.city}
                                        onChange={handleCityChange}
                                        maxLength={CHAR_LIMIT_PLACE}
                                    />
                                    {cityError !== '' && (
                                        <div className='errorText'>{cityError}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Postal Code <span className="text-danger">*</span>
                                    </label>
                                    <TKN_InputPlaceholderEdit
                                        placeholderText={'Postal code'}
                                        value={vendor_obj.Zipcode}
                                        onChange={handlePostalCodeChange}
                                        maxLength={CHAR_ZIPCODE}
                                    />
                                    {postalcodeError !== '' && (
                                        <div className='errorText'>{postalcodeError}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Country <span className="text-danger">*</span>
                                    </label>
                                    <CountryDropdown
                                        value={bcountry}
                                        onChange={handleCountryChange}
                                        classes="form-control"
                                        defaultOptionLabel="Select country"
                                        priorityOptions={['United States']}
                                    />
                                    <ChevronDown className="dropdown-icon" style={{ marginTop: '20px' }} />
                                    {countryError !== '' && (
                                        <div className='errorText'>{countryError}</div>
                                    )}
                                </div>
                            </div>


                            <div className="col-md-6" style={{ paddingBottom: '20px' }}>
                                <label className="col-form-label d-block">
                                    Location <span className="text-danger">*</span>{' '}
                                </label>
                                <TKN_LocationMap
                                    icon={<MapPin className="react-feather-custom" />}
                                    onLocationSelect={handleLocationSelect}
                                    savedLat={maplatitude}
                                    savedLng={maplongitude}
                                    savedAddress=""
                                />
                                {errors.location && <div className="text-danger">{errors.location}</div>}
                            </div>

                            <div className="col-md-6" style={{ paddingBottom: '20px' }}> </div>


                            <div className="col-md-6" style={{ marginTop: '20px' }}>
                                <h6 className="user-title">Business Photo(s) <span className="text-danger">*</span></h6>
                                <div className="pro-picture">
                                    <div className="pro-img-1">
                                        {businessSelectedImage && businessSelectedImage.length > 0 && businessSelectedImage.length < 4 ? (
                                            businessSelectedImage.map((image, index) => (
                                                <img
                                                    key={index}
                                                    src={image}
                                                    alt={`Selected ${index + 1}`}
                                                    className='pro-img-2'
                                                    style={{ width: '90px', height: '90px', marginRight: '15px' }}
                                                />
                                            ))
                                        ) : (
                                            /* vendor_obj.photo.map((photoPath: any, index: any) => (
                                                 <ImageWithBasePathWithUrl
                                                     key={index}
                                                     src={`${WEB_BASE_URL}${photoPath}`}
                                                     alt={"Vendor Business Images"}
                                                     className='pro-img-2'
                                                 />
                                             ))  */
                                            Array.isArray(vendor_obj.photo) && vendor_obj.photo.length > 0 ? (
                                                vendor_obj.photo.map((photoPath: any, index: any) => (
                                                    <ImageWithBasePathWithUrl
                                                        key={index}
                                                        src={`${WEB_BASE_URL}${photoPath}`}
                                                        alt="Vendor Business Images"
                                                        className="pro-img-2"
                                                    />
                                                ))
                                            ) : (
                                                <p>No photos available</p> // Fallback message if no photos are available
                                            )

                                        )}
                                    </div>
                                    <div className="pro-info">
                                        {businessSelectedImage ? (<div className="d-flex">
                                            <div className="img-upload upload-img-custom" style={{ backgroundColor: '#0445cf' }}>
                                                <ButtonTextOnly_Colored label={'Choose 3 files'} route={'#'} fullWidth={false} className='btn-choose-custom' />
                                                <input type="file" multiple accept='image/*' onChange={handleBusinessFileUpload} />
                                            </div>
                                            <div>
                                                <ButtonTextOnly_Colored label={'Remove'} route={'#'} fullWidth={false} className='btn-remove-2-custom' onClick={handlebusinessimagecancel} />
                                            </div>
                                        </div>) : (<div className="d-flex">
                                            <div className="img-upload upload-img-custom" style={{ backgroundColor: '#0445cf' }}>
                                                <ButtonTextOnly_Colored label={'Choose 3 files'} route={'#'} fullWidth={false} className='btn-choose-custom' />
                                                <input type="file" multiple accept='image/*' onChange={handleBusinessFileUpload} />
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="acc-submit">
                            <ButtonTextOnly_Colored
                                label={'Cancel'}
                                route={''}
                                fullWidth={false}
                                style={{ backgroundColor: '#f7f7ff', color: '#0445cf', borderColor: '#f7f7ff', marginRight: '20px' }}
                                onClick={handleClose}
                            />
                            <ButtonTextOnly_Colored
                                label={isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : 'Save'}
                                route={''}
                                fullWidth={false}
                                onClick={handleSave}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProviderBusinessProfile;