import React from 'react';
import { Link } from 'react-router-dom';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import './style.css';

const Pricing = () => {
  const routes = all_routes;
  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg1"
        />
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg2"
        />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Pricing</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Pricing
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="price-toggle">
                <h2>Choose Plan</h2>
                <p id='price_subheading'>No hidden charges Applied, Choose Your Plan</p>
              </div>
            </div>
            {/* Price List */}
            <div className="col-md-3 d-flex">
              <div className="price-card flex-fill">
                <div className="price-head">
                  <div className="price-level">
                    <h6>Sliver</h6>
                  </div>
                  <h1>
                    $20 / month
                  </h1>
                </div>
                <div className="price-body">
                  <ul>
                    <li className="active">3 Months free</li>
                    <li className="active">5 Services</li>
                    <li className="active">Email Support</li>
                  </ul>
                  <div className="text-center">
                    <Link to="#" className="btn btn-choose">
                      Select Plan <i className="feather-arrow-right-circle" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* /Price List */}
            {/* Price List */}
            <div className="col-md-3 d-flex">
              <div className="price-card flex-fill">
                <div className="price-head">
                  <div className="price-level">
                    <h6>Gold</h6>
                  </div>
                  <h1>
                    $40 / month
                  </h1>
                </div>
                <div className="price-body">
                  <ul>
                    <li className="active">1 Month validity </li>
                    <li className="active">10 Services</li>
                    <li className="active">Email Support</li>
                  </ul>
                  <div className="text-center">
                    <Link to="#" className="btn btn-choose">
                      Select Plan <i className="feather-arrow-right-circle" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 d-flex">
              <div className="price-card flex-fill">
                <div className="price-head">
                  <div className="price-level">
                    <h6>Diamond</h6>
                  </div>
                  <h1>
                    $75 / month
                  </h1>
                </div>
                <div className="price-body">
                  <ul>
                    <li className="active">1 Month validity</li>
                    <li className="active">25 Services </li>
                    <li className="active">Email Support</li>
                  </ul>
                  <div className="text-center">
                    <Link to="#" className="btn btn-choose">
                      Select Plan <i className="feather-arrow-right-circle" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Price List */}
            <div className="col-md-3 d-flex">
              <div className="price-card flex-fill">
                <div className="price-head">
                  <div className="price-level">
                    <h6>Platinum</h6>
                  </div>
                  <h1>
                    $150 / month
                  </h1>
                </div>
                <div className="price-body">
                  <ul>
                    <li className="active">1 Month validity</li>
                    <li className="active">30 Services </li>
                    <li className="active">Email Support</li>
                  </ul>
                  <div className="text-center">
                    <Link to="#" className="btn btn-choose">
                      Select Plan <i className="feather-arrow-right-circle" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* /Price List */}
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <div className="price-toggle">
            <h2>Key Features</h2>
            <p id='price_subheading'>Unlocking Excellence & Redefining Convenience</p>
          </div>
          {/* How it works*/}
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="work-wrap work-wrap-acc">
                <span>01</span>
                <h1>Cross Platform</h1>
                <p>
                  Our app seamlessly adapts to Android and iOS platforms, ensuring a consistent experience with robust functionality and a user friendly interface. Our goal is to offer you a versatile, dependable, and enjoyable user experience across both platforms.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-img">
                <ImageWithBasePath
                  src="assets/img/worknew-01.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4  order-last order-md-first">
              <div className="work-img">
                <ImageWithBasePath
                  src="assets/img/worknew-02.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="work-wrap work-wrap-post">
                <span>02</span>
                <h1>Effortless booking</h1>
                <p>Discover the benefits of speed and simplicity on our platform. We assure fast transactions and easy bookings, ensuring a seamless and convenient overall booking experience. Give our services a try today and elevate the way you book!</p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="work-wrap work-wrap-acc">
                <span>03</span>
                <h1>Zero wait guarantee</h1>
                <p>We understand the value of your time, and waiting in lines can be a source of frustration. With our zero-wait guarantee, we eliminate the inconvenience of queues and delays, ensuring a prompt service experience through our consumer app.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-img">
                <ImageWithBasePath
                  src="assets/img/worknew-03.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4  order-last order-md-first">
              <div className="work-img">
                <ImageWithBasePath
                  src="assets/img/worknew-04.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="work-wrap last_wrap">
                <span>04</span>
                <h1>Booking with Peace of Mind</h1>
                <p>Our Queue Booking app takes server and app security to the next level, ensuring your data is as safe. Our app adheres to the latest security standards, incorporating secure APIs for seamless communication between the app and server.</p>
              </div>
            </div>
          </div>
          {/* /How it works */}
        </div>
      </div>
    </>
  );
};

export default Pricing;
