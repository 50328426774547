import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { all_routes } from '../../../../core/data/routes/all_routes';
import HomeHeader from '../../../components/header/home-header';
import FooterOne from '../../../components/footer-one';
import './style.css'

const Faq = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      <HomeHeader type={1} />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Frequently Asked Questions</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Frequently Asked Questions
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content faq-content-custom">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="faq-section">
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqOne"
                      aria-expanded="false"
                    >
                      What is the &ldquo;Take A No&rdquo; Vendor app?
                    </Link>
                  </h4>
                  <div id="faqOne" className="card-collapse collapse">
                    <p>
                      “Take A No” Vendor app is meant for Service providers, where they can list services,
                      add dates and time slots, and attract a large number of consumers to book services online.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="&ldquo;#faqTwo"
                      aria-expanded="false"
                    >
                      How do I use it?
                    </Link>
                  </h4>
                  <div id="faqTwo" className="card-collapse collapse">
                    <p>
                      Download and install it from the Play Store for Android and the App Store for iPhone.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqThree"
                      aria-expanded="false"
                    >
                      How to register into vendor app?
                    </Link>
                  </h4>
                  <div id="faqThree" className="card-collapse collapse">
                    <p>
                      After installing the Vendor app, Sign up with the required fields given, you will receive an OTP enter it. Now you are successfully registered into the Vendor app.
                      Once registered update your profile and list down your services, add date and time slot where consumers can book.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqFour"
                      aria-expanded="false"
                    >
                      How to add services?
                    </Link>
                  </h4>
                  <div id="faqFour" className="card-collapse collapse">
                    <p>
                      At the bottom of your Home page, you will find the Services option, and it will be redirected to the Add Service option.
                      Here you can add multiple services, its details, date and time slot etc.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqFive"
                      aria-expanded="false"
                    >
                      What about Walk-Ins?
                    </Link>
                  </h4>
                  <div id="faqFive" className="card-collapse collapse">
                    <p>
                      At the bottom of your Home page Walk-In option is available, and it will be redirected to the Walk-In Slot option, where you can add client details,
                      services, and time slots they have opted for. Finally, click the Book Now option and the service is booked.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqSix"
                      aria-expanded="false"
                    >
                      Will I receive any booking confirmation mail when a consumer books a service?
                    </Link>
                  </h4>
                  <div id="faqSix" className="card-collapse collapse">
                    <p>
                      Yes, you will receive a booking confirmation mail.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqSeven"
                      aria-expanded="false"
                    >
                      Is it free?
                    </Link>
                  </h4>
                  <div id="faqSeven" className="card-collapse collapse">
                    <p>
                      It is free for 3 months, then a subscription is required.
                    </p>
                  </div>
                </div>
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faqEight"
                      aria-expanded="false"
                    >
                      What are the payment methods?
                    </Link>
                  </h4>
                  <div id="faqEight" className="card-collapse collapse">
                    <p>
                      We accept all Credit cards, Wallets and Paypal.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default Faq;
