import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import * as Icon from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { set_mouseoversidebar_data } from '../../../../core/data/redux/action';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { AppState } from '../../../../core/models/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/free-solid-svg-icons';
import { logout } from '../../../../core/data/redux/action';
import { AppDispatch } from '../../../../core/data/redux/store';

const CustSidebar = () => {
  const routes = all_routes;
  const navigateTo = useNavigate();
  const location = useLocation();
  const toggle_data = useSelector((state: AppState) => state.mouseOverSidebar);
  const dispatch: AppDispatch = useDispatch();
  const [subdroptoggle2, setsubdroptoggle2] = useState(false);

  const activeRouterPath = (link: string) => {
    return link === location.pathname;
  };

  const toogle = () => {
    dispatch(set_mouseoversidebar_data(toggle_data ? false : true));
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('UserDetails');
    navigateTo(routes.login);
  };

  return (
    <div
      onMouseEnter={toogle}
      onMouseLeave={toogle}
      className="sidebar"
      id="sidebar"
    >
      <div className="sidebar-inner slimscroll">
        <Scrollbars>
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li
                className={`${activeRouterPath(routes.customerDashboard) ? 'active' : ''}`}
              >
                <Link to={routes.customerDashboard}>
                  <Icon.Grid className="react-feather-icon" />{' '}
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={`${activeRouterPath(routes.customerBooking) ? 'active' : ''}`}
              >
                <Link to={routes.customerBooking}>
                  <Icon.Briefcase className="react-feather-icon" />{' '}
                  <span>Bookings</span>
                </Link>
              </li>
              <li
                className={`${activeRouterPath(routes.customerAddMember) ? 'active' : ''}`}
              >
                <Link to={routes.customerAddMember}>
                  <Icon.Users className="react-feather-icon" />{' '}
                  <span>Family Listing</span>
                </Link>
              </li>
              <li
                className={`${activeRouterPath(routes.customerFavourite) ? 'active' : ''}`}
              >
                <Link to={routes.customerFavourite}>
                  <FontAwesomeIcon icon={faWalking} className="react-feather-icon" />{' '}
                  <span>Favourites</span>
                </Link>
              </li>
              <li
                className={`${activeRouterPath(routes.customerComingSoon) ? 'active' : ''}`}
              >
                <Link to={routes.customerComingSoon}>
                  <Icon.DollarSign className="react-feather-icon" />
                  <span>Reviews</span>
                </Link>
              </li>
              <li
                className={`${activeRouterPath(routes.customerReportAnIssue) ? 'active' : ''
                  }`}
              >
                <Link to={routes.customerReportAnIssue}>
                  <Icon.Star className="react-feather-icon" />{' '}
                  <span>Contact Us</span>
                </Link>
              </li>
              <li
                className={`${activeRouterPath(routes.customerResetPassword) ? 'active' : ''}`}
              >
                <Link to={routes.customerResetPassword}>
                  <Icon.Lock className="react-feather-icon" />{' '}
                  <span>Change Password</span>
                </Link>
              </li>
              <li
                className={`${activeRouterPath(routes.customerEditProfile) ? 'active' : ''}`}
              >
                <Link to={routes.customerEditProfile}>
                  <Icon.User className="react-feather-icon" />{' '}
                  <span>Profile</span>
                </Link>
              </li>
              <li
                className={`${activeRouterPath(routes.customerDeleteAccount) ? 'active' : ''}`}
              >
                <Link to={routes.customerDeleteAccount}>
                  <Icon.User className="react-feather-icon" />{' '}
                  <span>Delete Account</span>
                </Link>
              </li>
              <li>
                <Link onClick={(e) => { e.preventDefault(); handleLogout(); }} to={''}>
                  <Icon.LogOut className="react-feather-icon" />{' '}
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </Scrollbars>
        <div
          className="dropdown-menu user-drop"
          id="dropboxes"
          style={{ display: subdroptoggle2 ? 'block' : 'none' }}
        >
          <div className="menu-user">
            <div className="menu-user-img avatar-online">
              <ImageWithBasePath
                src="assets/img/profiles/avatar-02.jpg"
                alt="user"
              />
            </div>
            <div className="menu-user-info">
              <h6>John Smith</h6>
              <p>Active</p>
            </div>
          </div>
          <div className="set-user">
            <p>Set as Away</p>
            <div className="status-toggle">
              <input type="checkbox" id="active-user" className="check" />
              <label htmlFor="active-user" className="checktoggle">
                checkbox
              </label>
            </div>
          </div>
          <ul className="set-menu">
            <li>
              <Link to="/provider-security-settings">
                <Icon.Settings className="react-feather-icon me-2" /> Settings
              </Link>
            </li>
            <li>
              <Link to="/provider-profile-settings">
                <Icon.User className="react-feather-icon me-2" /> Your Account
              </Link>
            </li>
            <li>
              <Link to="#">
                <Icon.CheckCircle className="react-feather-icon me-2" />{' '}
                Identity Verification
              </Link>
            </li>
          </ul>
          <ul className="help-menu">
            <li>
              <Link to="#">Help Center</Link>
            </li>
            <li>
              <Link to="/terms-condition">Terms of Condition</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div
          className="dropdown-menu user-drop"
          id="dropboxes"
          style={{ display: subdroptoggle2 ? 'block' : 'none' }}
        >
          <div className="menu-user">
            <div className="menu-user-img avatar-online">
              <ImageWithBasePath
                src="assets/img/profiles/avatar-02.jpg"
                alt="user"
              />
            </div>
            <div className="menu-user-info">
              <h6>John Smith</h6>
              <p>Active</p>
            </div>
          </div>
          <div className="set-user">
            <p>Set as Away</p>
            <div className="status-toggle">
              <input type="checkbox" id="active-user" className="check" />
              <label htmlFor="active-user" className="checktoggle">
                checkbox
              </label>
            </div>
          </div>
          <ul className="set-menu">
            <li>
              <Link to="/provider-security-settings">
                <Icon.Settings className="react-feather-icon me-2" /> Settings
              </Link>
            </li>
            <li>
              <Link to="/provider-profile-settings">
                <Icon.User className="react-feather-icon me-2" /> Your Account
              </Link>
            </li>
            <li>
              <Link to="#">
                <Icon.CheckCircle className="react-feather-icon me-2" />{' '}
                Identity Verification
              </Link>
            </li>
          </ul>
          <ul className="help-menu">
            <li>
              <Link to="#">Help Center</Link>
            </li>
            <li>
              <Link to="/terms-condition">Terms of Condition</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CustSidebar;
