import React, { useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import './style.css'
import { TKN_DropdownInput, TKN_TextareaPlaceholder } from '../../../components/inputfields/input';

const ProviderDeactivateAccount = () => {
    const routes = all_routes;

    const values = [
        { name: 'No longer using the app' },
        { name: 'Not interested with services' },
        { name: 'Moving to another platform' },
    ];

    return (
        <div className="provider-body">
            <div className="main-wrapper">
                {/* <div className="header">
                    <div className="header-left">
                        <div className="sidebar-logo">
                            <a href="index.html">
                                <img src="assets/img/407x100 logo.png" className="img-fluid logo" alt="Logo" style={{ objectFit: 'cover', width: '165px', height: '40px' }} />
                            </a>
                            <a href="index.html">
                                <img src="assets/img/logo-small.png" className="img-fluid logo-small" alt="Logo" />
                            </a>
                        </div>
                    </div>
                    <a className="mobile_btns" id="mobile_btns" href="javascript:void(0);">
                        <i className="fas fa-align-left"></i>
                    </a>
                    <div className="header-split" style={{ width: '77%' }}>
                        <div className="page-headers"></div>
                        <ul className="nav user-menu noti-pop-detail">
                            <li className="nav-item"></li>
                            <li className="nav-item has-arrow dropdown-heads dropdown logged-item noti-nav noti-wrapper">
                                <a href="#" className="dropdown-toggled nav-link notify-link" data-bs-toggle="dropdown">
                                    <span className="noti-message">1</span>
                                    <img src="assets/img/icons/bell-icon.svg" alt="Bell" />
                                </a>
                                <div className="dropdown-menu notify-blk notifications">
                                    <div className="topnav-dropdown-header">
                                        <div>
                                            <p className="notification-title">
                                                Notifications <span>3</span>
                                            </p>
                                        </div>
                                        <a href="javascript:void(0)" className="clear-noti">
                                            <i className="fa-regular fa-circle-check"></i> Mark all as read
                                        </a>
                                    </div>
                                    <div className="noti-content">
                                        <ul className="notification-list">
                                            <li className="notification-message">
                                                <a href="notification.html">
                                                    <div className="media noti-img d-flex">
                                                        <span className="avatar avatar-sm flex-shrink-0">
                                                            <img className="avatar-img rounded-circle img-fluid" alt="User Image" src="assets/img/notifications/avatar-01.jpg" />
                                                        </span>
                                                        <div className="media-body flex-grow-1">
                                                            <p className="noti-details">Lex Murphy left 6 comments on Isla Nublar SOC2 compliance report</p>
                                                            <p className="noti-time">
                                                                <span className="notification-time">1m ago</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="notification-message">
                                                <div className="media noti-img d-flex">
                                                    <a href="notification.html">
                                                        <span className="avatar avatar-sm flex-shrink-0">
                                                            <img className="avatar-img rounded-circle img-fluid" alt="User Image" src="assets/img/notifications/avatar-02.jpg" />
                                                        </span>
                                                    </a>
                                                    <div className="media-body flex-grow-1">
                                                        <a href="notification.html">
                                                            <p className="noti-details">Ray Arnold requested access to UNIX directory tree hierarchy</p>
                                                        </a>
                                                        <p className="noti-time">
                                                            <span className="notification-time">1 min</span>
                                                        </p>
                                                        <div className="notify-btns">
                                                            <button className="btn btn-secondary">Decline</button>
                                                            <button className="btn btn-primary">Accept</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="notification-message">
                                                <a href="notification.html">
                                                    <div className="media noti-img d-flex">
                                                        <span className="avatar avatar-sm flex-shrink-0">
                                                            <img className="avatar-img rounded-circle img-fluid" alt="User Image" src="assets/img/notifications/avatar-03.jpg" />
                                                        </span>
                                                        <div className="media-body flex-grow-1">
                                                            <p className="noti-details">Dennis Nedry commented on Isla Nublar SOC2 compliance report</p>
                                                            <p className="noti-time">
                                                                <span className="notification-time">1m ago</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="notification-message">
                                                <a href="notification.html">
                                                    <div className="media noti-img d-flex">
                                                        <span className="avatar avatar-sm flex-shrink-0">
                                                            <img className="avatar-img rounded-circle img-fluid" alt="User Image" src="assets/img/notifications/avatar-04.jpg" />
                                                        </span>
                                                        <div className="media-body flex-grow-1">
                                                            <p className="noti-details">John Hammond created Isla Nublar SOC2 compliance report </p>
                                                            <p className="noti-time">
                                                                <span className="notification-time">1m ago</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="topnav-dropdown-footer">
                                        <a href="notification.html">
                                            View All Notifications <img src="assets/img/icons/arrow-right-01.svg" alt="Arrow" />
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item has-arrow dropdown-heads">
                                <a href="#" className="win-maximize">
                                    <i className="feather-maximize"></i>
                                </a>
                            </li>
                            <li className="nav-item dropdown has-arrow account-item">
                                <a href="#" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                                    <div className="user-infos">
                                        <span className="user-img">
                                            <img src="assets/img/profiles/avatar-02.jpg" className="rounded-circle" alt="User Image" />
                                        </span>
                                        <div className="user-info">
                                            <h6>Dan Thomas</h6>
                                        </div>
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end emp">
                                    <a className="dropdown-item" href="provider-profile-settings.html">
                                        <i className="feather-user me-2"></i> Profile
                                    </a>
                                    <a className="dropdown-item" href="index.html">
                                        <i className="feather-log-out me-2"></i> Logout
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> */}

                {/* <div className="sidebar" id="sidebar">
                    <div className="sidebar-inner slimscroll">
                        <div id="sidebar-menu" className="sidebar-menu">
                            <ul>
                                <li>
                                    <a href="customer-dashboard.html">
                                        <i className="feather-grid"></i> <span>Dashboard</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="customer-booking.html" className="active">
                                        <i className="feather-smartphone"></i> <span>Bookings</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="customer-wallet.html">
                                        <i className="feather-user-plus"></i>
                                        <span>Add Members</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="customer-favourite.html">
                                        <i className="feather-heart"></i>
                                        <span>Favorites</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="customer-reviews.html">
                                        <i className="feather-star"></i> <span>Reviews</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="customer-chat.html">
                                        <i className="feather-message-circle"></i>
                                        <span>Contact Us</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="customer-profile.html">
                                        <i className="feather-user"></i> <span>Profile</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="index.html">
                                        <i className="feather-log-out"></i> <span>Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}

                <div className="page-wrapper">
                    <div className="content container-fluid" style={{ width: '97.5%' }}>
                        <div className="col-lg-6" style={{ width: '97.5%' }}>
                            <div className="widget-title">
                                <h4>Deactivate Account</h4>
                                <div className="new-contact-us-style1">
                                    <div className="new-contact-us-style2">
                                        <div className="col-md-6">
                                            <TKN_DropdownInput placeholderText={'Select a Category'} options={values}></TKN_DropdownInput>
                                        </div>
                                        <div className="col-md-6">
                                            <TKN_TextareaPlaceholder placeholderText={'Describe the issue '}></TKN_TextareaPlaceholder>
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                <a href="#" className="btn btn-light-danger" style={{ marginLeft: '20px', backgroundColor: '#0445CF', color: 'white' }}>
                                                    Submit
                                                </a>
                                            </div>
                                            <div className="new-contact-us-style4">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mouse-cursor cursor-outer"></div>
                <div className="mouse-cursor cursor-inner"></div>
            </div>
        </div>
    );
};

export default ProviderDeactivateAccount;
