import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { ImageWithBasePath, ImageWithBasePathWithUrl } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import Category from '../../../components/serviceList/category';
import apiService from '../../../../api/authentication-service';
import { WEB_BASE_URL } from '../../../../constants/constants';
import { TKN_LocationMapSearch } from '../../../components/inputfields/input';
import './service-grid.css';
import colors from '../../../../colors/colors';
import en from '../../../../en/en';
import { useDispatch, useSelector } from 'react-redux';
import { TKN_modal } from '../../../components/modal/modal';
import { RootState } from '../../../../core/data/redux/store';
import FooterOne from '../../../components/footer-one';
import HomeHeader from '../../../components/header/home-header';
import Loader from '../../../components/loader_spinner/loader_spinner';
import { setSearchRequestData, setvendorId } from '../../../../core/data/redux/action';
import initialState from '../../../../core/data/redux/initial.values';

const ServiceGrid = () => {
  const dispatch = useDispatch();
  const routes = all_routes;
  const navigate = useNavigate();
  const searchRequestData = useSelector((state: RootState) => state.searchRequestData);
  const userLocationDetails = useSelector((state: RootState) => state.userLocationDetails);
  const [query, setQuery] = useState(searchRequestData.query ?? '');
  const [placename, setPlaceName] = useState(searchRequestData.place_name ?? userLocationDetails.place_name ?? '');
  const [searchLatitude, setSearchLatitude] = useState<number>(searchRequestData.latitude ?? userLocationDetails.latitude ?? 0);
  const [searchLongitude, setSearchLongitude] = useState<number>(searchRequestData.longitude ?? userLocationDetails.longitude ?? 0);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(searchRequestData.category ?? 0);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<number>(searchRequestData.subcategory ?? 0);
  const [businessList, setbusinessList] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showloader, setShowLoader] = useState<boolean>(true);
  const vendorOrNot = useSelector((state: any) => state.vendor.vendor_id);
  const [reset, setReset] = useState<boolean>(false);
  const [newplacename, setNewplacename] = useState('');

  useEffect(() => {

    if (reset) {
      setQuery(searchRequestData.query || '');
      setPlaceName(searchRequestData.place_name || userLocationDetails.place_name || '');     
      setSearchLatitude(searchRequestData.latitude || userLocationDetails.latitude || 0);
      setSearchLongitude(searchRequestData.longitude || userLocationDetails.longitude || 0);
      setSelectedCategoryId(searchRequestData.category || 0);
      setSelectedSubCategoryId(searchRequestData.subcategory || 0);
      setReset(false);
      
    }
  }, [searchRequestData, reset]);

  
  const handleBookNow = (vendor_id: string) => {
    dispatch(setvendorId(vendor_id));
    navigate(routes.businessDetails);
  };

  useEffect(() => {

    fetchBusinessDetails(searchLatitude, searchLongitude, query, selectedCategoryId, selectedSubCategoryId,placename);
  }, []);

  
  const handlePlaceNameChange = (newPlaceName: string) => {

    setNewplacename(newPlaceName);  
    
  };

  const fetchBusinessDetails = (latitude?: number, longitude?: number, query?: string, categoryId?: number, subCategoryId?: number, placeName?: string) => {

    const payload = { latitude, longitude, category_id: categoryId, subcategory_id: subCategoryId, query };
    apiService.getBusinessByCategoryAndSubcategory(payload)
      .then((response: any) => {

        if (response?.data?.status === true) {

          setbusinessList(response.data.data || []);
          setShowLoader(false);
          

          dispatch(setSearchRequestData({
            ...searchRequestData,
            latitude: latitude,
            longitude: longitude,
            place_name: newplacename || placeName || userLocationDetails.place_name,
          }));

          

        } else {

          console.error('Error fetching business details:', response?.data?.message);
          setbusinessList([]);
          setShowLoader(false);

          dispatch(setSearchRequestData({
            ...searchRequestData,
            latitude: latitude,
            longitude: longitude,
            place_name: newplacename || placeName || userLocationDetails.place_name,
          }));
         
        }
      })
      .catch((error: any) => {

        console.error('Error fetching business details:', error);
        setbusinessList([]);
      });
  };

  const handleCategorySelect = (categoryId: number, subCategoryId: number) => {

    setSelectedCategoryId(categoryId);
    setSelectedSubCategoryId(subCategoryId);
    fetchBusinessDetails(searchLatitude, searchLongitude, query, categoryId, subCategoryId,placename);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    
    const lat = searchLatitude;
    const lng = searchLongitude;

    fetchBusinessDetails(lat, lng, query, selectedCategoryId, selectedSubCategoryId,placename);
    setPlaceName(newplacename);
  };

  const handleLocationSelect = (location: { lat: number, lng: number } | null, placeName?: string | null) => {
    
    if (location) {
      setSearchLatitude(location.lat);
      setSearchLongitude(location.lng);
      setPlaceName(placeName);
     
    } else {
      setSearchLatitude(userLocationDetails.latitude);
      setSearchLongitude(userLocationDetails.longitude);
      setPlaceName(userLocationDetails.place_name);
    }
  };


  const handleResetFilters = () => {
    setQuery('');
    setPlaceName('');
    setPlaceName(userLocationDetails.place_name ?? '');
    setSearchLatitude(userLocationDetails.latitude ?? 0);
    setSearchLongitude(userLocationDetails.longitude ?? 0);
    setSelectedCategoryId(0);
    setSelectedSubCategoryId(0);
    fetchBusinessDetails(userLocationDetails.latitude, userLocationDetails.longitude, '', 0, 0);
    dispatch(setSearchRequestData(initialState.searchRequestData));
    setReset(true);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  }

  return (
    <>
      <HomeHeader type={1} />
      <div className="bg-img">
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg1"
        />
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg2"
        />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Find a Business</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Find a Business
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-12 theiaStickySidebar">
              <div className="stickybar">
                <div className="filter-div">
                  <div className="filter-head">
                    <h5>Filter by</h5>
                    <h5><Link to="#" className="reset-link" onClick={handleResetFilters}>
                      Reset Filters
                    </Link></h5>
                  </div>
                  <div>
                    <form onSubmit={handleSubmit}>
                      <div className="filter-content">
                        <h2>Keyword</h2>
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search for services"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <Icon.XCircle className="close-icon" onClick={handleResetFilters} />
                        </div>
                      </div>
                      <div className="filter-container">
                        <div className="filter-content">
                          <h2>Location</h2>
                          <TKN_LocationMapSearch
                            resetFilter={reset}
                            icon={<Icon.MapPin className="react-feather-custom" />}
                            onLocationSelect={handleLocationSelect}
                            onPlaceNameChange={handlePlaceNameChange} 
                            incomingLocationPlaceName={placename !== '' ? placename : userLocationDetails?.place_name}                            
                            incomingLocation={searchLatitude !== 0 && searchLongitude !== 0 ? { lat: searchLatitude, lng: searchLongitude } : undefined} />
                        </div>
                      </div>
                      <div className="filter-container" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <Category onCategorySelect={handleCategorySelect} selectedCategory={selectedCategoryId} selectedSubCategory={selectedSubCategoryId} />
                      </div>
                      <div className="filter-container">
                      </div>
                      <button className="btn btn-primary" onKeyDown={handleKeyDown} onClick={handleSubmit} type="submit" >Search</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12">
              <Loader showloader={showloader} style={{ marginTop: '100px', position: 'absolute', left: '56%' }} />
              {!showloader && (
                <React.Fragment>
                  <div className="row sorting-div">
                    <div className="col-lg-4 col-sm-12 ">
                      <div className="filter-head">
                        <h5>Found {businessList.length} Business</h5>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '-1px' }}>
                    <div className="row">
                      {businessList.length === 0 ? (
                        <div className="col-12 text-center">
                          <h2 className="no-results" style={{ color: '#adb5bd', fontSize: '25px', marginTop: '200px', marginBottom: '200px' }}>No business found</h2>
                        </div>
                      ) : (
                        businessList.map((business, index) => (
                          <div className="col-xl-4 col-md-6" key={business.vendor_id}>
                            <div className="service-widget servicecontent">
                              <div className="service-img" style={{ height: '250px', overflow: 'hidden' }}>
                                <ImageWithBasePathWithUrl
                                  className="img-fluid serv-img business-img"
                                  alt={business.bussiness_name}
                                  src={`${WEB_BASE_URL}${business.temp_name}`}
                                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                                {/* <div className="fav-item" onClick={() => handleItemClick(index)}></div >  */}
                              </div>
                              <div className="service-content">
                                <h3 className="title">
                                  {business.bussiness_name || 'Business Name'}
                                </h3>
                                <h6 style={{ color: 'grey', fontWeight: '400' }} className='para-text-elises'>
                                  <i className="feather-map-pin " />
                                  {`${business.city}, ${business.State}` || 'Location not available'}
                                  <span className="rate">
                                    <i className="fas fa-star filled" />
                                    {business.rating || 'N/A'}
                                  </span>
                                </h6>
                                <div className="serv-info">
                                  <button
                                    className="btn btn-book"
                                    style={{ color: colors.web_primary_color, width: '400%', borderRadius: '5px', height: '40px' }}
                                    onClick={() => {
                                      vendorOrNot === 0 ? handleBookNow(business.id) : setShowModal(true);
                                    }}
                                  >
                                    {en.BOOK_NOW}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div></div>
                </React.Fragment>)}

            </div>
          </div>
        </div>
      </div>
      <FooterOne />
      {showModal && (
        <TKN_modal
          description={en.OPTION_UNAVAILABLE_VENDOR}
          okbutton={en.OK}
          handleshowModal={showModal}
          handleSuccessButton={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default ServiceGrid;