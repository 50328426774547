import React from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { ImageWithBasePath } from '../../core/img/ImageWithBasePath';
import TKN_texticon from '../components/icon/TKN_texticon';
import { all_routes } from '../../core/data/routes/all_routes';


const FooterOne = () => {
    const routes = all_routes;
    return (
        <>
            <footer className="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 footer-width1">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <Link to={'/'}>
                                            <ImageWithBasePath
                                                src="assets/img/TKN_images/407x100 logo.png"
                                                alt="logo"
                                            />
                                        </Link>
                                    </div>
                                    <div className="footer-content">
                                        <p style={{ textAlign: 'justify' }} className='footer-para-custom'>
                                            Lets take you through the journey where we began – Take A No – Your Partner for Booking Local Services! Download now and enjoy a seamless experience effortlessly.{' '}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 footer-width2">
                                <div className="footer-widget footer-menu">
                                    <h2 className="footer-title footer-title-custom">Quick Links</h2>
                                    <ul>
                                        <li className='footer-para-custom'>
                                            <Link to={routes.aboutUs}>About</Link>
                                        </li>
                                        <li className='footer-para-custom'>
                                            <Link to={routes.contactUs}>Support</Link>
                                        </li>
                                        <li className='footer-para-custom'>
                                            <Link to={routes.search}>Search</Link>
                                        </li>
                                        <li className='footer-para-custom'>
                                            <Link to={routes.faq}>Faq</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <TKN_texticon
                                title={"Contact Us"}
                                address={"Safa Logics, 6545 Market Ave., North Ste 100 North Canton, Oh 44721"}
                                emails={["support@takeano.com", "sales@takeano.com"]}
                                emailicon={Icon.Mail}
                                addressicon={Icon.MapPin}
                            />
                            <div className="col-lg-3 col-md-6 footer-width2 m-top-30px follow-us-custom">
                                <div className="footer-widget">
                                    <h2 className="footer-title footer-title-custom">Follow Us</h2>
                                    <div className="social-icon">
                                        <ul>
                                            <li>
                                                <Link to={'https://www.facebook.com/profile.php?id=61554757094902'} target="_blank">
                                                    <i className="fa-brands fa-facebook"></i>{' '}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'https://www.youtube.com/channel/UCQyZPx1g16STzYFGZpWddzw'} target="_blank">
                                                    <i className="fab fa-youtube"></i>{' '}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'https://twitter.com/safa_takeano'} target="_blank">
                                                    <i className="fa-brands fa-twitter"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'https://www.linkedin.com/company/100841522/'} target="_blank">
                                                    <i className="fa-brands fa-linkedin"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <div className="copyright">
                            <div className="row align-items-center">
                                <div className="col-md-4 footer-copyright-custom">
                                    <div className="copyright-text">
                                        <p className="mb-0 footer-para-custom">
                                            Copyright &copy; 2024. All Rights Reserved.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 footer-copyright-custom">
                                    <div className="payment-image">
                                        <ul>
                                            <li className='footer-para-custom'>
                                                <Link to={'/'}>
                                                    <ImageWithBasePath
                                                        src="assets/img/payment/visa.png"
                                                        alt="img"
                                                    />
                                                </Link>
                                            </li>
                                            <li className='footer-para-custom'>
                                                <Link to={'/'}>
                                                    <ImageWithBasePath
                                                        src="assets/img/payment/mastercard.png"
                                                        alt="img"
                                                    />
                                                </Link>
                                            </li>
                                            <li className='footer-para-custom'>
                                                <Link to={'/'}>
                                                    <ImageWithBasePath
                                                        src="assets/img/payment/stripe.png"
                                                        alt="img"
                                                    />
                                                </Link>
                                            </li>
                                            <li className='footer-para-custom'>
                                                <Link to={'/'}>
                                                    <ImageWithBasePath
                                                        src="assets/img/payment/discover.png"
                                                        alt="img"
                                                    />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="copyright-menu">
                                        <ul className="policy-menu ">
                                            <li className='footer-para-custom'>
                                                <Link to={routes.privacyPolicy}>Privacy Policy</Link>
                                            </li>
                                            <li className='footer-para-custom'>
                                                <Link to={routes.termsCondition}>Terms & Conditions</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default FooterOne;