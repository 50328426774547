import React, { useState } from "react";
import "./favoriteRowSectionStyle.css";
import { useSelector } from "react-redux";
import { FavoriteRowSection } from "../bookingRowSection/bookingRowSection";
import en from "../../../../en/en";
import useGetAllFavorites from "../../../../hooks/getAllFavorites";
import Loader from "../../loader_spinner/loader_spinner";

const FavoriteSection = () => {

    const limit = 5;
    const [page] = useState(1);
    const userId = useSelector((state: any) => state.user.user_id);
    const { favorites, showloaderfav } = useGetAllFavorites(userId, page, limit);

    return (
        <React.Fragment>
            <Loader showloader={showloaderfav} style={{ marginTop: '108px', marginBottom: '60px' }} />
            {!showloaderfav && (
                favorites.length > 0 ? <FavoriteRowSection dataArray={favorites} /> :
                    <div className="d-flex no-bookings justify-content-center align-items-center">
                        <h4 className="no-favorites-h4">{en.NO_FAVORITES}</h4>
                    </div>
            )}
        </React.Fragment>
    )
};

export default FavoriteSection;