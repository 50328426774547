import React from 'react';
import { useEffect } from 'react';

interface CustomMapProps {
    latitude: number;
    longitude: number;
    zoomLevel?: number;
    isLoaded: boolean;
}

interface GoogleWindow extends Window {
    google?: {
        maps?: {
            Map: any;
        }
    }
}

const CustomMap = ({ latitude, longitude, zoomLevel, isLoaded }: CustomMapProps) => {
    useEffect(() => {
        const initMap = () => {
            const mapElement = document.getElementById('map');
            if (mapElement) {
                const googleMaps = (window as GoogleWindow).google?.maps;
                if (googleMaps && googleMaps.Map) {
                    const map = new googleMaps.Map(mapElement, {
                        center: { lat: latitude, lng: longitude },
                        zoom: zoomLevel || 12,
                        mapTypeControl: true,
                        zoomControl: true,
                        fullscreenControl: false,
                        streetViewControl: false,
                    });
                } else {
                    console.error("Google Maps API not found");
                }
            } else {
                console.error("Map element not found");
            }
        };


        if (isLoaded && (window as GoogleWindow).google?.maps) {
            initMap();
        }else {
            console.error("Google Maps API not loaded");
        }
    }, [latitude, longitude, zoomLevel, isLoaded]);

    return <div id="map" style={{ width: '100%', height: '250px'}}></div>;
};

export default CustomMap;
