import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { all_routes } from '../../../../core/data/routes/all_routes';

const TermsCondition = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>

      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Terms &amp; Condition</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Terms &amp; Condition
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Terms & Conditions */}
            <div className="col-md-12">
              <div className="terms-content">
                <p>
                  By accessing and using the services provided by SAFA logics, including the “𝗧𝗮𝗸𝗲 𝗔 𝗡𝗼” web app,
                  you agree to comply with and be bound by these terms and conditions.
                </p>
                <p>
                  The email address provided during registration will be used for the following purposes:
                  Account verification and activation.
                  Communication regarding appointment bookings, cancellations, and modifications.
                  Sending important announcements, promotions, and relevant information related to the “𝗧𝗮𝗸𝗲 𝗔 𝗡𝗼” web app.{' '}
                </p>
                <ul>
                  <li>Facilitate the booking and management of appointments.</li>
                  <li>Provide personalized services based on your preferences.</li>
                  <li>
                    Communicate important updates, promotions, and relevant information.{' '}
                  </li>
                  <li>
                    Allow service providers to contact you for appointment-related purposes.
                  </li>
                </ul>
                <p>
                  SAFA Logics reserves the right to update or modify this data collection and privacy policy.
                  Users will be notified of any significant changes, and continued use of the web app
                  implies acceptance of the updated terms. By using the “𝗧𝗮𝗸𝗲 𝗔 𝗡𝗼” web app,
                  you acknowledge and agree to the terms outlined in this data collection and privacy policy.{' '}
                </p>
                <p>
                  For questions or concerns about these terms and conditions, please contact us at support@takeano.com.
                </p>
                {/* <div className="terms-btn">
                  <Link to="#" className="btn btn-secondary">
                    Not right now...
                  </Link>
                  <Link to="#" className="btn btn-primary">
                    I agree with terms
                  </Link>
                </div> */}
              </div>
            </div>
            {/* /Terms & Conditions */}
          </div>
        </div>
      </div>

    </>
  );
};

export default TermsCondition;
