import React from 'react';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import { ReviewBox } from '../reviewBoxs/reviewBox';


const ReviewBoxSlider = () => {

  const clientSlider = {
    dots: false,
    autoplay: false,
    slidesToShow: 2,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <Slider {...clientSlider}>
                <ReviewBox
                  reviewerImageSrc={'assets/img/profiles/avatar-01.jpg'}
                  reviewerName={'Sophie Moore'}
                  reviewContent={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua. Ut enim ad minim veniam, quis nostrud exercitationullamco laboris nisi'}
                  reviewerDesignation={'Director'}
                  ratingNumber={4}
                />
                <ReviewBox
                  reviewerImageSrc={'assets/img/profiles/avatar-02.jpg'}
                  reviewerName={'Mike Hussy'}
                  reviewContent={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua. Ut enim ad minim veniam, quis nostrud exercitationullamco laboris nisi'}
                  reviewerDesignation={'Lead'}
                  ratingNumber={3}
                />
                <ReviewBox
                  reviewerImageSrc={'assets/img/profiles/avatar-03.jpg'}
                  reviewerName={'John Doe'}
                  reviewContent={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua. Ut enim ad minim veniam, quis nostrud exercitationullamco laboris nisi'}
                  reviewerDesignation={'CEO'}
                  ratingNumber={2}
                />
      </Slider>
    </React.Fragment>
  )
}
export default ReviewBoxSlider;