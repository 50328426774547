import { all_routes } from "../routes/all_routes";

export const featureBoxData = [
    {
        route: all_routes.businessDetails,
        iconSrc: 'assets/img/icons/feature-icon-01.svg',
        iconAlt: 'Construction Icon',
        title: 'Construction',
        id: 3,
        overlaySrc: 'assets/img/services/service-02.jpg',
        overlayAlt: 'Construction Service'
    },
    {
        route: all_routes.businessDetails,
        iconSrc: 'assets/img/icons/feature-icon-02.svg',
        iconAlt: 'Automotive Icon',
        title: 'Automotive',
        id: 2,
        overlaySrc: 'assets/img/feature.jpg',
        overlayAlt: 'Car Wash Service'
    },
    {
        route: all_routes.businessDetails,
        iconSrc: 'assets/img/icons/feature-icon-03.svg',
        iconAlt: 'Construction Icon',
        title: 'Electrical',
        id: 15,
        overlaySrc: 'assets/img/services/service-01.jpg',
        overlayAlt: 'Electrical Service'
    },
    {
        route: all_routes.businessDetails,
        iconSrc: 'assets/img/icons/feature-icon-04.svg',
        iconAlt: 'Construction Icon',
        title: 'Cleaning',
        id: 33,
        overlaySrc: 'assets/img/services/service-05.jpg',
        overlayAlt: 'Cleaning Service',
    },
    {
        route: all_routes.businessDetails,
        iconSrc: 'assets/img/icons/feature-icon-05.svg',
        iconAlt: 'Construction Icon',
        title: 'Interior',
        id: 32,
        overlaySrc: 'assets/img/services/service-07.jpg',
        overlayAlt: 'Interior Service',
    },
    {
        route: all_routes.businessDetails,
        iconSrc: 'assets/img/icons/feature-icon-06.svg',
        iconAlt: 'Construction Icon',
        title: 'Carpentry',
        id: 9,
        overlaySrc: 'assets/img/services/service-03.jpg',
        overlayAlt: 'Carpentry Service',
    },
    {
        route: all_routes.businessDetails,
        iconSrc: 'assets/img/icons/feature-icon-07.svg',
        iconAlt: 'Construction Icon',
        title: 'Computer',
        id: 8,
        overlaySrc: 'assets/img/services/service-06.jpg',
        overlayAlt: 'Computer Service',
    },
    {
        route: all_routes.businessDetails,
        iconSrc: 'assets/img/icons/feature-icon-08.svg',
        iconAlt: 'Construction Icon',
        title: 'Plumbing',
        id: 3,
        overlaySrc: 'assets/img/services/service-11.jpg',
        overlayAlt: 'Plumbing Service',
    }
];