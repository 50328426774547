import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const ProviderComingSoon = () => {
    const routes = all_routes;
    return (
        <>
            <div className="main-wrapper">
                <div className="bg-img">
                    <ImageWithBasePath
                        src="assets/img/bg/work-bg-03.png"
                        alt="img"
                        className="bgimg1"
                    />
                    <ImageWithBasePath
                        src="assets/img/bg/work-bg-03.png"
                        alt="img"
                        className="bgimg2"
                    />
                </div>
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="error-wrap text-center">
                                </div>
                            </div>
                        </div>
                        <div className="maintenance-sec">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="maintenance-wrap">
                                        <h4 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>This feature will be available shortly...</h4>
                                        {/* <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            We Will be here soon with our new awesome site, subscribe
                                            to be notified.
                                        </p> */}
                                        <div className="email-notify">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProviderComingSoon;
