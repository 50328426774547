export const faqData = [
    {
        id: 1,
        title: "How Can I Book Service?",
        category: "Services",
        details: "Lorem ipsum dolor amet, adipiscing elit",
        action: ""
    },
    {
        id: 2,
        title: "Sed perspiciatis omnis iste natus error?",
        category: "Services",
        details: "At vero eos et accusamus et iusto dignissimos",
        action: ""
    },
    {
        id: 3,
        title: "How Can I Book Service?",
        category: "Services",
        details: "Lorem ipsum dolor amet, adipiscing elit",
        action: ""
    },
    {
        id: 4,
        title: "Sed perspiciatis omnis iste natus error?",
        category: "Services",
        details: "At vero eos et accusamus et iusto dignissimos",
        action: ""
    }
]
