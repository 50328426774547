import { fromLatLng, setKey } from "react-geocode";
import { UserLocationDetailsState } from "../core/data/redux/initial.values";
import { GOOGLE_MAPS_API_KEY } from "../constants/constants";

// setKey('AIzaSyAf3LRTL4qaj_40KPE5qCCHU_Aet0npVyA');
//setKey('AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8');
setKey(GOOGLE_MAPS_API_KEY);

const fetchCurrentLocation = async () : Promise<UserLocationDetailsState> => {
	
	let locationDetails : UserLocationDetailsState = {
		latitude: 0,
		longitude: 0,
		place_name: 'United States',
	};

	if (navigator.geolocation) {

		return new Promise((resolve, reject) => {

			navigator.geolocation.getCurrentPosition(
				async (position) => {
					
					const { latitude, longitude } = position.coords;

					try {
						
						const response = await fromLatLng(latitude, longitude);
						if (response) {
							
							let localityAddress = '', districtLevelAddress = '', countryLevelAddress = '';
							const allAddressResults = response?.results;

							allAddressResults.forEach((address: any) => {

								if (address?.types.includes('locality')) {

									localityAddress = address?.formatted_address;
								}

								if (address?.types.includes('administrative_area_level_3')) {

									districtLevelAddress = address?.formatted_address;
								}

								if (address?.types.includes('country')) {

									countryLevelAddress = address?.formatted_address;
								}
							});

							locationDetails = {

								latitude: latitude,
								longitude: longitude,
								place_name: localityAddress ?? districtLevelAddress ?? countryLevelAddress,
							};

							resolve(locationDetails);
						} else {

							console.error('Location response empty');
							// reject('Location response empty');
						}
					} catch (error) {

						console.error('Error with Geocode:', error);
						// reject(error);
					}
				},
				(error) => {

					console.error('Geolocation error:', error);
					// reject(error);
				}
			);
		});
	} else {

		console.error('Geolocation not supported by this browser.');
		return Promise.reject('Geolocation not supported by this browser.');
	}
};

export default fetchCurrentLocation;