const en = {
    MOST_POPULAR_BUSINESS: "Most Popular Business",
    VIEW_ALL: "View All",
    FEATURED_CATEGORIES: "Featured Categories",
    WHAT_TO_FIND: "What do you need to find?",
    YOUR_LOCATION: "Your Location",
    WHAT_TO_LOOK: "What are you looking for?",
    SEARCH: "Search",
    LOGIN: "Login",
    SIGNUP: "Signup",
    SELECT_DAYS: 'Select day',
    SUBMIT: "Submit",
    HOME_HEADING: "Connecting consumers and vendors virtually",
    HOME_SUBHEADING: "For seamless bookings and appointments on finger tips",
    RATING_NUMBER: "4.9",
    NUMBER_OF_REVIEWS: "255 reviews",
    BOOK_NOW: "Book Now",
    VIEW_ON_MAP: "View on map",
    TOTAL_BOOKINGS: "Total Bookings",
    FAVORITES: "Favorites",
    FAMILY_MEMBERS: "Family Members",
    RATE: "4.9",
    LOCATION: "Ernakulam,Kakkanad",
    NO_FAVORITES: "No favorites found",
    NO_BOOKING: "No bookings found",
    BUSINESS_DETAILS: "Business Details",
    HOME: "Home",
    STATUS_ACTIVE: 'Active',
    STATUS_INACTIVE: 'Inactive',
    STATUS_ENDED: 'Ended',
    FAST_BACKWARD: "<<",
    FAST_FORWARD: ">>",
    PREVIOUS: "<",
    NEXT: ">",
    CANCEL: "Cancel",
    EDIT_NOT_PERMITTED: 'Active appointments exist. Either cancel the appointments to modify or inactivate the service and create a new one.',
    CHAR_LIMIT: 'Maximum char limit reached',
    SERVERERROR: 'Something went wrong... please try after sometime',
    INVALID_OTP: 'Invalid OTP. Please check your OTP again',
    UNFAVORITE: "Unfavorite",
    BOOKING_LIST: "Booking List",
    VIEW_ALL_FAVORITES: "All Favorites",
    VIEW_ALL_BOOKINGS: "All Bookings",
    VIEW_UPCOMING_BOOKINGS: "View Upcoming Bookings",
    VIEW_RECENT_BOOKING: "View Recent Bookings",
    RECENT_BOOKING: "Recent Bookings",
    UPCOMING_BOOKING: "Upcoming Booking",
    DEFAULT_ERROR: 'Unable to process your request right now. Please try after sometime.',
    PASSWORD_CONDITION: 'Your password must be atleast 8 characters long, contain atleast one number, and have a mixture of uppercase and lowercase letters with a special character.',
    VENDOR_DASHBOARD: 'Vendor Dashboard',
    BOOKING: 'Booking',
    TOTAL_BOOKING: 'Total Booking',
    TODAY_BOOKING: "Today's Booking",
    SERVICES: 'Services',
    TOTAL_SERVICES: 'Total Services',
    ACTIVE_SERVICES: 'Active Services',
    WALKIN_BOOKING: 'Walk-in Booking',
    CURRENT_PLAN: 'Current Plan',
    VIEW_ALL_SERVICES: 'All Services',
    LOADING: 'Loading...',
    DELETE_PAST_ACTIVE_BOOKINGS: 'Active bookings exists. Kindly cancel all reservations before account deletion.',
    DELETE_PAST_BOOKINGS: 'Past bookings exists. Do you want to delete?',
    DELETE_ACCOUNT_DEFAULT: 'Are you sure you want to delete the account?',
    DELETE_ACCOUNT_MESSAGE: 'Your account has been successfully deleted.',
    DELETE_ACTIVE_BOOKINGS: 'Active bookings exists. Kindly cancel all reservations before account deletion.',
    VIEW_IN_LARGE_MAP: 'View in large map',
    OPTION_UNAVAILABLE: 'Feature unavailable',
    OPTION_UNAVAILABLE_VENDOR: 'Please login as consumer to book an appointment',
    OK: 'Ok',
    ACCNT_CREATION_SUCCESS: 'Account created successfully',
    ACCNT_CREATION_SUCCESS_MESSAGE: 'Welcome to TakeANo and thank you for registering with us. Please login to continue.',
    ACCNT_CREATION_SUCCESS_MESSAGE_VENDOR: "Welcome to TakeANo and thank you for registering your business! Next, add your services to enhance your profile. We'll verify your business to ensure trust and safety in our community. Please login to continue.",
    CONTINUE: 'CONTINUE',
    REGISTRATION_INCOMPLETE: "Registration incomplete",
    REGISTRATION_INCOMPLETE_MESSAGE: "Your account registration is incomplete. Please click 'CONTINUE' to complete the registration.",
    ACTIVATION: "Activate account?",
    ACCOUNT_DELETED: "Account deleted",
    ACCOUNT_DELETED_MESSAGE: "This account is deleted. Please contact admin.",
    ACCOUNT_REGISTRATION_INCOMPLETE_BOOKINGLOGIN: "Your account registration is not yet complete. Click 'CONTINUE' to finalize the process.",
    BY_CLICKING_SIGNUP: "By clicking Sign Up, you agree to our",
    TERMS: "Terms",
    PRIVACY_POLICY: "Privacy Policy",
    COOKIES_POLICY: "and Cookies Policy",
    SIGNUP_TERMS: "Please accept the terms and conditions.",
    NOT_AVAILABLE: "( Not available )",
    PASSWORD_REQUIREMENTS: "Your password must be at least 8 characters long, contain at least one number, and have a mixture of uppercase and lowercase letters with a special character.",
    WALKIN_HEADING: "Walkin Booking",
    CUSTOMER_INFO: "Customer Information",
    VENDOR_INFO: "General Information",
    CANCELLATIONDETAILS: 'Provide cancellation details for the consumer...',
    SERVICE_TIMES_DETAILS: "Service times & details",
    CANCEL_BOOKING_DESCRIPTION: "Are you sure you want to cancel booking?",
    CANCELLATIONDETAILSVENDOR: 'Please provide cancellation reason',
};

export default en;