import { format, parse } from "date-fns";
import { MONTH_NAMES } from "../constants/constants";

const getFormattedDate = (date: any) => {

    let formattedDate = "";
    try {

        if (date) {

            const parsedDate = parse(date, 'dd/MM/yyyy', new Date());
            formattedDate = format(parsedDate, 'yyyy-MM-dd');
        }
    } catch (error) {

        console.error("ERROR : ", error);
    }
    return formattedDate;
};

interface MonthsMap {
    [key: string]: string;
};

const months: MonthsMap = {
    'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06',
    'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12'
};

function convertDateToISO(dateString: string) {

    const [monthAbbr, day, year] = dateString.split('-').map(part => part.trim());

    const monthNumber = months[monthAbbr.slice(0, 3)];

    if (monthNumber && day && year) {
        const formattedDate = `${year}-${monthNumber}-${day.padStart(2, '0')}`;
        return formattedDate;
    } else {
        throw new Error('Please provide a date in the format of "Dec, 23 2024".');
    }
};

function convertDateStringToISOString(dateInput: string | Date): string {
    let givenData: Date;

    if (typeof dateInput === 'string') {

        givenData = new Date(dateInput);
    } else if (dateInput instanceof Date) {

        givenData = dateInput;
    } else {

        throw new Error('Please provide a valid string or Date object.');
    }

    const givenDateISOString = givenData.toISOString().split('T')[0];
    return givenDateISOString;
};

function convertISOToDate(isoDateString: string | Date): string {
    let year, month: string, day;

    if (isoDateString instanceof Date) {
        year = isoDateString.getFullYear().toString();
        month = (isoDateString.getMonth() + 1).toString().padStart(2, '0');
        day = isoDateString.getDate().toString().padStart(2, '0');
    } else if (typeof isoDateString === 'string') {
        const dateParts = isoDateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);
        if (!dateParts) {
            throw new Error('Please provide a date in the format of "yyyy-mm-dd" or a valid Date object.');
        }
        [, year, month, day] = dateParts;
    } else {
        throw new Error('Please provide a valid string or Date object.');
    }

    const monthAbbreviation = Object.keys(months).find(value => months[value] === month);

    if (monthAbbreviation && day && year) {
        const formattedDate = `${monthAbbreviation}, ${parseInt(day, 10)} ${year}`;
        return formattedDate;
    } else {
        throw new Error('Please provide a date in the format of "yyyy-mm-dd" or a valid Date object.');
    }
};

const getBusinessTimeByTimezone = (timezone: string) => {

    const currentDate = new Date();

    const optionsOrg = {
        timeZone: timezone,
        hour12: true,
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    const options: Intl.DateTimeFormatOptions = {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    const formattedDate = currentDate.toLocaleString('en-US', options);
    const reformattedDate = parse(formattedDate, 'MM/dd/yyyy, HH:mm:ss', new Date());
    const convertedDate = format(reformattedDate, 'yyyy-MM-dd,HH:mm:ss');
    return convertedDate;
};

const getConvertedBusinessDateTime = (timezone: string) => {
    const convertedBUsinessDate = getBusinessTimeByTimezone(timezone);
    const [datePart, timePart] = convertedBUsinessDate.split(',');
    const dateTimeString = `${datePart}T${timePart}`;
    const newTimeObj = new Date(dateTimeString);
    return newTimeObj;
};

/**
 * Formats a given date into a string in the format 'month-day-year'.
 *
 * @param {any} date - The date to be formatted.
 * @return {string} The formatted date string.
 */
const dateFormatFunction = (date: any) => {
    const monthIndex = date.getMonth();
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${MONTH_NAMES[monthIndex]}-${day}-${year}`;
    return formattedDate;
};

/**
 * Formats a date string into a specific format.
 *
 * @param {string} dateString - The date string to be formatted.
 * @return {string} The formatted date string in the format 'month-day-year'.
 */
const dateFormatFunctionWithoutDateObject = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDay().toString().padStart(2, '0');
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
};

export {
    getFormattedDate,
    convertDateStringToISOString,
    convertDateToISO,
    convertISOToDate,
    getConvertedBusinessDateTime,
    dateFormatFunction,
    dateFormatFunctionWithoutDateObject
};