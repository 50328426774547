import { useEffect, useState } from "react";
import apiService from "../api/authentication-service";

const useGetAllBookings = (userId: number, page: number, limit: number) => {
    const [bookings, setBookings] = useState([]);
    const [totalCount, settotalCount] = useState(0);
    const [error, setError] = useState<any>();
    const [showloader, setShowLoader] = useState<boolean>(true);
    const pagesCount = Math.ceil(totalCount / limit);

    useEffect(() => {
        const sendGetAllBookings = async () => {
            try {

                const response = await apiService.getUserAllBookingsLimited({
                    user_id: userId,
                    all_bookings: 1,
                    page,
                    limit
                });
                if (response.data.status === true) {
                    setBookings(response.data.data.bookings);
                    settotalCount(response.data.data.total_bookings);
                    setShowLoader(false);
                } else {
                    setShowLoader(false);
                }
            } catch (error) {

                console.error('sendGetAllBookings_Error:', error);
                setError(error);
            }
        };

        if (userId) {

            sendGetAllBookings();
        }
    }, [userId, page, limit]);

    return { bookings, pagesCount, totalCount, error, showloader };
};

export default useGetAllBookings;