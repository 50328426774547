import { useEffect, useState } from "react";
import apiService from "../api/authentication-service";

const useUserTotalCountDetails = (userId: number) => {
    const [dashboardValues, setDashboardValues] = useState<number[] | any>([0, 0, 0]);
    const [error, setError] = useState<any>();

    useEffect(() => {
        const sendGetUserTotalCountDetails = async () => {

            try {

                const response = await apiService.getUserTotalCountDetails({
                    user_id: userId
                });
                const data = response.data.data;
                const valuesArray = Object.values(data);
                setDashboardValues(valuesArray);
            } catch (error) {

                console.log('Error: ', error);
                setError(error);
            }
        };

        if (userId) {

            sendGetUserTotalCountDetails();
        }
    }, [userId]);

    return { dashboardValues, error };
};

export default useUserTotalCountDetails;