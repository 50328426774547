import React from 'react';
import { Link } from 'react-router-dom';
import { ImageWithBasePath } from '../../../core/img/ImageWithBasePath';

interface Props {
    route: string;
    iconSrc: string;
    iconAlt: string;
    title: string;
    overlaySrc: string;
    overlayAlt: string;
}

const FeatureBox: React.FC<Props> = ({ route, iconSrc, iconAlt, title, overlaySrc, overlayAlt }) => {

    return (

        <div className="col-sm-12">
            <Link
                to={route}
                className="feature-box aos"
                data-aos="fade-up"
            >
                <div className="feature-icon">
                    <span>
                        <ImageWithBasePath
                            src={iconSrc}
                            alt={iconAlt}
                        />
                    </span>
                </div>
                <h5>{title}</h5>
                <div className="feature-overlay">
                    <ImageWithBasePath
                        src={overlaySrc}
                        alt={overlayAlt}
                    />
                </div>
            </Link>
        </div>
    );
};

export default FeatureBox;