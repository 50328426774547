import React, { useState } from "react";

const RatingStar = (props: { Rating: number, onClick?: boolean }) => {

    const [rating, setRating] = useState(props.Rating);
    const roundedRating = Math.floor(rating);
    const handleStarClick = (index: number) => {
        setRating(index + 1);
    };

    return (
        <div>
            {[...Array(5)].map((_, index) => (
                <i
                    key={index}
                    className={`fas fa-star ${index < roundedRating ? 'filled me-1' : 'me-1'}`}
                    onClick={props.onClick ? () => handleStarClick(index) : undefined}
                />
            ))}
        </div>
    )
};

export default RatingStar;