import { parse, format, isDate } from 'date-fns';

const getTimeDifference = (startTime: string, endTime: string) => {

    let timeDifference = 0;
    try {

        if (startTime && endTime) {

            const [startHour, startMinute] = startTime.split(':').map(Number);
            const [endHour, endMinute] = endTime.split(':').map(Number);

            timeDifference = (endHour - startHour) * 60 + (endMinute - startMinute);
        }
    } catch (error) {

        console.error("ERROR ::", error);
    }
    return timeDifference;
};

const converHoursToMinutes = (time: string) => {

    let totalMinutes = 0;
    try {

        const [hours, minutes] = time.split(':').map(Number);
        totalMinutes = (hours * 60) + minutes;
    } catch (error) {

        console.error("ERROR : ", error);
    }
    return totalMinutes;
};

const getCurrentTime = (time: any) => {

    let formattedTime = '';
    if (isDate(time)) {

        const dateObj = new Date(time);
        formattedTime = format(dateObj, 'HH:mm');
    } else {

        const parsedTime = parse(time, 'hh:mm a', new Date());
        formattedTime = format(parsedTime, 'HH:mm');
    }

    return formattedTime;
};

function convertTimeFormat(inputTime: string) {
    
    const [hours, minutes] = inputTime.split(':');

    const parsedHours = parseInt(hours, 10);
    const parsedMinutes = parseInt(minutes, 10);

    const meridiem = parsedHours < 12 ? 'AM' : 'PM';

    const formattedHours = parsedHours % 12 === 0 ? '12' : (parsedHours % 12 < 10 ? '0' : '') + (parsedHours % 12).toString();

    const formattedMinutes = parsedMinutes < 10 ? '0' + parsedMinutes.toString() : parsedMinutes.toString();

    const formattedTime = formattedHours + ':' + formattedMinutes + ' ' + meridiem;

    return formattedTime;
};

export { getTimeDifference, converHoursToMinutes, getCurrentTime, convertTimeFormat };