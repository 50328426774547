import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HomeHeader from '../../../components/header/home-header';
import SearchSection from '../../../components/searchSection/searchSection';
import PopularBusinessSection from '../../../components/popularBusinessSection/popularBusinessSection';
import FeatureSection from '../../../components/featureSection/featureSection';
import DownloadAppSection from '../../../components/downloadAppSection/download-app-section';
import Footer from '../../../components/footer/footer';
import apiService from '../../../../api/authentication-service';
import ReviewBoxSection from '../../../components/reviewBoxSection/reviewBoxSection';
import { settings } from '../../../../core/data/json/slider_settings';
import { featureBoxData } from '../../../../core/data/json/feature_box_data';
import { RootState } from '../../../../core/data/redux/store';
import { useSelector } from 'react-redux';
import BannerSection from '../../../components/bannerSection/bannerSection';

const HomeOne = () => {
  const [businessDetails, setBusinessDetails] = useState<any[]>([]);
  const userLocationDetails = useSelector((state: RootState) => state.userLocationDetails);
  const [showloader, setShowLoader] = useState<boolean>(true);
  const [showloadererror, setShowLoaderError] = useState('');

  AOS.init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const handleScroll = () => {
    AOS.refresh();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getPopularBusinesses = () => {

    const userLatitude = userLocationDetails?.latitude ?? 0;
    const userLongitude = userLocationDetails?.longitude ?? 0;
    fetchBusinessDetails(userLatitude, userLongitude);
  };

  useEffect(() => {

    getPopularBusinesses();
  }, []);

  const fetchBusinessDetails = (latitude?: number, longitude?: number) => {

    const payload = { latitude, longitude };
    apiService.getBusinessByCategoryAndSubcategory(payload)
      .then((response: any) => {
        if (response?.data?.status === true) {
          setBusinessDetails(response.data.data || []);
          setShowLoader(false);
        } else {

          console.error('Error fetching business details:', response?.data?.message);
          setBusinessDetails([]);
          setShowLoader(false);
          setShowLoaderError("No business to be displayed")
        }
      })
      .catch((error: any) => {

        console.error('Error fetching business details:', error);
        setBusinessDetails([]);
      });
  };

  return (
    <>
      <HomeHeader type={1} />
      <SearchSection />
      <BannerSection />
      <FeatureSection featureBoxData={featureBoxData} />
      <PopularBusinessSection settings={settings} serviceDetailsArray={businessDetails} showloader={showloader} showloadererror={showloadererror} />
      <ReviewBoxSection />
      <DownloadAppSection />
      <Footer />
    </>
  );
};

export default HomeOne;
