import React from "react";
import { ImageWithBasePath } from "../../../core/img/ImageWithBasePath";
import SearchBox from "../searchBox/searchBox";
import { all_routes } from "../../../core/data/routes/all_routes";
import en from "../../../en/en";

const SearchSection = () => {

    const routes = all_routes;

    return (
        <section className="hero-section">
            <div className="container">
                <div className="home-banner">
                    <div className="row align-items-center w-100">
                        <div className="col-lg-7 col-md-10 mx-auto">
                            <div className="section-search aos" data-aos="fade-up">
                                <h1 className="home-heading-custom">{en.HOME_HEADING}</h1>
                                <p className='home-title-custom'>{en.HOME_SUBHEADING}</p>
                                <SearchBox routes={routes.search} />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-imgs">
                                <div className="banner-1 shape-1">
                                    <ImageWithBasePath
                                        className="img-fluid"
                                        alt="banner"
                                        src="assets/img/banner1.png"
                                    />
                                </div>
                                <div className="banner-2 shape-3">
                                    <ImageWithBasePath
                                        className="img-fluid"
                                        alt="banner"
                                        src="assets/img/banner2.png"
                                    />
                                </div>
                                <div className="banner-3 shape-3">
                                    <ImageWithBasePath
                                        className="img-fluid"
                                        alt="banner"
                                        src="assets/img/banner3.png"
                                    />
                                </div>
                                <div className="banner-4 shape-2">
                                    <ImageWithBasePath
                                        className="img-responsive"
                                        alt="banner"
                                        src="assets/img/banner4.png"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default SearchSection;