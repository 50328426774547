import React, { useCallback, useEffect, useState } from 'react';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import ProviderBookingModal from '../../common/modals/provider-booking-modal';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { ButtonIconOnly_Colored } from '../../../components/buttons/button';
import { SearchBar } from '../../../components/inputfields/input';
import { useSelector } from 'react-redux';
import { usePagination } from '../../../../context/paginationContext';
import { CustomerBookingCard } from '../../../components/customerBookingCard/customerBookingCard';
import useGetAllBookings from '../../../../hooks/getAllBookings';
import PaginationComponent from '../../../components/paginationComponent/pagination';
import { WEB_BASE_URL } from '../../../../constants/constants';
import useBookingStatusText from '../../../../helper/bookingStatusCheckFunction';
import './customer-booking-new-style.css';
import en from '../../../../en/en';
import { RootState } from '../../../../core/data/redux/store';
import Loader from '../../../components/loader_spinner/loader_spinner';

const CustomerBooking = () => {

  const limit = 6;
  const routes = all_routes;
  const { count, setCount } = usePagination();
  const userId = useSelector((state: RootState) => state.user.user_id);
  const { bookings, pagesCount, showloader } = useGetAllBookings(userId, count, limit);
  const [searchQuery, setSearchQuery] = React.useState<any>();
  const [filteredBookings, setFilteredBookings] = useState<any[]>([]);

  useEffect(() => {
    setFilteredBookings(bookings);
  }, [bookings]);

  const handlePrevious = useCallback(() => {
    setCount((prevCount) => {
      if (prevCount > 1) {
        return prevCount - 1;
      }
      return prevCount;
    });
  }, []);

  const handleNext = useCallback(() => {
    setCount((prevCount) => {
      if (prevCount < pagesCount) {
        return prevCount + 1;
      }
      return prevCount;
    });
  }, [pagesCount]);

  const handleSearchQuery = (text: string) => {

    setSearchQuery(text);
    const trimmedText = text.trim();
    if (trimmedText === '') {

      setFilteredBookings(bookings);
    } else {
      const filtered = bookings.filter((item: any) =>
        item.service_name.toLowerCase().includes(trimmedText.toLowerCase()) ||
        item.bussiness_name.toLowerCase().includes(trimmedText.toLowerCase())
      );

      setFilteredBookings(filtered);
    }
  };

  return (
    <>
      <div className="page-wrapper customer-booking">
        <div className="content container-fluid box-sizing-border">
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="provider-subtitle">
                  <h6>{en.BOOKING_LIST}</h6>
                </div>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                <div className="grid-listview" >
                  <ul>
                    <li>
                      <SearchBar placeholderText={'Search by services'} icon={'Search'} style={{ color: '#0445CF' }} className='form-control-new' value={searchQuery} onChangeText={handleSearchQuery} />
                    </li>
                    <li>
                      <ButtonIconOnly_Colored icon={'Calendar'} route={routes.customerBookingCalendar} />
                    </li>
                    <li>
                      <ButtonIconOnly_Colored icon={'Grid'} route={''} className='active active-new' />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Loader showloader={showloader} style={{ marginTop: '150px' }} />
          <div className={`${filteredBookings.length === 0 ? 'no-bookings' : 'booking-card'} box-sizing-border`}>
            {filteredBookings.length > 0 ? (<React.Fragment>
              {!showloader && filteredBookings.map((booking: any, index: number) => {
                const { text, className } = useBookingStatusText(booking);

                return (
                  <div key={index}>
                    <CustomerBookingCard
                      Imgsrc={WEB_BASE_URL + booking.service_image}
                      ServiceName={booking.bussiness_name}
                      Date={booking.booking_for}
                      Time={booking.booking_slot.replace(/(am|pm)/i, (match: any) => match.toUpperCase())}
                      statusText={text}
                      statusClassName={className}
                      PersonName={booking.first_name + ' ' + booking.last_name}
                      user_id={booking.user_id}
                      bookingid={booking.booking_id}
                      tokennumber={booking.token_number}
                      Bookedfor={booking.first_name + ' ' + booking.last_name}
                      BusinessServiceName={booking.service_name}
                      svgImage={booking.walkin !== 0 ? (
                        <ImageWithBasePath src="assets/img/icons/person-walking-white.svg" alt="image" className='person-walking-class-customer' />)
                        : (
                          <ImageWithBasePath src="assets/img/icons/hand -white.svg" alt="image" className='hand-class' />
                        )}
                      BusinessTimeZone={booking.business_timezone}
                    />
                  </div>
                );
              })}
            </React.Fragment>) : (
              <React.Fragment>
                {!showloader && (<div>
                  <h1>{en.NO_BOOKING}</h1>
                </div>)}
              </React.Fragment>
            )}
          </div>
          {!showloader && (<div className="row col-md-12 col-sm-12 align-items-center justify-content-center">
            <div className="d-flex justify-content-center">
              {pagesCount > 1 &&
                <div>
                  <PaginationComponent
                    totalPages={pagesCount}
                    currentPage={count}
                    handlePrevious={() => handlePrevious()}
                    handleNext={() => handleNext()}
                    handleBeginning={() => setCount(1)}
                    handleEnd={() => setCount(pagesCount)}
                  />
                </div>
              }
            </div>
          </div>)}
        </div>
      </div><ProviderBookingModal />
    </>
  );
};

export default CustomerBooking;
