import React from "react";
import { Link, Route } from "react-router-dom";
import * as Icon from 'react-feather';
import { all_routes } from "../../../core/data/routes/all_routes";
import { Rating_With_TextProps } from "../../../core/models/interface";


const Ratingwithtext: React.FC<Rating_With_TextProps> = (Props) => {
    return (
        <p>
            <span className="rate">
                <i className="fas fa-star filled" />
                &nbsp;
                {Props.rating}
            </span></p>

    )
}
export default Ratingwithtext