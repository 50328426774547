import { useEffect, useState } from "react";
import apiService from "../api/authentication-service";

const useGetAllFavorites = (userId: number, page?: number, limit?: number, allFavourites?: boolean) => {
    const [favorites, setFavorites] = useState([]);
    const [totalCount, settotalCount] = useState(0);
    const [showloaderfav, setShowLoaderfav] = useState<boolean>(true);
    const [error, setError] = useState<any>();
    const pagesCount = Math.ceil(totalCount / (limit || 1));

    useEffect(() => {

        const sendGetAllFavorites = async () => {

            try {

                const response = await apiService.getUserFavouritesLimited({
                    user_id: userId,
                    limit,
                    page,
                    allFavourites
                });

                if (response.data.status === true) {
                    setFavorites(response.data.data.favourites);
                    settotalCount(response.data.data.totalFavourites);
                    setShowLoaderfav(false);
                }
            } catch (error) {

                console.log('Error: ', error);
                setError(error);
            }
        }

        if (userId) {

            sendGetAllFavorites();
        }

    }, [userId, page, limit]);

    return { favorites, totalCount, pagesCount, error, showloaderfav };
};

export default useGetAllFavorites;
