import React, { createContext, ReactNode, useContext, useState } from "react";

const PaginationContext = createContext<{
    count: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
  } | undefined>(undefined);
  
  const PaginationProvider = ({ children }: { children: ReactNode }) => {
    const [count, setCount] = useState(1);
  
    return (
      <PaginationContext.Provider value={{ count, setCount }}>
        {children}
      </PaginationContext.Provider>
    );
  };

  const FavouritePaginationProvider = ({ children }: { children: ReactNode }) => {
    const [count, setCount] = useState(1);
    
    return (
      <PaginationContext.Provider value={{ count, setCount }}>
        {children}
      </PaginationContext.Provider>
    );
  };

const usePagination = () => {

    const context = useContext(PaginationContext);
    if (!context) {

      throw new Error('usePagination must be used within a PaginationProvider');
    }
    
    return context;
  };

export {PaginationProvider, FavouritePaginationProvider, usePagination};