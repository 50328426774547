import React from "react";

interface TableTagProps {
    className?: string,
    children: React.ReactNode
}

const TableTag = (props: TableTagProps) => {

    return (
        <table className={props.className}>
            <tbody>
                {props.children}
            </tbody>
        </table>
    )
};

export default TableTag;