import React, { useState } from "react";
import { ImageWithBasePath } from "../../../core/img/ImageWithBasePath";
import { ProvideInfoBoxProps } from "../../../core/models/interface";
import { Link } from "react-router-dom";
import './providerinfoBox.css';

const ProvideInfoBox = (props: ProvideInfoBoxProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const formatUrl = (url: any | null) => url ? url.replace(/(^\w+:|^)\/\//, '') : '';

    // Function to handle website redirection
    const handleWebsiteClick = (url: any) => {
        window.open(url, '_blank'); // Opens the link in a new tab
    };

    const viewLargeMapUrl = (latitude: number, longitude: number) => {
        return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    };

    return (
        <React.Fragment>
            {props.icon && props.iconName && (
                <div className={props.provideInfoMainContainerClass ? props.provideInfoMainContainerClass : "provide-box"}>
                    <span>
                        {typeof props.icon !== 'string' ?
                            <props.icon
                                className="react-feather-custom"
                            />
                            :
                            <ImageWithBasePath
                                src={props.icon}
                                alt="img"
                            />
                        }
                    </span>
                    <div className={props.provideInfoContainerClass ? props.provideInfoContainerClass : "provide-info w-79"}>
                        <h6>{props.iconName}</h6>
                        {props.iconName === "Email" ? (
                            <p
                                className="website-link text-overflow-elipses"
                            >
                                <a href={`mailto:${props.info}`} className="email-link"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    style={{ color: isHovered ? '#0445cf' : 'inherit', textDecoration: 'none' }}
                                >
                                    {props.info}
                                </a>
                            </p>
                        ) : props.iconName === "Phone" ? (
                            <a href={`tel:${props.info}`} className="phone-link"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{ color: isHovered ? '#0445cf' : 'inherit', textDecoration: 'none' }}
                            >
                                {props.info}
                            </a>
                        ) : props.iconName === "Website" ? (
                            <p
                                className="website-link text-overflow-elipses"
                            >
                                <span
                                    onClick={() => handleWebsiteClick(props.info)}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    style={{
                                        color: isHovered ? '#0445cf' : 'inherit',
                                        textDecoration: 'none',
                                    }}
                                    className="mouse-pointer"
                                >
                                    {formatUrl(props.info)}
                                </span>
                            </p>
                        ) : props.iconName === "Address" && props.latitude && props.longitude ? (
                            <Link
                                target='_blank'
                                to={viewLargeMapUrl(props.latitude, props.longitude)}
                                style={{ textDecoration: 'none', color: isHovered ? '#0445cf' : 'inherit' }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <p className="website-link text-overflow-elipses"
                                    style={{ cursor: 'pointer', color: isHovered ? '#0445cf' : 'inherit' }}>
                                    {props.info}
                                </p>
                            </Link>
                        ) : (
                            <p>{props.info}</p>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    )
};

export default ProvideInfoBox;