import React from "react";
import en from "../../../../en/en";
import { ImageWithBasePath } from "../../../../core/img/ImageWithBasePath";
import { useSelector } from "react-redux";
import useUserTotalCountDetails from "../../../../hooks/getTotalCountDetails";
import "./customerDashboardWidgetSectionStyle.css";

const CustomerDashboardWidgetSection = () => {

    const userId = useSelector((state: any) => state.user.user_id);
    const { dashboardValues } = useUserTotalCountDetails(userId);

    const dashBoardDetails = [
        {
            imgSrc: "assets/img/icons/dash-icon-01.svg",
            title: en.TOTAL_BOOKINGS
        },
        {
            imgSrc: "assets/img/icons/heart-icon.svg",
            title: en.FAVORITES
        },
        {
            imgSrc: "assets/img/icons/user.svg",
            title: en.FAMILY_MEMBERS
        },
    ];

    return (
        <React.Fragment>
            <div className="row dashBoard-widget d-flex flex-wrap" >
                {dashBoardDetails.map((item, index) => (
                    <div className="col-md-6 col-lg-3 d-flex flex-grow-1" key={index}>
                        <div className="dash-widget flex-fill" >
                            <div className="dash-img d-flex align-content-center">
                                <span className="dash-icon bg-yellow align-self-center">
                                    <ImageWithBasePath
                                        src={item.imgSrc}
                                        alt="image"
                                    />
                                </span>
                            </div>
                            <div className="dash-info">
                                <div className="dash-order">
                                    <h6>{item.title}</h6>
                                    <h5>{dashboardValues[index]}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment >
    )
};

export default CustomerDashboardWidgetSection;