import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import * as Icon from 'react-feather';
import { ImageWithBasePath, ImageWithBasePathWithUrl } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import ServiceNameSection from '../../../components/serviceNameSection/serviceNameSection';
import ProvideInfoBox from '../../../components/provideInfoBox/provideInfoBox';
import TimeSlot from '../../../components/timeSlot/timeSlot';
import apiService from '../../../../api/authentication-service';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import './service-details2.css';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownInputvalue, TKN_DropdownInputvalue } from '../../../components/inputfields/input';
import { BusinessState } from '../../../../core/data/redux/initial.values';
import { WEB_BASE_URL } from '../../../../constants/constants';
import { ButtonTextOnly_Colored } from '../../../components/buttons/button';
import { TKN_Danger_message } from '../../../components/alert_messages/alert_messages';
import HomeHeader from '../../../components/header/home-header';
import FooterOne from '../../../components/footer-one';
import { Spinner } from 'react-bootstrap';

type Option = {
  value: number;
  label: string;
};

type Service = BusinessState['services'][number];

interface FamilyMember {
  family_member_id: number;
  family_member_name: string;
  relation_name: string;
}

interface BookingData {
  user_id: number;
  family_member_id: number;
  vendor_map_id: string;
  booking_for: string;
  booking_slot: string;
}

const BookingAppointment = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, onChange] = useState(new Date());
  const [availableSlot, setAvailableSlots] = useState([]);
  const [activeSection, setActiveSection] = useState('appointment');
  const [familyMembers, setFamilyMembers] = useState<FamilyMember[]>([]);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState<number | null>(null);
  const [selectedFamilyMemberError, setSelectedFamilyMemberError] = useState('');
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
  const [selectedSlotError, setSelectedSlotError] = useState<string>('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const user_id = useSelector((state: RootState) => state.user.user_id);
  const user = useSelector((state: RootState) => state.user);
  const guest = useSelector((state: RootState) => state.guest);
  const guest_user = useSelector((state: RootState) => state.guest?.user);
  const [isGuest, setIsGuest] = useState<boolean>(false);
  const businessDetails = useSelector((state: RootState) => state.business);
  const vendorMapDetails = useSelector((state: RootState) => state.vendorMapDetails);
  const [serverError, setServerError] = useState('')
  const location = useLocation();
  const stateValue = location.state || {};
  const { DropdownComponent, toggleDropdown } = DropdownInputvalue();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const {
    mobile,
    bussiness_address,
    vendor_email,
    bussiness_name,
    vendorMapId
  } = vendorMapDetails;

  const {
    website
  } = businessDetails;

  let service: any = {};
  const getServiceWithBusinessDetails = functionGetServiceMapID(businessDetails);
  service = getServiceWithBusinessDetails(vendorMapId);

  function functionGetServiceMapID(businessDetails: any) {
    return (vendorMapId: any) => {
      if (vendorMapId) {
        const foundService = businessDetails.services?.find(
          (service: Service) => service.service_vendor_map_id === vendorMapId
        );

        if (foundService) {
          return foundService;
        } else {
          return null;
        };
      };
    };
  };

  useEffect(() => {
    sendGetAvailableSlotsRequest(value, vendorMapId);
  }, [vendorMapId, value]);

  useEffect(() => {
    if (stateValue === true) {
      fetchFamilyMembers(user_id, true);
    }

    fetchFamilyMembers(user_id);
  }, [user_id]);

  if (!user.user_id) {
    useEffect(() => {
      setIsGuest(guest_user === "guest");
    }, [guest_user]);
  }

  const handleDateChange = (newValue: Date) => {
    onChange(newValue);
    setAvailableSlots([]);
    sendGetAvailableSlotsRequest(newValue, vendorMapId);
  };

  const sendGetAvailableSlotsRequest = (date: Date, vendor_map_id: string) => {
    const adjustedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    const booking_for = adjustedDate.toISOString().split('T')[0];
    const payload = { booking_for, vendor_map_id };

    apiService.getAvailableSlots(payload)
      .then((response) => {
        getAvailableSlotsResponse(response?.data);
      })
      .catch((error) => {
        console.error('Error while fetching available slots:', error);
      });
  };

  const getAvailableSlotsResponse = (response: any) => {
    if (response) {
      const status = response?.status;
      if (status === true) {
        const responseData = response?.data;
        setAvailableSlots(responseData);
      }
    }
  };

  const fetchFamilyMembers = (user_id: number, updateMember = false) => {
    const payload = { user_id };
    apiService.getAllFamilyMembersByUserId(payload)
      .then((response) => {
        getFamilyMembersResponse(response?.data, updateMember);
      })
      .catch((error) => {
        console.error('Error while fetching family members:', error);
      });
  };

  const getFamilyMembersResponse = (response: any, updateMember: boolean) => {
    if (response) {
      const status = response?.status;
      if (status === true) {
        const responseData = response?.data;
        setFamilyMembers(responseData);
        if (responseData.length > 0 && isGuest) {
          setSelectedFamilyMember(responseData[0].family_member_id);
        }
        if (updateMember) {
          if (responseData.length > 0) {
            const allMembers = responseData?.map((item: any) => ({
              value: item.family_member_id,
              label: `${item.relation_name} - ${item.family_member_name}`
            }));

            if (allMembers.length > 0) {
              setSelectedLabel(allMembers[allMembers.length - 1].label);
              setSelectedFamilyMember(allMembers[allMembers.length - 1].value);
            }
          }
        }
      }
    }
  };

  const convertTo24HourFormat = (timeSlot: string): string => {
    if (!timeSlot) return '';

    const [time, period] = timeSlot.split(' ');
    const [hours, minutes] = time.split(':').map(Number);

    let adjustedHours = hours;
    if (period === 'PM' && hours !== 12) {
      adjustedHours += 12;
    }
    if (period === 'AM' && hours === 12) {
      adjustedHours = 0;
    }

    return `${String(adjustedHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  const handleWalkinBooking = () => {
    if (!selectedSlot) {
      setSelectedSlotError('Please select a time slot.');
      return;
    }
    else {
      setSelectedSlotError('')
    }
    const adjustedDate = new Date(value.getTime() - (value.getTimezoneOffset() * 60000));
    const booking_for = adjustedDate.toISOString().split('T')[0];
    const booking_slot_24 = convertTo24HourFormat(selectedSlot);

    const payload = {
      vendor_map_id: vendorMapId,
      first_name: guest.firstName,
      last_name: guest.lastName,
      email: guest.email,
      phone: guest.phone,
      gender: guest.gender,
      country: guest.country,
      country_code: guest.country_code,
      country_details: JSON.stringify(guest.country_details),
      vendor_id: businessDetails.vendor_id,
      service_id: service?.service_vendor_map_id,
      booking_for: booking_for,
      booking_slot: booking_slot_24,
      dob: guest.dob,
    };

    apiService.addWalkingbooking(payload)
      .then((response) => {
        const responseData = response.data as { status: boolean; message?: string };
        if (responseData.status) {
          setActiveSection('done');
        } else {
          setServerError(responseData.message || 'Booking failed. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error while booking appointment:', error);
        setServerError('An error occurred while processing your request. Please try again.');
      });
  };
  const handleFormSubmit = () => {
    if (guest_user == "guest") {
      handleWalkinBooking();
    }
    else {
      if (!selectedFamilyMember) {
        setSelectedFamilyMemberError('Please select a member.');
        return;
      } else {
        setSelectedFamilyMemberError('')
      }
      if (!selectedSlot) {
        setSelectedSlotError('Please select a time slot.');
        return;
      } else {
        setSelectedSlotError('')
      }


      const adjustedDate = new Date(value.getTime() - (value.getTimezoneOffset() * 60000));
      const booking_for = adjustedDate.toISOString().split('T')[0];
      const booking_slot_24 = convertTo24HourFormat(selectedSlot);

      const payload: BookingData = {
        user_id,
        family_member_id: selectedFamilyMember,
        vendor_map_id: vendorMapId,
        booking_for,
        booking_slot: booking_slot_24
      };

      apiService.addBookings(payload)
        .then((response) => {
          const responseData = response.data as { status: boolean; message?: string };
          if (responseData.status) {
            // dispatch(resetVendorMapDetails());
            setActiveSection('done');
          } else {
            setServerError(responseData.message || 'Booking failed. Please try again.');
          }
        })
        .catch((error) => {
          console.error('Error while booking appointment:', error);
          setServerError('An error occurred while processing your request. Please try again.');
        });
    }
  };

  const functionAddMembers = (familyMembers: FamilyMember[] | []) => {
    let members: Option | any;

    if (familyMembers.length > 0) {
      members = familyMembers.map((item) => ({
        value: item.family_member_id,
        label: `${item.relation_name} - ${item.family_member_name}`
      }));
    }

    return (additionalMember: Option) => {
      if (members?.length > 0 && additionalMember) {

        return [...members, additionalMember];
      } else {

        return [additionalMember];
      }
    }
  };

  const bookingForArray = functionAddMembers(familyMembers);
  const combinedFamilyMembers = bookingForArray({ value: -1, label: 'Add member' });

  const handleCancelButton = () => {
    navigate(-1);
  };

  const handleOptionSelect = (selectedItem: Option) => {
    toggleDropdown();
    if (selectedItem.label === "Add member") {
      navigate(routes.customerAddFamily, { state: { from: routes.bookingAppointment } });
    } else {
      setSelectedFamilyMember(selectedItem.value);
      setSelectedLabel(selectedItem.label);
    }
    setSelectedFamilyMemberError('');
  };

  const handleServerErrorClose = () => {
    setServerError('');
  }
  return (
    <div>
      <HomeHeader type={1} />
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <ul className="step-register row">
                <li
                  className={`col-md-6 appointmentandconfirmation1 ${activeSection === 'appointment' ? 'active' : ''}`}
                // onClick={() => setActiveSection('appointment')}
                >
                  <div className="multi-step-icon">
                    <ImageWithBasePath src="assets/img/icons/calendar-icon.svg" alt="img" />
                  </div>
                  <div className="multi-step-info">
                    <h6>Appointment</h6>
                    <p>Choose time &amp; date for the service</p>
                  </div>
                </li>
                <li
                  className={`col-md-6 justify-content-start appointmentandconfirmation2 ${activeSection === 'done' ? 'active' : ''}`}
                // onClick={() => setActiveSection('done')}
                >
                  <div className="multi-step-icon">
                    <ImageWithBasePath src="assets/img/icons/book-done.svg" alt="img" />
                  </div>
                  <div className="multi-step-info">
                    <h6>Confirmation</h6>
                    <p>Completion of Booking</p>
                  </div>
                </li>
              </ul>

              {activeSection === 'appointment' && (
                <div>
                  <div className="booking-service">
                    {serverError && <TKN_Danger_message text={serverError} onClick={handleServerErrorClose} />}
                    <div className="row align-items-center">
                      <ServiceNameSection
                        vendorServiceName={service?.service_name}
                        vendorName={bussiness_name}
                        serviceImageSrc={`${WEB_BASE_URL}${service?.service_image}`}
                        vendorImageSrc={<Icon.Home size={18} />}
                      />
                      <div className="col-lg-6">
                        <div className="row align-items-start">
                          <div className="col-md-6 col-sm-6">
                            <ProvideInfoBox icon={Icon.Phone} iconName="Phone" info={mobile} />
                            <ProvideInfoBox icon={Icon.Mail} iconName="Email" info={vendor_email} />
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <ProvideInfoBox icon={Icon.MapPin} iconName="Address" info={bussiness_address} latitude={businessDetails?.latitude} longitude={businessDetails?.longitude} />
                            {website ? (
                              <ProvideInfoBox icon={'assets/img/icons/service-icon.svg'} iconName="Website" info={website} />
                            ) : ('')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="book-form">
                    <div className="row">
                      {!isGuest && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Booking For</label>
                            <DropdownComponent
                              placeholderText="Select Family Member"
                              options={combinedFamilyMembers}
                              handleOptionSelect={handleOptionSelect}
                              value={selectedLabel}
                            />
                            {selectedFamilyMemberError ? (<div className='error-msg'>{selectedFamilyMemberError}</div>) : ('')}
                          </div>
                        </div>
                      )}
                      {isGuest && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Booking For</label>
                            <TKN_DropdownInputvalue
                              placeholderText="Self"
                              options={[]}
                              isDisabled={true}
                            />
                            {selectedFamilyMemberError ? (<div className='error-msg'>{selectedFamilyMemberError}</div>) : ('')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="book-title">
                        <h5 className='servicedetails-custom-title'>Appointment Date</h5>
                      </div>
                      <div id="datetimepickershow">
                        <Calendar
                          onChange={(value: any) => handleDateChange(value)}
                          value={value}
                          className={"react-calendar-style"}
                          prev2Label={null}
                          next2Label={null}
                        />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="book-title">
                            <h5 className='servicedetails-custom-title'>Appointment Time</h5>
                          </div>
                        </div>
                      </div>
                      <div className="token-slot mt-2">
                        <TimeSlot availableSlots={availableSlot}
                          onSelectSlot={(slot) => {
                            setSelectedSlot(slot);
                            if (selectedSlotError) {
                              setSelectedSlotError('');
                            }
                          }}
                        />
                        {selectedSlotError && <div className='error-msg'>{selectedSlotError}</div>}
                      </div>
                      <div className="book-submit text-end">
                        <ButtonTextOnly_Colored label={"Cancel"} fullWidth={false} onClick={handleCancelButton} className='servicedetails-custom-cancelbtn' />
                        <ButtonTextOnly_Colored label={"Book Appointment"} fullWidth={false} onClick={handleFormSubmit} className='servicedetails-custom-appointmentbtn' />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeSection === 'done' && (
                <div className="done-section">
                  {/* <div className="row justify-content-center w-100 margin-l">
                    <div className="col-md-5 w-50">
                      <div className="card-wrapper">
                        <div className="booking-completed done-completed">
                          <h4>Booking Summary</h4>
                          <div className="card" style={{ backgroundColor: '#F2F2FF', padding: '50px', borderRadius: '18px' }}>
                            <div className="summary-content">
                              <div className="summary-img1">
                                {<ImageWithBasePathWithUrl src={`${WEB_BASE_URL}${service?.service_image}`} alt="img" className="img-size" />}
                              </div>
                              <div className="summary-details">
                                <h6>{bussiness_name}</h6>
                                <p style={{ color: '#0445CF' }} className='para-text-servicedetail para-text-elipses'>{service?.service_name}</p>
                                <div className='d-flex w-100'>
                                  <p className='fontsize-phoneandaddress para-text-elipses w-48 '><Icon.Phone size={15} /> {mobile}</p>
                                  <p className='fontsize-phoneandaddress para-text-elipses w-50'>| <Icon.MapPin size={15} /> {bussiness_address}</p>
                                </div>
                              </div>
                            </div>
                            <div className="summary-info">
                              <div className="info-row">
                                <p className="label">Date</p>
                                <p className="value para-text-elipses" >
                                  {`${value.toDateString().slice(0, 3)}, ${value.toDateString().slice(4, 10)}-${value.getFullYear()}`}
                                </p>
                              </div>
                              <div className="info-row">
                                <p className="label">Time</p>
                                <p className="value para-text-elipses">{selectedSlot}</p>
                              </div>
                              <div className="info-row">
                                <p className="label">Name</p>
                                <p className="value para-text-elipses">
                                  {!user.user_id
                                    ? guest.firstName.concat(" ", guest.lastName)
                                    : user.first_name.concat(" ", user.last_name) || 'N/A'}
                                </p>
                              </div>
                              <div className="info-row">
                                <p className="label">Email</p>
                                <p className="value para-text-elipses">{!user.user_id ? guest.email : user.email}</p>
                              </div>
                              <div className="info-row">
                                <p className="label">Mobile</p>
                                <p className="value para-text-elipses">{!user.user_id ? guest.phone : user.phone}</p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 w-50">
                      <div className="card-wrapper">
                        <div className="booking-completed done-completed">
                          <h4>Booking Completed</h4>
                          <div className="completed-img">
                            <ImageWithBasePath src="assets/img/TKN_images/TKN_booking_successful.jpg" />
                          </div>
                          <p>Your Booking has been Successfully Completed</p>
                          <div className="done-buttons">
                            <ButtonTextOnly_Colored
                              label={isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : "Go To Home"}
                              fullWidth={false}
                              onClick={() => navigate(routes.homeOne)}
                              disabled={isLoading}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className='row d-flex w-100 flex-no-wrap colown-reverse-flex new-card-style margin-l-1px' >
                    <div className='col-md-6 d-flex justify-content-center flex-wrap' >
                      <h4 className='bookingtext w-100'>Booking Summary</h4>
                      <div className='col-md-10  bookingsummarybox w-100' >
                        <div className='col-md-12 d-flex justify-content-between margin-b-30 height-bookingnameandimage'>
                          <div className="booking-imgbox">
                            <ImageWithBasePathWithUrl src={`${WEB_BASE_URL}${service?.service_image}`} alt="img" className="img-size-new" />
                          </div>
                          <div className="booking-customerdetails">
                            <div className='col-md-12 text-align-start w-100'>
                              <h6 className='w-100 para-text-elipses margin-b-10 line-height-title'>{bussiness_name}</h6>
                              <p className='w-100 para-text-elipses margin-b-10 text-primary-clr'>{service?.service_name}</p>
                              <div className='w-100 d-flex-new justify-content-between flex-no-wrap h-30 line-height-title'>
                                <div className='w-40 line-height-title phone-icon margin-b-new-0 '>
                                  <a href={`tel:${mobile}`} >
                                    <p className='fontsize-phoneandaddress para-text-elipses margin-t-1 phoneandaddresslink '><Icon.Phone size={15} className='margin-right-icon-new' /> {mobile}</p>
                                  </a>
                                </div>
                                <div className='w-9 d-flex-new2 justify-content-center line-height-title gap-icon'>
                                  <p>|</p>
                                </div>
                                <div className='w-56 line-height-title location-icon'>
                                  <p className='fontsize-phoneandaddress para-text-elipses margin-t-1'><Icon.MapPin size={15} className='margin-right-icon-new' /> {bussiness_address}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className='col-md-12 d-flex justify-content-between ' >
                            <div className='w-40'>
                              <p className="label text-align-start">Date</p>
                            </div>
                            <div className='w-60'>
                              <p className="value para-text-elipses" >
                                {`${value.toDateString().slice(0, 3)}, ${value.toDateString().slice(4, 7)}-${value.toDateString().slice(8, 10)}-${value.getFullYear()}`}
                              </p>
                            </div>
                          </div>
                          <div className='col-md-12 d-flex justify-content-between '>
                            <div className='w-40'>
                              <p className="label text-align-start">Time</p>
                            </div>
                            <div className='w-60'>
                              <p className="value para-text-elipses">{selectedSlot}</p>
                            </div>
                          </div>
                          <div className='col-md-12 d-flex justify-content-between '>
                            <div className='w-40'>
                              <p className="label text-align-start">Name</p>
                            </div>
                            <div className='w-60'>
                              <p className="value para-text-elipses">
                                {!user.user_id
                                  ? guest.firstName.concat(" ", guest.lastName)
                                  : user.first_name.concat(" ", user.last_name) || 'N/A'}
                              </p>
                            </div>
                          </div>
                          <div className='col-md-12 d-flex justify-content-between '>
                            <div className='w-40'>
                              <p className="label text-align-start">Email</p>
                            </div>
                            <div className='w-60'>
                              <p className="value para-text-elipses">{!user.user_id ? guest.email : user.email}</p>
                            </div>
                          </div>
                          <div className='col-md-12 d-flex justify-content-between height-22px '>
                            <div className='w-40 height-22px'>
                              <p className="label text-align-start margin-0-new">Mobile</p>
                            </div>
                            <div className='w-60 height-22px'>
                              <p className="value para-text-elipses height-22px margin-0-new">{!user.user_id ? guest.phone : user.phone}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-center flex-wrap' >
                      <h4 className='bookingtext w-100'>Booking Completed</h4>
                      <div className='col-md-10'>
                        <div className="completed-img">
                          <ImageWithBasePath src="assets/img/TKN_images/TKN_booking_successful.jpg" />
                        </div>
                        <p>Your Booking has been Successfully Completed</p>
                        <div className="done-buttons">
                          <ButtonTextOnly_Colored
                            label={isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : "Go To Home"}
                            fullWidth={false}
                            onClick={() => navigate(routes.homeOne)}
                            disabled={isLoading}
                          />
                          {/* <Link to={routes.bookingHistory} className="btn btn-secondary">Booking History</Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
    </div>
  );
};

export default BookingAppointment;
