import { useEffect, useState } from "react";
import apiService from "../api/authentication-service";

const useGetVendorActiveServicesDetails = (vendor_id: number) => {
    const [error, setError] = useState<any>();
    const [serviceData, setServiceData] = useState<[]>();
    const [totalBookingsCount, setTotalBookingsCount] = useState<[]>();
    const [totalCheckInBookingsCount, setTotalCheckInBookingsCount] = useState<[]>();
    const [totalCheckOutBookingsCount, setTotalCheckOutBookingsCount] = useState<[]>();
    const [totalCancelledBookingsCount, setTotalCancelledBookingsCount] = useState<[]>();
    const [showloaderservice, setShowLoaderService] = useState<boolean>(true);

    useEffect(() => {

        const getVendorActiveServicesDetails = async () => {

            try {

                const response = await apiService.getVendorActiveServicesDetails({
                    vendor_id: vendor_id
                });

                if (response.data.status === true) {

                    setServiceData(response.data.data.Vendorservicename);
                    setTotalBookingsCount(response.data.data.VendorTokenBookingscount);
                    setTotalCheckInBookingsCount(response.data.data.VendorCheckinBookingscount);
                    setTotalCheckOutBookingsCount(response.data.data.vendorCheckOutBookingsCount);
                    setTotalCancelledBookingsCount(response.data.data.vendorCancelledBookingsCount);
                    setShowLoaderService(false);
                } else {

                    setShowLoaderService(false);
                }
            } catch (error) {

                console.log(error);
                setError(error);
            }
        };

        if (vendor_id) {

            getVendorActiveServicesDetails();
        }
    }, [vendor_id]);

    return { serviceData, totalBookingsCount, totalCheckInBookingsCount, totalCheckOutBookingsCount, totalCancelledBookingsCount, error, showloaderservice };
};

export default useGetVendorActiveServicesDetails;