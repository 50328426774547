import React from 'react';
import 'aos/dist/aos.css';
import { ReviewBoxList } from '../../reviewBoxs/reviewBox';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';

const routes = all_routes;
const ReviewBoxListing = () => {

    return (
        <React.Fragment>
            <div className="service-wrap hide">
                <h5>Reviews</h5>
                <ul>
                    <ReviewBoxList
                        reviewerImageSrc={'assets/img/profiles/avatar-02.jpg'}
                        reviewerName={'Dennis'}
                        ratingNumber={4}
                        reviewContent={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua. Ut enim ad minim veniam, quis nostrud exercitationullamco laboris nisi'}
                        reviewDateAndTime={'a week ago'}
                        reviewBoxTag='li'
                    />
                    <ReviewBoxList
                        reviewerImageSrc={'assets/img/profiles/avatar-03.jpg'}
                        reviewerName={'Jaime'}
                        ratingNumber={5}
                        reviewContent={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua. Ut enim ad minim veniam, quis nostrud exercitationullamco laboris nisi'}
                        reviewDateAndTime={'2 days ago | 14:35PM '}
                        reviewBoxTag='li'
                    />
                    <ReviewBoxList
                        reviewerImageSrc={'assets/img/profiles/avatar-07.jpg'}
                        reviewerName={'Martinez'}
                        ratingNumber={4}
                        reviewContent={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua. Ut enim ad minim veniam, quis nostrud exercitationullamco laboris nisi'}
                        reviewDateAndTime={'yesterday | 10:35AM '}
                        reviewBoxTag='li'
                    />
                    <ReviewBoxList
                        reviewerImageSrc={'assets/img/profiles/avatar-05.jpg'}
                        reviewerName={'Bradley'}
                        ratingNumber={3}
                        reviewContent={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaaliqua. Ut enim ad minim veniam, quis nostrud exercitationullamco laboris nisi'}
                        reviewDateAndTime={'1 month ago | 17:35PM '}
                        reviewBoxTag='li'
                    />
                </ul>
                <div className="text-center">
                    <Link
                        to={''}
                        className="btn btn-primary btn-review"
                    >
                        View All Reviews
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ReviewBoxListing;