import React from 'react'
import { Link } from 'react-router-dom';
import { ImageWithBasePath } from '../../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../../core/data/routes/all_routes';

const PagesAuthHeader = () => {

  const routes = all_routes;

  return (

    <header className="header">
      <div className="container">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <Link to={routes.homeOne} className="navbar-brand logo img-logo-custom">
              <ImageWithBasePath src="assets/img/TKN_images/TestingLogo.png" className="img-fluid max-height-logo" /* className="img-fluid" */ alt="Logo" />
            </Link>
            <Link to={routes.homeOne} className="navbar-brand logo-small img-logosmall-custom img-logosmall-login-custom">
              <ImageWithBasePath src="assets/img/TKN_images/logo-small.png" className="img-fluid" alt="Logo" />
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default PagesAuthHeader