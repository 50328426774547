import { useState } from "react";

export const useSearch = (data: any[]) => {
    return (searchKey: any) => {
        const [searchQuery, setSearchQuery] = useState('');
        const [filteredData, setFilteredData] = useState(data);

        const handleSearchQuery = (query: string) => {
            setSearchQuery(query);
            const trimmedQuery = query.trim().toLowerCase();

            if (trimmedQuery === '') {
                setFilteredData(data);
            } else {
                const filtered = data.filter((item: any) => {
                    return String(item[searchKey]).toLowerCase().includes(trimmedQuery)
                });
                setFilteredData(filtered);
            }
        };

        return { searchQuery, filteredData, handleSearchQuery };
    }
};