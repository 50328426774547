// localStorage.ts
export const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };
  
  /* export const saveState = (state: any) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch (err) {
      // Ignore write errors.
    }
  }; */

  export const saveState = (state: any, retainOnlySelected = false) => {
    try {
      const stateToSave = retainOnlySelected
        ? { 
            userLocationDetails: state.userLocationDetails,
            sessionTokenDetails: state.sessionTokenDetails,
            searchRequestData: state.searchRequestData,
          }
        : state;
  
      const serializedState = JSON.stringify(stateToSave);
      localStorage.setItem('state', serializedState);
    } catch (err) {
      // Ignore write errors
    }
  };
  