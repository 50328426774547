// apiClient.tsx
import axios from 'axios';
import { WEB_BASE_URL } from '../constants/constants';

const apiClient = axios.create({
  baseURL: WEB_BASE_URL + 'api/',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// Request interceptor to set the Authorization and X-CSRF-TOKEN headers
apiClient.interceptors.request.use((config: any) => {
  try {
    if (config && config.headers) {
      const accessToken = "108|oS69VSG1Fmcbz7oBsdfi5LmXPi95LwMMGbhPZXnk"

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        console.log('No access token found');
      };
    };

    console.log('Request Config:', config);
    return config;
  } catch (error) {
    console.log('Request Error:', error);
  };
});

// Response interceptor to handle errors
apiClient.interceptors.response.use((response) => response,
  (error) => {
    
    if (error.response) {
      console.log('Response Status:', error.response.status);
      console.log('Response Data:', error.response.data);
    };

    return Promise.reject(error);
  }
);

export { apiClient };