import { useState, useEffect } from 'react';

interface GoogleWindow extends Window {
    google?: {
        maps?: {
            places?: any;
        }
    }
}

export const useLoadGoogleMapAPI = (anyGoogleApi: string) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        const loadGoogleMapsAPI = async () => {
            try {
                if (document.getElementById('google-maps-script')) {
                    if (
                        (window as GoogleWindow).google &&
                        (window as GoogleWindow).google?.maps &&
                        (window as GoogleWindow).google?.maps?.places
                    ) {

                        setIsLoaded(true);
                    }

                    return;
                }

                const script = document.createElement('script');
                script.src = `${anyGoogleApi}&loading=async`;
                script.async = true;
                script.defer = true;
                script.id = 'google-maps-script';

                script.onload = () => {
                    waitForScriptLoad();
                };

                script.onerror = (error) => console.error('Error loading Google Maps API:', error);
                document.body.appendChild(script);

            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }

            const waitForScriptLoad = () => {
                const intervalId = setInterval(() => {
                    if (
                        (window as GoogleWindow).google &&
                        (window as GoogleWindow).google?.maps &&
                        (window as GoogleWindow).google?.maps?.places
                    ) {
                        setIsLoaded(true);
                        clearInterval(intervalId);
                    } else {
                        console.log('Waiting for Google Maps API to be ready...');
                    }
                }, 100);

                return () => clearInterval(intervalId);
            };
        };

        loadGoogleMapsAPI();
    }, [anyGoogleApi]);

    return isLoaded;
};
