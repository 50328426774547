import React from "react";
import { PaginationProps } from "../../../core/models/interface";
import "./paginationStyle.css"
import en from "../../../en/en";
import useMaxVisiblePages from "../../../hooks/useMaxVisiblePages";
import { usePagination } from "../../../context/paginationContext";

const PaginationComponent: React.FC<PaginationProps> = ({
    totalPages,
    currentPage,
    handlePrevious,
    handleNext,
    handleEnd,
    handleBeginning
}) => {

    const breakpoints = [
        { width: 385, pages: 1 },
        { width: 446, pages: 2 },
        { width: 593, pages: 3 },
    ];

    const maxVisiblePages = useMaxVisiblePages(breakpoints);
    const { setCount } = usePagination();

    let startPage, endPage;

    if (totalPages <= maxVisiblePages) {

        startPage = 1;
        endPage = totalPages;
    } else {

        if (currentPage <= Math.ceil(maxVisiblePages / 2)) {

            startPage = 1;
            endPage = maxVisiblePages;
        } else if (currentPage + Math.floor(maxVisiblePages / 2) >= totalPages) {

            startPage = totalPages - maxVisiblePages + 1;
            endPage = totalPages;
        } else {

            startPage = currentPage - Math.floor(maxVisiblePages / 2);
            endPage = currentPage + Math.floor(maxVisiblePages / 2);
        }
    };

    const handlePageClick = (count: number) => {

        setCount(count);
    };

    return (
        <div className="mt-3 custom-pagination d-flex">
            <ul className="pagination mt-0 d-flex justify-content-center align-items-center">
                <li className="page-item">
                    <button onClick={handleBeginning}>{en.FAST_BACKWARD}</button>
                </li>
                <li className="page-item">
                    <button onClick={handlePrevious}>{en.PREVIOUS}</button>
                </li>
                {[...Array(endPage - startPage + 1)].map((_, index) => (
                    <li
                        key={index + startPage}
                        className={`page-item ${index + startPage === currentPage ? 'active' : ''}`}
                    >
                        <button className="page-link mx-2" onClick={() => handlePageClick(index + startPage)}>{index + startPage}</button>
                    </li>
                ))}
                <li className="page-item">
                    <button onClick={handleNext}>{en.NEXT}</button>
                </li>
                <li className="page-item">
                    <button onClick={handleEnd}>{en.FAST_FORWARD}</button>
                </li>
            </ul>
        </div>
    );
};

export default React.memo(PaginationComponent);