import { format } from "date-fns";
import { convertDateStringToISOString, convertDateToISO, getConvertedBusinessDateTime } from "./Date";

export function checkBookingDayCondition(dayTimeString: string, timezone: string) {

    const [datePart] = dayTimeString.split(' | ');
    const date = datePart.trim();

    const currentDate = format(getConvertedBusinessDateTime(timezone),'yyyy-MM-dd');
    const convertedDate = convertDateToISO(date)
    const givenDate = convertDateStringToISOString(convertedDate);

    if (givenDate === currentDate) {

        return true;
    } else {

        return false;
    }
};