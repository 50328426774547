import React, { useState } from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { ImageWithBasePath } from '../../../core/img/ImageWithBasePath';

const BannerSection = () => {

    return (
        <section className="app-section">
            <div className="container">
                <div className="app-sec app-sec-banner-custom" style={{ backgroundColor: 'rgb(242, 242, 255)', padding: '0' }}>
                    <div className="row rowheight-custom">
                        <div className="col-lg-6 col-12 home-bannerimg-custom">

                            <div className="appimg-set aos bannerimg-custom" data-aos="fade-up">
                                <ImageWithBasePath
                                    src="assets/img/open-work-1.png"
                                    className="img-fluid banner-img-custom"
                                    alt="img"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 padding-banner-sec-custom">
                            <div className="col-md-12">
                                <div className="heading aos" data-aos="fade-up">
                                    <h2 className='home-banner-heading-custom home-heading-custom'>Journey that began with a simple step</h2>
                                    <p className='home-banner-title-custom home-para-custom'>Lets take you through the journey where we began - “Take A No” - Your Partner for Booking Local Services! Download now and enjoy a seamless experience effortlessly.
                                    </p></div>
                            </div>
                            <div className="downlaod-btn aos margin-t-30px banner-btn-margin-custom" data-aos="fade-up">
                                <div className="offering-button toknowmore-btn-custom">
                                    <a href="https://safalogics.com" className="btn btn-primary moreaboutus-btn-custom" target="_blank" rel="noopener noreferrer">
                                        To Know More About Us
                                        <i className="fas fa-arrow-right" style={{ marginLeft: '10px', fontSize: '16px' }}></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerSection;