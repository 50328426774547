import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import './style.css'
import { TKN_DropdownInput, TKN_TextareaPlaceholder } from '../../../components/inputfields/input';
import apiService from '../../../../api/authentication-service';
import { validateDropdown, validateEmptyValue } from '../../../../helper/validate';
import { useSelector } from 'react-redux';
import en from '../../../../en/en';
import { VENDOR_APP } from '../../../../constants/constants';
import { TKN_Success_message, TKN_Danger_message } from '../../../components/alert_messages/alert_messages';
import { Spinner } from 'react-bootstrap';

const ProviderReportAnIssue = () => {
  const routes = all_routes;
  const [selected, setSelected] = useState([]);
  const [category, setCategory] = useState('');
  const [categoryDropdownError, setCategoryDropdownError] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const maxLength = 200;

  const sendReportIssueCategoryRequest = () => {
    apiService.getReportedIssueCategories(getReportIssueCategoriesRequest()).then((response: { data: { data: any; }; }) => {
      const responseData = response?.data?.data;
      if (responseData) {
        const reportIssueCategories = responseData;
        if (reportIssueCategories) {
          setSelected(reportIssueCategories);
        }
      }
    }).catch((error: any) => {
      console.log('Error', error)
    })
  };

  const getReportIssueCategoriesRequest = () => ({
    app_type: VENDOR_APP
  });

  useEffect(() => {
    sendReportIssueCategoryRequest();
  }, []);

  const dropdownDataArray = Object.entries(selected).map(([value, label]) => ({
    value,
    label,
  }));

  const dropdownData = dropdownDataArray.map(item => item.label);
  const data = [...dropdownData];

  const sendReportAnIssueRequest = () => {
    apiService.AddReportAnIssue(addReportIssueRequest()).then((response) => {
      const status = response?.data?.status
      setIsLoading(false);

      if (status && status === true) {
        setResponseMessage(response?.data?.message);
      } else {
        setServerError(response?.data?.message)
      }
    }).catch((error) => {
      console.log('Error', error)
    })
  };

  const userId = useSelector((state: any) => {
    return state.user.user_id
  });

  const addReportIssueRequest = () => ({
    user_id: userId,
    category: category,
    description: description
  });

  const categoryNumber = (selectedItem: any) => {
    const selectedLabel = dropdownDataArray.find((item) => item.label === selectedItem);

    if (selectedLabel) {
      setCategory(selectedLabel?.value);
    }
  };

  const handleSubmitReportIssue = () => {
    let isValid = true;
    const fieldsToValidate = [
      { value: category, errorSetter: setCategoryDropdownError, validationFunction: [validateDropdown], fieldName: 'category' },
      { value: description, errorSetter: setDescriptionError, validationFunction: [validateEmptyValue], fieldName: 'Description' },
    ];

    fieldsToValidate.forEach((field => {
      for (const validationFunction of field.validationFunction) {
        const validationResult = validationFunction(field.value, field.fieldName);

        if (validationResult.status === 'false') {
          field.errorSetter(validationResult.error);
          isValid = false;
          break;
        }
      }
    }));

    if (isValid) {
      setIsLoading(true);
      sendReportAnIssueRequest();
    };
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    if (value.length >= maxLength) {
      setDescriptionError(en.CHAR_LIMIT);
    } else {
      setDescriptionError('');
    }
    setDescription(value);
  };
  const handleServerErrorClose = () => {
    setServerError('');
  }

  const handleSuccessmsgClose = () => {
    setResponseMessage('');
  }

  return (
    <div className="provider-body">
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid" style={{ width: '97.5%' }}>
            <div className="col-lg-6" style={{ width: '97.5%' }}>
              <div className="widget-title">
                {responseMessage && (<TKN_Success_message text={responseMessage} onClick={handleSuccessmsgClose} />)}
                {serverError && (<TKN_Danger_message text={serverError} onClick={handleServerErrorClose} />)}
                <h4>Report An Issue</h4>
                <div className="new-contact-us-style1">
                  <div className="new-contact-us-style2">
                    <div className="col-md-6 new-col-w-100">
                      <TKN_DropdownInput
                        placeholderText={'Select a Category'}
                        options={data}
                        required={true}
                        onSelect={(selectedItem: string) => {
                          setCategoryDropdownError('')
                          categoryNumber(selectedItem)
                        }} categoryDropdownError={categoryDropdownError}
                      >
                      </TKN_DropdownInput>
                    </div>
                    <div className="col-md-6 new-col-w-100">
                      <TKN_TextareaPlaceholder
                        placeholderText={'Describe the issue '}
                        value={description}
                        descriptionInputError={descriptionError}
                        required={true}
                        maxLength={maxLength}
                        onChange={handleDescriptionChange}
                      />
                      <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <button
                          className="btn btn-light-danger"
                          style={{ marginLeft: '20px', backgroundColor: '#0445CF', color: 'white' }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubmitReportIssue();
                          }}
                          disabled={isLoading}
                        >
                          {isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : "Submit"}
                        </button>
                      </div>
                      <div className="new-contact-us-style4">
                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderReportAnIssue;