import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useAuth from '../common/useAuth';
import CustomerFavourite from './customer-favourite/customer-favourite';
import CustomerProfile from './customer-profile/customer-profile';
import CustomerDashboard from './customer-dashboard/customer-dashboard';
import CustomerReportAnIssue from './customer-report-an-issue/customer-report-an-issue';
import CustomerResetPassword from './customer-reset-password/customer-reset-password';
import AddFamilyMember from './customer-add-family/customer-add-family';
import CustomerBooking from './customer-booking-new/customer-booking-new';
import { FavouritePaginationProvider, PaginationProvider } from '../../../context/paginationContext';
import CustomerComingSoon from './customer-coming-soon/customer-coming-soon';
import CustomerEditProfile from './customer-edit-profile/customer-edit-profile';
import CustomerAddMemberlisting from './customer-addmemberlisting/customer-addmemberelisting';
import EditFamilyMember from './customer-edit-family/customer-edit-family';
import CustomerDeleteAccount from './customer-delete-account/customer-delete-account';
import ServiceDetails1 from '../services/service-details/service-details1';
import CustomerBookingCalendar from './customer-booking-calendar/customer-booking-calendar';

const CustomersRoutes = () => {

  useAuth();

  const all_customers_routes = [
    {
      path: '/booking',
      name: 'customer-booking',
      element: (
        <PaginationProvider>
          <CustomerBooking />
        </PaginationProvider>
      ),
      route: Route,
    },
    {
      path: '/customer-dashboard',
      name: 'customer-dashboard',
      element: <CustomerDashboard />,
      route: Route,
    },
    {
      path: '/favourite',
      name: 'customer-favourite',
      element:
        <FavouritePaginationProvider>
          <CustomerFavourite />
        </FavouritePaginationProvider>,
      route: Route,
    },
    {
      path: '/profile',
      name: 'customer-profile',
      element: <CustomerProfile />,
      route: Route,
    },
    {
      path: '/report-an-issue',
      name: 'customer-report-an-issue',
      element: <CustomerReportAnIssue />,
      route: Route,
    },
    {
      path: '/reset-password',
      name: 'customer-reset-password',
      element: <CustomerResetPassword />,
      route: Route,
    },
    {
      path: '/add-family-members',
      name: 'add-family-members',
      element: <AddFamilyMember />,
      route: Route,
    },
    {
      path: '/edit-family-members',
      name: 'edit-family-members',
      element: <EditFamilyMember />,
      route: Route,
    },
    {
      path: '/coming-soon',
      name: 'customer-coming-soon',
      element: <CustomerComingSoon />,
      route: Route,
    },
    {
      path: '*',
      name: 'NotFound',
      element: <Navigate to="/" />,
      route: Route,
    },
    {
      path: '/edit-profile',
      name: 'customer-edit-profile',
      element: <CustomerEditProfile />,
      route: Route,
    },
    {
      path: '/delete-account',
      name: 'customer-delete-account',
      element: <CustomerDeleteAccount />,
      route: Route,
    },
    {
      path: '/family-members-list',
      name: 'customer-add-member',
      element: <CustomerAddMemberlisting />,
      route: Route,
    },
    {
      path: '/business-details',
      name: 'business-details',
      element: <ServiceDetails1 />,
      route: Route
    },
    {
      path: '/booking-calendar',
      name: 'customer-booking-calendar',
      element: <CustomerBookingCalendar />,
      route: Route,
    },

    // {
    //   path: '/chat',
    //   name: 'customer-chat',
    //   element: <CustomerChat />,
    //   route: Route,
    // },
    // {
    //   path: '/notifications',
    //   name: 'customer-notifications',
    //   element: <CustomerNotifications />,
    //   route: Route,
    // },
    // {
    //   path: '/reviews',
    //   name: 'customer-reviews',
    //   element: <CustomerReviews />,
    //   route: Route,
    // },
    // {
    //   path: '/wallet',
    //   name: 'customer-reviews',
    //   element: <CustomerWallet />,
    //   route: Route,
    // },
    // {
    //   path: '/settings/device-management',
    //   name: 'device-management',
    //   element: <DeviceManagement />,
    //   route: Route,
    // },
    // {
    //   path: '/settings/login-activity',
    //   name: 'login-activity',
    //   element: <LoginActivity />,
    //   route: Route,
    // },
    // {
    //   path: '/invoice',
    //   name: 'invoice',
    //   element: <Invoice />,
    //   route: Route,
    // },
    // {
    //   path: '/notification',
    //   name: 'notification',
    //   element: <Notification />,
    //   route: Route,
    // },
    // {
    //   path: '/setting/security-settings',
    //   name: 'SecuritySetting',
    //   element: <SecuritySetting />,
    //   route: Route,
    // },
  ];

  return (
    <>
      <Routes>
        <Route>
          {all_customers_routes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default CustomersRoutes;
