import React from 'react';
import CustomerDashboardWidgetSection from '../../../components/customerDashBoard/customerDashboardWidgetSection/customerDashboardWidgetSection';
import CustomerDashboardRecentSection from '../../../components/customerDashBoard/CustomerDashboardRecentSection/CustomerDashboardRecentSection';
import DashBoardRecentSubHeading from '../../../components/customerDashBoard/dashBoardRecentSubHeading/dashBoardRecentSubHeading';
import useAuth from '../../common/useAuth';

const CustomerDashboard = () => {
 
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div>
          <div className="widget-title">
            <DashBoardRecentSubHeading SubHeading={'Dashboard'} tagName={'h4'} />
          </div>
          <CustomerDashboardWidgetSection />
          <CustomerDashboardRecentSection />
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;
