import React from "react";
import { Link } from "react-router-dom";
import * as Icon from 'react-feather';
import { all_routes } from "../../../core/data/routes/all_routes";
import { Location_with_NameProps, } from "../../../core/models/interface";


const Locationwithname: React.FC<Location_with_NameProps> = (Props) => {
    const IconComponent = Icon[Props.icon];
    return (

        <p>
            <span className="rate" >
                {IconComponent && <IconComponent className={Props.iconClass} />}
                &nbsp;
                {Props.city}, {Props.State}
            </span></p>
    )
}
export default Locationwithname