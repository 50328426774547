import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { ImageWithBasePath, ImageWithBasePathWithUrl } from "../../../core/img/ImageWithBasePath";
import { all_routes } from "../../../core/data/routes/all_routes";
import * as Icon from "react-feather";
import DashBoardRecentSubHeading from "../customerDashBoard/dashBoardRecentSubHeading/dashBoardRecentSubHeading";
import colors from "../../../colors/colors";
import { ButtonTextOnly_Colored } from "../buttons/button";
import { object } from "prop-types";
import { format } from "date-fns"
import en from "../../../en/en";
import useSelection from "antd/es/table/hooks/useSelection";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/data/redux/store";
import apiService from "../../../api/authentication-service";
import './provider_services.css'
import { TKN_modal, TKN_successmodal } from "../modal/modal";


interface Vendor_myservicescardProps {
    ServiceName: string;
    Imgsrc: string;
    ServiceStatus: number;
    hoursOpen: string;
    ImgclassName?: string;
    route?: string;
    style?: string;
    ServiceDescription?: string;
    ServiceEndDate?: string;
    ServiceStartDate?: string;
    ServiceId?: any;
    toggleButton?: (serviceId: number, newStatus: number) => void;
}
interface IconwithTextProps {
    route: string;
    text: string;
    onClick?: () => void;
    Icon: keyof typeof Icon;
    IconclassName?: string;
    style?: React.CSSProperties;
    Iconstyle?: React.CSSProperties;
    //onClick?: () => void;
}
interface Active_InactiveProps {
    text: string;
    className?: string;
    style?: React.CSSProperties;
}

interface Week_NameProps {
    text?: string;
    route?: string;
    hoursOpen: {
        [day: string]: {
            day: string;
            open_from_time: string;
            open_to_time: string;
        };
    };
}

interface Service {
    serviceStatus: number;
    serviceEndDate: string;
}
interface ServiceToggles {
    [serviceID: number]: boolean;
}
interface FormErrors {
    general?: string;
};

const Vendor_myservicescard: React.FC<Vendor_myservicescardProps> = (Props) => {


    const routes = all_routes;
    const vendorId = useSelector((state: RootState) => state.vendor.id);


    const [isChecked, setIsChecked] = useState(Props.ServiceStatus === 1);
    const [services, setServices] = useState<Service[]>([]);
    const [serviceToggles, setServiceToggles] = useState<ServiceToggles>({});
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [data, setData] = useState([]);
    const [activeServicesCount, setActiveServicesCount] = useState(0);
    const [isClicked, setIsClicked] = useState(serviceToggles[Props.ServiceId]);
    const [serviceStatus, setServiceStatus] = useState(Props.ServiceStatus);
    const [deleteservice, setDeleteservice] = useState(Props.ServiceId);
    const [errors, setErrors] = useState<FormErrors>({});
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [showModal1, setShowModal1] = useState(false);
    const [status, setStatus] = useState(false);

    const navigate = useNavigate();
    const handleEdit = (serviceId: any) => {
        navigate(routes.vendorEditService, { state: { serviceId } });
    };

    let hoursOpen: any;
    try {
        hoursOpen = typeof Props.hoursOpen === 'string' ? JSON.parse(Props.hoursOpen) : Props.hoursOpen;
    } catch (error) {
        console.error('Failed to parse hours_open JSON :', error)
        hoursOpen = {}
    }

    const handleToggle = () => {
        setIsChecked(!isChecked);
    };








    const Toggle_btn: React.FC<{ isChecked: boolean, handleToggle: () => void }> = ({ isChecked, handleToggle }) => {
        return (
            <React.Fragment>
                <div className="toggle-button-color-inactive" onClick={handleToggle} style={{ backgroundColor: isChecked ? '#0445CF' : 'red' }}>
                    <Link to="#">
                        <div className="toggle-button-round" style={{ marginLeft: isChecked ? '24px' : '2px' }}></div>
                    </Link>
                </div>
            </React.Fragment>
        )
    }

    const rearrangeWeekDays = (weeksDays: string[]) => {
        const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return weekdays.filter(day => weeksDays.includes(day));
    }

    const Week_Name: React.FC<Week_NameProps> = ({ hoursOpen }) => {
        if (!hoursOpen || Object.keys(hoursOpen).length === 0) {
            return <p>No Bookings Available.</p>
        }

        const getAvailableDays = (openDays: { [key: string]: any }) => {
            const days = Object.keys(openDays).map(day => openDays[day].day);
            const rearrangedDays = rearrangeWeekDays(days);
            return (
                rearrangedDays.map((day, index) => (
                    <div key={index} className="week-slot-new">
                        {day.slice(0, 3)}
                    </div>
                ))
            );
        }

        return (
            <React.Fragment>
                {getAvailableDays(hoursOpen)}
            </React.Fragment>
        )
    }

    const IconwithText: React.FC<IconwithTextProps> = (Props) => {
        const IconComponent = Icon[Props.Icon];
        return (
            <React.Fragment>
                <div onClick={Props.onClick} className="serv-edit" style={Props.style}>
                    {IconComponent && <IconComponent className={`react-feather-icon ${Props?.IconclassName}`} style={Props.Iconstyle} />}
                    <p style={{ color: '#0445CF', margin: '0px' }}>{Props.text}</p>
                </div>
            </React.Fragment>
        );
    };


    const getServiceStatusText = (serviceStaus: number, serviceEndDate: any) => {

        const todayDate = format(new Date(), 'yyyy-MM-dd');
        console.log(serviceStaus, serviceEndDate)
        let serviceStatusString = "";
        if (new Date(todayDate) > new Date(serviceEndDate) && serviceEndDate !== null) {

            serviceStatusString = en.STATUS_ENDED;
        } else {

            if (serviceStaus === 1) {

                serviceStatusString = en.STATUS_ACTIVE;
            } else {

                serviceStatusString = en.STATUS_INACTIVE;
            }
        }
        return (
            <React.Fragment>
                <Link to="#" className={`btn-active-new d-flex flex-align-center`} style={{
                    backgroundColor:
                        serviceStatusString === en.STATUS_ACTIVE ? '#0445CF' :
                            serviceStatusString === en.STATUS_INACTIVE ? 'red' :
                                serviceStatusString === en.STATUS_ENDED ? 'red' :
                                    'inherit',
                    borderColor:
                        serviceStatusString === en.STATUS_ACTIVE ? '#0445CF' :
                            serviceStatusString === en.STATUS_INACTIVE ? 'red' :
                                serviceStatusString === en.STATUS_ENDED ? 'red' :
                                    'inherit',
                }} >
                    {serviceStatusString}
                </Link>
            </React.Fragment >
        );
    };

    const toggleButton = (serviceID: number, status: number) => {
        setServiceToggles(prevState => ({
            ...prevState,
            [serviceID]: !prevState[serviceID]
        }));
        if (status === 1) {
            serviceStatusUpdationRequest(serviceID, 1);
        } else {
            serviceStatusUpdationRequest(serviceID, 0);
        }
    };

    const serviceStatusUpdationRequest = (serviceID: number, status: any) => {
        apiService.serviceStatusUpdation(serviceStatusPayLoad(serviceID, status)).then((response) => {
            const status = response?.data?.status;
            if (status === true) {
                sendServicesListRequest();
                setLoadingIndicator(true);
            }
        }).catch((error) => {
            console.log('Error', error.message,)

        });
    };

    const serviceStatusPayLoad = (serviceId: number, status: number) => ({
        service_id: serviceId,
        status: status
    });

    const sendServicesListRequest = () => {
        apiService.getServicesListByVendorId(servicesListRequest()).then((response) => {
            const responseData = response?.data;
            const status = responseData?.status;
            if (status === true) {

                const serviceList = responseData?.data;
                if (serviceList) {

                    setData(responseData?.data);
                    const defaultServiceToggles: ServiceToggles = {};
                    responseData?.data.forEach((item: any) => {
                        if (item.service_status === 1) {
                            defaultServiceToggles[item.service_id] = true;
                        } else {
                            defaultServiceToggles[item.service_id] = false;
                        }
                    });
                    setServiceToggles(defaultServiceToggles);
                    setLoadingIndicator(false);
                    let serviceCount = 0;
                    serviceList.map((service: any, index: any) => {

                        if (service?.service_status === 1) {

                            serviceCount++;
                        }
                    });
                    setActiveServicesCount(serviceCount);
                }
            }
        }).catch((error) => {
            console.log('error', error);
        })
    };

    const servicesListRequest = () => ({
        vendor_id: vendorId
    });


    const handleButtonClick = () => {
        const newStatus = serviceStatus === 0 ? 1 : 0;
        console.log('Current serviceStatus:', serviceStatus);
        console.log('New serviceStatus:', newStatus);

        setServiceStatus(newStatus);
        toggleButton(Props.ServiceId, newStatus);
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };
    //--------------------------------------------------------//

    const sendDeleteServicesRequest = (serviceid: any) => {
        const payload = deleteserviceRequest(serviceid);
        apiService.deleteService(payload)
            .then((response: any) => {
                console.log('DELETE API Response:', response);
                getdeleteserviceResponse(response.data);

            })
            .catch((error: any) => {
                console.error('DELETE ERROR ', error);
                setModalTitle('Service');
                setModalMessage('Service not deleted.');
                setShowModal(true);
            });
    };

    const deleteserviceRequest = (serviceid: any) => ({
        service_id: serviceid
    });
    const getdeleteserviceResponse = (response: any) => {
        if (response) {
            const status = response?.status;
            if (status === true) {
                const successMessage = 'Service deleted successfully.';
                setStatus(true);
                setShowModal1(true);
                setModalTitle('Success');
                setModalMessage(successMessage);
                setShowModal(false);
            } else {
                const errorMessage = 'Service not deleted.';
                console.error('ERROR MSG:', response?.message);
                setStatus(false);
                setShowModal1(true);
                setModalTitle('Delete Service');
                setModalMessage(en.EDIT_NOT_PERMITTED);
                console.log('ERROR MSG:', (response?.message));
                // setShowModal(true);
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDeleteButton = () => {
        setShowModal(true)
    }
    const handleSuccessButton = () => {
        sendDeleteServicesRequest(Props.ServiceId);

        setShowModal(false)
    }

    const handleButtonDelete = () => {
        sendDeleteServicesRequest(Props.ServiceId);
    };

    const handleSuccessButtonCancel = () => {
        setShowModal1(false)
    }
    const handleSuccessButtonSave = () => {
        setShowModal1(false)
    }

    const handleStatusTrue = () => {
        setShowModal1(false)
        setTimeout(() => {
            window.location.reload()
        }, 500)
    }
    const handleStatusFalse = () => {
        setShowModal1(false)
    }

    const handleSuccessButtonOk = () => {
        setShowModal1(false)
        setTimeout(() => {
            window.location.reload();
        }, 500)
    }
    //--------------------------------------------------------//



    return (
        <>
            <div className="col-xl-4 col-md-6">
                <div className="service-widget pro-service">
                    <div className="service-img">
                        <div>
                            <ImageWithBasePathWithUrl className={` serv-img new-service-height ${Props.ImgclassName}`} alt="Service Image" src={Props.Imgsrc} />
                        </div>
                    </div>
                    <div className="service-content d-flex service-colown-grow">
                        <div className="w-100 new-flex-style">
                            <h3 className="title w-78">
                                <h5 className="para-elipses">{Props.ServiceName}</h5>
                            </h3>
                            {/* <Active_Inactive
                                text={isChecked ? "Active" : "Inactive"}
                                style={{ backgroundColor: isChecked ? '#0445cf' : 'red', borderColor: isChecked ? '#0445cf' : 'red' }}
                            /> */}
                            {getServiceStatusText(Props.ServiceStatus, Props.ServiceEndDate)}
                        </div>
                        <div className="addrs-item">
                            <p>Business Days</p>
                        </div>
                        <div className="f-grow">
                            <div className="d-flex w-100 new-flex-style-wrap">

                                <Week_Name hoursOpen={hoursOpen} />


                            </div>
                        </div>
                        <div className="serv-info">
                            <div>
                                <IconwithText text={"Edit"} Icon={"Edit"} style={{ color: "#0445CF", cursor: "pointer" }} Iconstyle={{ color: '#0445CF' }} route={''} onClick={() => handleEdit(Props.ServiceId)} />
                                <IconwithText text={"Delete"} Icon={"Trash"} style={{ color: "#0445CF", cursor: "pointer" }} Iconstyle={{ color: '#0445CF' }} route={"#"} onClick={handleDeleteButton} />
                            </div>
                            {/* <Toggle_btn isChecked={isChecked} handleToggle={handleToggle} /> */}
                            <div style={{}}>
                                <button className="button-style-new-active-inactive" onClick={handleButtonClick} style={{ backgroundColor: serviceStatus === 1 ? '#0445cf' : 'red' }}>
                                    <div className="button-style-new-round" style={{ marginLeft: serviceStatus === 1 ? '28px' : '0px' }} />
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Delete Service */}
                <TKN_modal title={'Delete Service'} description={'Are you sure to delete the service?'} okbutton={'OK'} handleshowModal={showModal} handleSuccessButton={handleSuccessButton} handleCancelButton={handleCloseModal} closeButton={false} cancelbutton={'Cancel'} />
                {status === true ? (
                    <Modal show={showModal1} backdrop="static" keyboard={false} contentClassName='modal-success-custom' dialogClassName='dialog-info-custom'>
                        <div className="w-100">
                            <div className="icon-modal-custom">
                                <i className="fa-solid fa-check check-icon-custom"></i>
                            </div>
                        </div>
                        <Modal.Header className='border-none'>
                            <Modal.Title className='color-title'>{modalTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='para-modal-custom'>
                            <p>{modalMessage}</p>
                        </Modal.Body>
                        <Modal.Footer className='border-none modal-footer2'>
                            <div>
                                <ButtonTextOnly_Colored label={'OK'} fullWidth={false} onClick={handleSuccessButtonOk} className='ok-btnmodal-custom check-okcancel-btn-custom' />
                            </div>
                        </Modal.Footer>
                    </Modal >
                ) : (
                    <Modal show={showModal1} backdrop="static" keyboard={false} contentClassName='modal-failed-custom' dialogClassName='dialog-info-custom'>
                        <div className="w-100">
                            <div className="icon-modal-failed-custom">
                                <i className="fa-solid fa-xmark xmark-icon-failed-custom"></i>
                            </div>
                        </div>
                        <Modal.Header className='border-none'>
                            <Modal.Title className='color-title'>{modalTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='para-modal-custom'>
                            <p>{modalMessage}</p>
                        </Modal.Body>
                        <Modal.Footer className='border-none modal-footer2'>
                            <div>
                                <ButtonTextOnly_Colored label={'OK'} fullWidth={false} onClick={handleSuccessButtonSave} className='ok-btnmodal-failed-custom check-okcancel-btn-custom' />
                            </div>
                        </Modal.Footer>
                    </Modal >
                )}

                {/* /Delete Service */}

            </div>
        </>

    )
}


export { Vendor_myservicescard, };