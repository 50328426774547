import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../../../../constants/constants';

const GeolocationMap: React.FC = () => {
    const [center, setCenter] = useState({ lat: -3.745, lng: -38.523 });
    const [error, setError] = useState<string | null>(null);
    const searchBoxRef = useRef<any>(null);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [marker, setMarker] = useState<google.maps.Marker | null>(null);

    useEffect(() => {
        // Geolocation API to get the user's location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setCenter(userLocation);
                },
                (error) => {
                    setError('Failed to get your location. Showing default location.');
                    console.error("Geolocation error: ", error);
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    }, []);

    const onPlacesChanged = () => {
        const places = searchBoxRef.current.getPlaces();
        if (places && places.length > 0) {
            const place = places[0];
            const location = place.geometry?.location;
            if (location) {
                setCenter({ lat: location.lat(), lng: location.lng() });
                map?.panTo({ lat: location.lat(), lng: location.lng() });

                // Place marker at the searched location
                if (marker) {
                    marker.setPosition({ lat: location.lat(), lng: location.lng() });
                } else {
                    const newMarker = new google.maps.Marker({
                        map: map,
                        position: { lat: location.lat(), lng: location.lng() },
                    });
                    setMarker(newMarker);
                }
            }
        }
    };

    const onMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            if (!marker) {
                const newMarker = new google.maps.Marker({
                    map: map,
                    position: event.latLng,
                });
                setMarker(newMarker);
            } else {
                marker.setPosition(event.latLng);
            }
        }
    };

    const onMapLoad = (mapInstance: google.maps.Map) => {
        setMap(mapInstance);
        mapInstance.addListener("click", onMapClick); // Add click listener to place marker
    };

    return (
        <div className="main-wrapper">
            <div className="content" style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                <div className="container">
                    <div className="row" style={{ height: '700px' }}>
                        <div className="map-container">
                            {error && <div className="error-message">{error}</div>}
                            <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
                                <div>
                                    {/* SearchBox input */}
                                    <input
                                        type="text"
                                        placeholder="Search for an area"
                                        style={{
                                            boxSizing: 'border-box',
                                            border: '1px solid transparent',
                                            width: '240px',
                                            height: '40px',
                                            marginTop: '10px',
                                            padding: '0 12px',
                                            borderRadius: '3px',
                                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                            fontSize: '16px',
                                            outline: 'none',
                                            textOverflow: 'ellipses',
                                            position: 'absolute',
                                            top: '10px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            zIndex: 10,
                                        }}
                                        ref={searchBoxRef}
                                    />
                                    <GoogleMap
                                        mapContainerStyle={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        center={center}
                                        zoom={10}
                                        onLoad={onMapLoad} // Map loading handler
                                    >
                                        {/* <SearchBox
                                            ref={searchBoxRef}
                                            onPlacesChanged={onPlacesChanged} // Handle place search changes
                                        /> */}
                                    </GoogleMap>
                                </div>
                            </LoadScript>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeolocationMap;
