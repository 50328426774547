import React from 'react'
import { Link } from 'react-router-dom'
import * as Icon from 'react-feather';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const Maintenance = () => {
  const routes = all_routes;
  return (
    <>
     
  <div className="bg-img">
    <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
    <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
  </div>
  <div className="content">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="error-wrap text-center">
            <div className="error-logo mb-0">
              <Link to={routes.homeOne}>
                <ImageWithBasePath
                  className="img-fluid"
                  src="assets/img/logo.svg"
                  alt="img"
                />
             </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="maintenance-sec pb-0">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="maintenance-wrap">
              <h2>We Are Down For Maintenance</h2>
              <p>
                Our website is currently undergoing scheduled maintenance, will
                be right back in a few minutes.
              </p>
              <div className="maintenance-icon">
                <h6>We Will Be Back Shortly</h6>
                <div className="social-icon media-icon">
                  <ul>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Instagram className="react-feather-custom"/>{" "}
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Twitter className="react-feather-custom"/>
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                      <Icon.Youtube className="react-feather-custom"/>
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Linkedin className="react-feather-custom"/>
                     </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={routes.homeOne}className="btn btn-primary">
               <Icon.ArrowLeftCircle className="react-feather-custom" />
                Back to Home
             </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <ImageWithBasePath
              src="assets/img/maintenance.png"
              className="img-fluid"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    
  )
}

export default Maintenance
