import React from 'react'
import './loader.css'

interface LoaderProps {
    showloader: boolean;
    style?: React.CSSProperties;
    spinnerstyle?: React.CSSProperties;
    className?: string;
}
const Loader: React.FC<LoaderProps> = (Props) => {
    return (
        (Props.showloader && (<div className={`loader-container ${Props.className}`} style={Props.style} >
            <div className="spinner" style={Props.spinnerstyle} ></div>
        </div >))

    );
};

export default Loader;
