import React from 'react';
import { Link } from 'react-router-dom';
import './button.css';
import * as Icon from 'react-feather';
import {
  ButtonTextandIcon_ColoredProps,
  ButtonIcononly_ColoredProps,
  ButtonTextOnly_ColoredProps,
  ButtonNumberOnly_ColoredProps,
  IconBtn,
  TextBtn,
} from '../../../core/models/interface';
interface Props {
  route: string;
  buttonText: string;
  hover: boolean;
}
const GreyButtonwithOutline: React.FC<Props> = (Props) => {
  return (
    <>
      <Link
        to={Props.route}
        className={`${Props.hover ? 'book-btn' : 'secondary-btn'}`}
      >
        {Props.buttonText}
      </Link>
    </>
  );
};

const GreyButtonwithIcon: React.FC<IconBtn> = ({
  buttonText,
  icon,
  iconClass,
  id,
  onClick,
}) => {
  const IconComponent = Icon[icon];
  return (
    <>
      <Link to="#" className="view-more" id={id} onClick={onClick}>
        {buttonText}
        {IconComponent && <IconComponent className={iconClass} />}
      </Link>
    </>
  );
};

const GreyButtonwithText: React.FC<TextBtn> = ({ buttonText }) => {
  return (
    <>
      <Link to="#" className="btn-text">
        {buttonText}
      </Link>
    </>
  );
};

const ButtonTextOnly_Colored: React.FC<ButtonTextOnly_ColoredProps> = (
    Props,
  ) => {
  return (
      <button
      className={`btn-new btn-primary-new btn-view-new ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`}
      style={Props?.style}
      onClick={Props.onClick}
      disabled={Props?.disabled}
      >
        {Props.label}
      </button>
  );
};

const ButtonTextandIcon_Colored: React.FC<ButtonTextandIcon_ColoredProps> = (
  Props,
) => {
  const IconComponent = Icon[Props.icon];
  return (
    <Link
      to={Props.route}
      className={`btn-new btn-primary-new btn-view-new ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`}
      style={Props?.style}
      onClick={Props.onClick}
    >
      {Props.label}
      {IconComponent && (
        <IconComponent
          className={`standard-feather-new ms-2-new ${Props?.iconclassName}`}
        />
      )}
    </Link>
  );
};

const ButtonTextandIconReverse_Colored: React.FC<
  ButtonTextandIcon_ColoredProps
> = (Props) => {
  const IconComponent = Icon[Props.icon];
  return (
    <Link
      to={Props.route}
      className={`btn-new btn-primary-new btn-view-new flex-reverse ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`}
      style={Props?.style}
      onClick={Props.onClick}
    >
      {Props.label}
      {IconComponent && (
        <IconComponent
          className={`react-feather-custom-new me-1-new mb-1-new ${Props?.iconclassName}`}
          style={Props?.iconstyle}
        />
      )}
    </Link>
  );
};

const ButtonIconOnly_Colored: React.FC<ButtonIcononly_ColoredProps> = (
  Props,
) => {
  const IconComponent = Icon[Props.icon];
  return (
    <Link
      to={Props.route}
      className={`icon-style ${Props?.className}`}
      style={Props?.style}
      onClick={Props.onClick}
    >
      {IconComponent && (
        <IconComponent
          className={`react-feather-custom-new ${Props?.iconclassName}`}
          style={Props?.iconstyle}
        />
      )}
    </Link>
  );
};

const ButtonNumberOnly_Colored: React.FC<ButtonNumberOnly_ColoredProps> = (
  Props,
) => {
  return (
    <Link
      to={Props.route}
      className={`page-item-new ${Props?.className}`}
      style={Props.style}
      onClick={Props.onClick}
    >
      {Props.label}
    </Link>
  );
};

export {
  GreyButtonwithOutline,
  GreyButtonwithIcon,
  GreyButtonwithText,
  ButtonTextOnly_Colored,
  ButtonTextandIcon_Colored,
  ButtonTextandIconReverse_Colored,
  ButtonIconOnly_Colored,
  ButtonNumberOnly_Colored,
};