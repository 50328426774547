import React from "react";
import { Booking_TimingProps } from "../../../core/models/interface";
import './available_services.css';

const Booking_timings: React.FC<Booking_TimingProps> = ({ hoursOpen }) => {

    const convertTo12HourFormat = (time: string) => {
        const [hours, minutes] = time.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const adjustedHours = hours % 12 || 12;
        return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
    };

    const daysOfWeekOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    if (!hoursOpen || Object.keys(hoursOpen).length === 0) {

        return <p>No booking timings available.</p>;
    }

    return (
        <>
            <p className="new-para-style new-bottom-style availableservices-title-custom">Booking Timings</p>
            <div className="col-l-12">
                {daysOfWeekOrder.map(day => {
                    const dayData = Object.values(hoursOpen).find(d => d.day === day);
                    if (!dayData) {

                        return null;
                    }
                    const { day: dayName, open_from_time, open_to_time } = dayData;
                    const fromTime = convertTo12HourFormat(open_from_time);
                    const toTime = convertTo12HourFormat(open_to_time);
                    return (
                        <div className="col-l-12 new_flex_between" key={dayName}>
                            <div className="col-l-3">
                                <p className="availableservices-para-custom"><span>{dayName}</span></p>
                            </div>
                            <div className="col-l-3">
                                <p className="availableservices-para-custom"><span>{fromTime} - {toTime}</span></p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Booking_timings;