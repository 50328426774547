export const zipCodePatterns: Record<string, RegExp> = {
    'Afghanistan': /^\d{4}$/,
    'Albania': /^\d{4}$/,
    'Algeria': /^\d{5}$/,
    'Andorra': /^\d{5}$/,
    'Angola': /^\d{5}$/,
    'Antigua and Barbuda': /^268$/,
    'Argentina': /^[A-Z]\d{4}[A-Z]{3}$/,
    'Armenia': /^\d{6}$/,
    'Australia': /^\d{4}$/,
    'Austria': /^\d{4}$/,
    'Azerbaijan': /^\d{4}$/,
    'Bahamas': /^\d{5}$/,
    'Bahrain': /^\d{3,4}$/,
    'Bangladesh': /^\d{4}$/,
    'Barbados': /^\d{5}$/,
    'Belarus': /^\d{6}$/,
    'Belgium': /^\d{4}$/,
    'Belize': /^(\d{5}(-\d{4})?)?$/,
    'Benin': /^\d{5}$/,
    'Bhutan': /^\d{5}$/,
    'Bolivia': /^\d{4}$/,
    'Bosnia and Herzegovina': /^\d{5}$/,
    'Botswana': /^\d{5}$/,
    'Brazil': /^\d{5}-\d{3}$/,
    'Brunei': /^([A-Z]{2}\d{4})?$/,
    'Bulgaria': /^\d{4}$/,
    'Burkina Faso': /^\d{5}$/,
    'Burundi': /^\d{4}$/,
    'Cabo Verde': /^\d{4}$/,
    'Cambodia': /^\d{5}$/,
    'Cameroon': /^\d{5}$/,
    'Canada': /^[ABCEGHJKLMNPRSTVXY]\d[A-Z] \d[A-Z]\d$/,
    'Central African Republic': /^\d{5}$/,
    'Chad': /^\d{5}$/,
    'Chile': /^\d{7}$/,
    'China': /^\d{6}$/,
    'Colombia': /^\d{6}$/,
    'Comoros': /^\d{5}$/,
    'Congo (Congo-Brazzaville)': /^\d{5}$/,
    'Costa Rica': /^\d{5}$/,
    'Croatia': /^\d{5}$/,
    'Cuba': /^\d{5}$/,
    'Cyprus': /^\d{4}$/,
    'Czech Republic': /^\d{5}$/,
    'Denmark': /^\d{4}$/,
    'Djibouti': /^\d{5}$/,
    'Dominica': /^(\d{5}(-\d{4})?)?$/,
    'Dominican Republic': /^\d{5}$/,
    'Ecuador': /^\d{6}$/,
    'Egypt': /^\d{5}$/,
    'El Salvador': /^\d{4}$/,
    'Equatorial Guinea': /^\d{5}$/,
    'Eritrea': /^\d{6}$/,
    'Estonia': /^\d{5}$/,
    'Eswatini': /^[A-Z]\d{3}$/,
    'Ethiopia': /^\d{4}$/,
    'Fiji': /^(\d{5}(-\d{2})?)?$/,
    'Finland': /^\d{5}$/,
    'France': /^\d{5}$/,
    'Gabon': /^\d{5}$/,
    'Gambia': /^\d{5}$/,
    'Georgia': /^\d{4}$/,
    'Germany': /^\d{5}$/,
    'Ghana': /^\d{5}$/,
    'Greece': /^\d{3} \d{2}$/,
    'Grenada': /^(\d{5}(-\d{4})?)?$/,
    'Guatemala': /^\d{5}$/,
    'Guinea': /^\d{3}$/,
    'Guinea-Bissau': /^\d{4}$/,
    'Guyana': /^(\d{6}(-\d{3})?)?$/,
    'Haiti': /^\d{4}$/,
    'Honduras': /^(\d{5}(-\d{4})?)?$/,
    'Hong Kong': /^(\d{6})?$/,
    'Hungary': /^\d{4}$/,
    'Iceland': /^\d{3}$/,
    'India': /^\d{6}$/,
    'Indonesia': /^\d{5}$/,
    'Iran': /^\d{10}$/,
    'Iraq': /^\d{5}$/,
    'Ireland': /^[A-Z]\d{3} [A-Z]{4}$/,
    'Israel': /^\d{5}$/,
    'Italy': /^\d{5}$/,
    'Ivory Coast': /^\d{2}-\d{3}$/,
    'Jamaica': /^\d{5}$/,
    'Japan': /^\d{7}$/,
    'Jordan': /^\d{5}$/,
    'Kazakhstan': /^\d{6}$/,
    'Kenya': /^\d{5}$/,
    'Kosovo': /^\d{5}$/,
    'Kuwait': /^\d{5}$/,
    'Kyrgyzstan': /^\d{6}$/,
    'Laos': /^\d{5}$/,
    'Latvia': /^\d{4}$/,
    'Lebanon': /^(\d{4}(\d{4})?)?$/,
    'Lesotho': /^\d{3}$/,
    'Liberia': /^\d{4}$/,
    'Libya': /^\d{5}$/,
    'Liechtenstein': /^\d{4}$/,
    'Lithuania': /^\d{5}$/,
    'Luxembourg': /^\d{4}$/,
    'Macau': /^(\d{6})?$/,
    'Madagascar': /^\d{3}$/,
    'Malawi': /^\d{5}$/,
    'Malaysia': /^\d{5}$/,
    'Maldives': /^\d{5}$/,
    'Mali': /^\d{5}$/,
    'Malta': /^\d{4}$/,
    'Marshall Islands': /^(\d{5}(-\d{5})?)?$/,
    'Mauritania': /^\d{5}$/,
    'Mauritius': /^\d{5}$/,
    'Mexico': /^\d{5}$/,
    'Micronesia': /^(\d{5}(-\d{4})?)?$/,
    'Moldova': /^\d{4}$/,
    'Monaco': /^\d{5}$/,
    'Mongolia': /^\d{6}$/,
    'Montenegro': /^\d{5}$/,
    'Morocco': /^\d{5}$/,
    'Mozambique': /^\d{4}$/,
    'Myanmar': /^\d{5}$/,
    'Namibia': /^\d{5}$/,
    'Nepal': /^\d{5}$/,
    'Netherlands': /^\d{4} [A-Z]{2}$/,
    'New Zealand': /^\d{4}$/,
    'Nicaragua': /^\d{7}$/,
    'Niger': /^\d{4}$/,
    'Nigeria': /^\d{6}$/,
    'North Korea': /^\d{6}$/,
    'North Macedonia': /^\d{4}$/,
    'Norway': /^\d{4}$/,
    'Oman': /^\d{3}$/,
    'Pakistan': /^\d{5}$/,
    'Palestine': /^\d{5}$/,
    'Panama': /^(\d{6}(-\d{4})?)?$/,
    'Papua New Guinea': /^(\d{3}(-\d{3})?)?$/,
    'Paraguay': /^\d{4}$/,
    'Peru': /^\d{5}$/,
    'Philippines': /^\d{4}$/,
    'Poland': /^\d{2}-\d{3}$/,
    'Portugal': /^\d{7}$/,
    'Qatar': /^(\d{3}(-\d{2})?)?$/,
    'Romania': /^\d{6}$/,
    'Russia': /^\d{6}$/,
    'Rwanda': /^\d{4}$/,
    'Saint Kitts and Nevis': /^(\d{5}(-\d{4})?)?$/,
    'Saint Lucia': /^(\d{5}(-\d{4})?)?$/,
    'Saint Vincent and the Grenadines': /^(\d{5}(-\d{4})?)?$/,
    'Samoa': /^(\d{5}(-\d{4})?)?$/,
    'San Marino': /^\d{5}$/,
    'Sao Tome and Principe': /^\d{5}$/,
    'Saudi Arabia': /^\d{5}$/,
    'Senegal': /^\d{5}$/,
    'Serbia': /^\d{6}$/,
    'Seychelles': /^\d{5}$/,
    'Sierra Leone': /^\d{5}$/,
    'Singapore': /^\d{6}$/,
    'Slovakia': /^\d{5}$/,
    'Slovenia': /^\d{4}$/,
    'Solomon Islands': /^(\d{5}(-\d{4})?)?$/,
    'Somalia': /^\d{5}$/,
    'South Africa': /^\d{4}$/,
    'South Korea': /^\d{5}$/,
    'South Sudan': /^\d{5}$/,
    'Spain': /^\d{5}$/,
    'Sri Lanka': /^\d{5}$/,
    'Sudan': /^\d{5}$/,
    'Suriname': /^(\d{6}(-\d{4})?)?$/,
    'Sweden': /^\d{5}$/,
    'Switzerland': /^\d{4}$/,
    'Syria': /^\d{5}$/,
    'Taiwan': /^\d{5}$/,
    'Tajikistan': /^\d{6}$/,
    'Tanzania': /^\d{5}$/,
    'Thailand': /^\d{5}$/,
    'Timor-Leste': /^\d{4}$/,
    'Togo': /^\d{2}$/,
    'Tonga': /^(\d{5}(-\d{4})?)?$/,
    'Trinidad and Tobago': /^(\d{6}(-\d{4})?)?$/,
    'Tunisia': /^\d{4}$/,
    'Turkey': /^\d{5}$/,
    'Turkmenistan': /^\d{6}$/,
    'Tuvalu': /^(\d{5}(-\d{4})?)?$/,
    'Uganda': /^\d{5}$/,
    'Ukraine': /^\d{5}$/,
    'United Arab Emirates': /^\d{5}$/,
    'United Kingdom': /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/,
    'United States': /^\d{5}(-\d{4})?$/,
    'Uruguay': /^\d{5}$/,
    'Uzbekistan': /^\d{6}$/,
    'Vanuatu': /^(\d{4}(-\d{4})?)?$/,
    'Vatican City': /^\d{5}$/,
    'Venezuela': /^\d{4}$/,
    'Vietnam': /^\d{6}$/,
    'Yemen': /^\d{5}$/,
    'Zambia': /^\d{5}$/,
    'Zimbabwe': /^\d{4}$/,
};
