import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import OtpInput from 'react-otp-input';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import './style/bookinglogin.css';
import { TKN_OTPInput } from '../../../components/inputfields/input';
import Footer from '../../../components/footer/footer';
import { validateEmptyValuenumber, validateOTP } from "../../../../helper/validate";
import apiService from '../../../../api/authentication-service';
import en from '../../../../en/en';


const ForgotPasswordOtp = () => {

    const routes = all_routes;
    const navigateTo = useNavigate();

    const userdetails = useSelector((state: RootState) => state.useremail);
    console.log('USEREMAILL redux', userdetails.email);
    console.log('USEREID redux', userdetails.userid);


    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const email = userdetails.email;
    // const Userrole = Vendordetails.userrole;

    const handleSubmit = () => {
        let isValid = true;

        console.log('ISVALID_OTP', otp);

        const fieldsToValidate = [
            { value: otp, errorSetter: setError, validationFunction: [validateEmptyValuenumber, validateOTP], fieldName: 'OTP' },
        ];

        fieldsToValidate.forEach((field) => {
            for (const validationFunction of field.validationFunction) {
                const validationResult = validationFunction(field.value, field.fieldName);

                if (validationResult.status === 'false') {
                    field.errorSetter(validationResult.error || '');
                    isValid = false;
                    break;
                }
            }
        });

        if (isValid) {
            setLoading(true);
            sendOTPVerificationRequest();
        }
    };

    const sendOTPVerificationRequest = () => {
        const payload = otpRequest();
        console.log('PAYLOAD', payload);
        apiService.registerotp(payload)
            .then((response: any) => {
                console.log('API Response:', response);
                getOtpResponse(response?.data);
            })
            .catch((error: any) => {
                console.error('Error fetching User details', error);
                setError('Failed to fetch user details');
            });
    };

    const otpRequest = () => ({
        userid: userdetails.userid,
        user_otp: otp,
        email: userdetails.email,
    });

    const getOtpResponse = (response: any) => {

        if (response) {

            const status = response?.status;
            if (status && status === true) {

                const userId = response?.data?.userid;
                console.log("Store :: USER_ID : ", userId);
                if (userId) {

                    navigateTo(routes.resetPassword)
                }

            } else {

                setError(en.INVALID_OTP);
            }
        }
    };

    const handleOtpChange = (Otpvalue: any) => {

        setOtp(Otpvalue)
        if (error) {

            setError('')
        }
    }

    return (
        <>
            <PagesAuthHeader />
            <div className="content">
                <div className="container">
                    <div className="row">
                        {/* Email Otp Form */}
                        <div className="col-md-6 col-lg-6 mx-auto">
                            <div className="login-wrapotp otp-wrap">
                                <div className="login-header d-flex flex-column align-items-center">
                                    <h3 className='otp-title-custom'>OTP Verification</h3>
                                </div>
                                <div className="col-md-12 col-lg-12 mx-auto d-flex flex-column align-items-center">
                                    <div className="otpmessage"> An OTP has been sent to your email address.</div>
                                    <div className="otpmessage">If it is not in your inbox,please check your spam folder.</div>
                                </div>
                                <div className="col-md-12" style={{ paddingBottom: "15px" }}>
                                </div>
                                <form action={routes.login}>
                                    <div className="form-group">
                                        <div className="col-md-11">

                                            <div className="d-flex passcode-wrap digit-group passcode-verified d-flex flex-column align-items-center">
                                                <OtpInput
                                                    value={otp}
                                                    onChange={handleOtpChange}
                                                    numInputs={6}
                                                    inputStyle={{
                                                        width: '2rem',
                                                        height: '3rem',
                                                        margin: '0 0.5rem',
                                                        fontSize: '1.5rem',
                                                        borderRadius: '4px',
                                                        border: '1px solid rgba(0,0,0,0.3)',
                                                    }}
                                                    renderSeparator={<span> </span>}
                                                    renderInput={(props) => <input {...props} className='passcode-input-custom' />}
                                                />
                                                {error && <div className="errorText d-flex flex-column align-items-center">{error}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <p className="no-acc hide">
                                        Didn&apos;t get the OTP? <Link to="#">Resend OTP</Link>
                                    </p>
                                    <div className="col-md-12 d-flex flex-column align-items-center ">
                                        <Link to=''
                                            className="btn btn-primary w-50 btn-verifypass-custom"
                                            type="submit"
                                            onClick={handleSubmit}
                                        > Verify &amp; Proceed </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* /Email Otp Form */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ForgotPasswordOtp;
