import React, { useState } from 'react';
import PagesAuthHeader from './common/header';
import * as Icon from 'react-feather';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Link, useNavigate } from 'react-router-dom';
import en from '../../../../en/en';
import { validatePassword_ConfirmPassword } from '../../../../helper/validate';
import apiService from '../../../../api/authentication-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../core/data/redux/store';

const ResetPassword = () => {
  const routes = all_routes;
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmpasswordError, setConfirmPasswordError] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState([false, false]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const userdetails = useSelector((state: RootState) => state.useremail);
  const navigateTo = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPasswordResponse, setConfirmPasswordResponse] = useState({
    confirmPasswordResponceText: '',
    confirmPasswordResponceKey: '',
  });

  const togglePasswordVisibility = (index: number) => {
    const updatedVisibility = [...passwordVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisibility(updatedVisibility);
  };

  const [passwordResponce, setPasswordResponce] = useState({
    passwordResponceText: '',
    passwordResponceKey: '',
  });

  const onChangePassword = (password: string) => {
    setPassword(password);

    if (password.match(/^$|\s+/)) {
      setPasswordResponce({
        passwordResponceText: en.PASSWORD_CONDITION,
        passwordResponceKey: '',
      });
    } else if (password.length === 0) {
      setPasswordResponce({
        passwordResponceText: '',
        passwordResponceKey: '',
      });
    } else if (password.length < 8) {
      setPasswordResponce({
        passwordResponceText: en.PASSWORD_CONDITION,
        passwordResponceKey: '0',
      });
    } else if (
      password.search(/[a-z]/) < 0 ||
      password.search(/[A-Z]/) < 0 ||
      password.search(/[0-9]/) < 0
    ) {
      setPasswordResponce({
        passwordResponceText: en.PASSWORD_CONDITION,
        passwordResponceKey: '1',
      });
    } else if (password.search(/(?=.*?[#?!@$%^&*-])/) < 0) {
      setPasswordResponce({
        passwordResponceText: en.PASSWORD_CONDITION,
        passwordResponceKey: '2',
      });
    } else {
      setPasswordResponce({
        passwordResponceText: '',
        passwordResponceKey: '3',
      });
    }
  };

  const onChangeConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);
    if (confirmPassword.match(/^$|\s+/)) {
      setConfirmPasswordResponse({
        confirmPasswordResponceText: 'Whitespaces are not allowed',
        confirmPasswordResponceKey: '',
      });
    } else if (confirmPassword.length === 0) {
      setConfirmPasswordResponse({
        confirmPasswordResponceText: '',
        confirmPasswordResponceKey: '',
      });
    } else if (confirmPassword.length < 8) {
      setConfirmPasswordResponse({
        confirmPasswordResponceText: 'Weak. Must contain at least 8 characters',
        confirmPasswordResponceKey: '0',
      });
    } else if (
      confirmPassword.search(/[a-z]/) < 0 ||
      confirmPassword.search(/[A-Z]/) < 0 ||
      confirmPassword.search(/[0-9]/) < 0
    ) {
      setConfirmPasswordResponse({
        confirmPasswordResponceText:
          'Average. Must contain at least 1 upper case and number',
        confirmPasswordResponceKey: '1',
      });
    } else if (confirmPassword.search(/(?=.*?[#?!@$%^&*-])/) < 0) {
      setConfirmPasswordResponse({
        confirmPasswordResponceText: 'Almost. Must contain a special symbol',
        confirmPasswordResponceKey: '2',
      });
    } else if (password !== confirmPassword) {
      setConfirmPasswordResponse({
        confirmPasswordResponceText: 'Passwords do not match',
        confirmPasswordResponceKey: '3',
      });
    } else {
      setConfirmPasswordResponse({
        confirmPasswordResponceText: 'Confirm password matches the new password.',
        confirmPasswordResponceKey: '4',
      });
    }
  };

  const handleSubmit = () => {
    let isValid = true;
    const ispasswordValid = validatePassword_ConfirmPassword(password, confirmPassword, 'password');

    if (ispasswordValid["status"] === "false") {
      setPasswordError(ispasswordValid["newPasswordError"]);
      setConfirmPasswordError(ispasswordValid["confirmPasswordError"]);
      isValid = false;
    }

    if (isValid) {
      sendResetPasswordRequest();
    }
  };

  const sendResetPasswordRequest = () => {
    apiService.resetPassword(resetPasswordRequest()).then((response) => {
      resetPasswordResponse(response?.data);
    }).catch((error) => {
      console.log('error', error);
    });
  };

  const resetPasswordRequest = () => ({
    new_password: password,
    confirm_password: confirmPassword,
    user_id: userdetails.userid
  });
  const resetPasswordResponse = (response: any) => {
    if (response) {
      const status = response?.status;
      if (status && status === true) {
        setErrorMessage(null);
        navigateTo(routes.login);
      } else {
        setErrorMessage('Unable to reset your password. Please try again.');
      };
    } else {
      setErrorMessage('Unable to reset your password. Please try again.');
    };
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Reset Password</h3>
                  <p>
                    Your new password must be different from previous used
                    passwords.
                  </p>
                </div>
                {errorMessage && (
                  <div className="response-message" style={{ color: 'green', fontSize: 18, marginBottom: '10px' }}>
                    {errorMessage}
                  </div>
                )}
                <form>
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">
                        New Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="pass-group" id="passwordInput">
                        <input
                          type={passwordVisibility[0] ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => onChangePassword(e.target.value)}
                          className="form-control pass-input"
                          placeholder="*************"
                        />
                        <span
                          className="toggle-password"
                          onClick={() => togglePasswordVisibility(0)}
                        >
                          {passwordVisibility[0] ? (
                            <Icon.Eye className="react-feather-custom" />
                          ) : (
                            <Icon.EyeOff className="react-feather-custom" />
                          )}
                        </span>
                      </div>
                      {passwordError && (
                        <div className="response-message" style={{ color: 'red' }}>
                          {passwordError}
                        </div>
                      )}
                      <div
                        className={`password-strength ${passwordResponce.passwordResponceKey === '0' ?
                          'poor-active' : passwordResponce.passwordResponceKey === '1' ?
                            'avg-active' : passwordResponce.passwordResponceKey === '2' ?
                              'strong-active' : passwordResponce.passwordResponceKey === '3' ?
                                'heavy-active' : ''}`
                        }
                        id="passwordStrength"
                      >
                        <span id="poor" className="active"></span>
                        <span id="weak" className="active"></span>
                        <span id="strong" className="active"></span>
                        <span id="heavy" className="active"></span>
                      </div>
                      <div
                        id="passwordInfo"
                        className={
                          passwordResponce.passwordResponceKey === '0'
                            ? 'weak'
                            : passwordResponce.passwordResponceKey === '1'
                              ? 'average'
                              : passwordResponce.passwordResponceKey === '2'
                                ? 'good'
                                : passwordResponce.passwordResponceKey === '3'
                                  ? 'great'
                                  : ''
                        }
                      >
                        {passwordResponce.passwordResponceText && (
                          <p style={{ color: 'red' }}>{passwordResponce.passwordResponceText}</p>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="pass-group">
                        <input
                          type={passwordVisibility[1] ? 'text' : 'password'}
                          className="form-control pass-input"
                          placeholder="*************"
                          value={confirmPassword}
                          onChange={(e) => onChangeConfirmPassword(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={() => togglePasswordVisibility(1)}
                        >
                          {passwordVisibility[1] ? (
                            <Icon.Eye className="react-feather-custom" />
                          ) : (
                            <Icon.EyeOff className="react-feather-custom" />
                          )}
                        </span>
                      </div>
                      {confirmpasswordError !== '' && (
                        <div className="response-message" style={{ color: 'red' }}>{confirmpasswordError}</div>
                      )}
                    </div>
                  </div>
                  <Link
                    to={''}
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save Change
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
