import { bookingData } from '../json/all_booking';
import { ipaddress } from '../json/banip_address';
// import { banktransfer } from '../json/bank_transferlist';
// import { blogData } from '../json/blog_categories';
// import { commentsData } from '../json/blog_comments';
import { CancelledData } from '../json/cancelled_booking';
import { dashboard } from '../json/dashboard_data';
import { membershipTransaction } from '../json/membership_transaction';
import { pageData } from '../json/page_list';
import { pagesData } from '../json/pages_list';
import { smstemplate } from '../json/sms_template';
// import { statesData } from '../json/state';
import { subCategories } from '../json/sub_categories';
import { walletHistory } from '../json/wallet_history';
// import { SalesReport } from '../json/sales_services';
// import { ServicesData } from '../json/service';
import { ProviderLogin } from '../json/provider_loginactivity';
import { ProviderDevice } from '../json/providerdevice_management';
import { ProviderOffer } from '../json/provider_offer';
// import { newsletter } from '../json/email_newsletter';
// import { bookingCompletedData } from '../json/booking-completed';
import { cashOnDeliveryData } from '../json/cash-on-delivery';
// import { citiesData } from '../json/cities';
import { ContactMessagesViewData } from '../json/contact-message-viewdata';
import { ContactMessages } from '../json/contact-messages';
// import { countriesData } from '../json/countries';
import { couponsData } from '../json/coupons';
import { currenciesData } from '../json/currencies';
// import { customersData } from '../json/customersdata';
// import { providersSidebar } from '../json/provider_sidebar';
import { RefundRequestData } from '../json/refund_requestsdata';
import { RegisterReportData } from '../json/register_reportdata';
// import { RejectedTranferlistdata } from '../json/rejected_transferlistdata';
import { ReviewTypes } from '../json/review_typedata';
import { ReviewData } from '../json/reviewdata';
import { SalesTransactionsData } from '../json/sales_transactionsdata';
// import { walletData } from '../json/wallet';
import { faqData } from '../json/faq';
import { featureServicesData } from '../json/feature-services';
// import { payoutRequestsDate } from '../json/payout-requests';
import { pendingServiceData } from '../json/pending-services';
// import { successfulTransferlistData } from '../json/successfull-transferlist';
import { systemBackUpData } from '../json/system-backup';
import { taxRatesData } from '../json/tax-rates';
// import { testimonialsData } from '../json/testimonials';
import { verficationRequestData } from '../json/verfication-request';
import { header } from '../json/header';
// import { adminDashboard1Data } from '../json/admin-dashboard1';
import { adminDashboard2Data } from '../json/admin-dashboard2';
// import { adminEarningsData } from '../json/admin-earnings';
import { announcementsData } from '../json/announcements';
// import { approvedTransferlistData } from '../json/approved-transferlist';
// import { inActiveServicesData } from '../json/inactive-services';
import { inProgressBookingData } from '../json/inprogress-booking';
import { providerCouponsData } from '../json/provider-coupons';
import { providerSalesData } from '../json/provider-sales';
// import { providerWalletData } from '../json/provider-wallet';
// import { providersData } from '../json/providers';
import { DeviceManagementData } from '../json/device-managementdata';
import { DataBackupdata } from '../json/database_backupdata';
import { DeleteAccountrequestsdata } from '../json/delete_account_requestsdata';
import { DeletedServicesdata } from '../json/deleted_services_data';
import { emailTemplateData } from '../json/email-template';
// import { userData } from '../json/users';
// import { providerEarningsAdminData } from '../json/provider_earnings';
// import { pendingTransferlistData } from '../json/pendingTransfetList-data';
// import { providerEarning } from '../json/provider_earning';
// import { offer } from '../json/offer';
// import { customerWalletData } from '../service/admin/customerWalletData';
// import { adminDashboard3Data } from '../json/admin-dashboard3';
import { refundReportData } from '../json/refund-reports';
import { categoriesData } from '../json/categoriesdata';
// import { customerWalletData } from '../json/customerWalletData';


export interface UserState {
  address1: string;
  address2: string;
  city: string;
  country: string;
  country_code: string;
  country_details: string;
  dob: string | null;
  email: string;
  first_name: string;
  gender: string;
  last_name: string;
  otp_verified: number;
  phone: string;
  profile_verified: number;
  profilephoto: string;
  state: string;
  status: number;
  token: string;
  user_id: number;
  zipcode: string;
}
export interface UseremailState {
  email: string;
  userid: number;
}

export interface RoleState {
  role: number;
}

export interface VendorroleState {
  userrole: number;
  id: number;
}

export interface CountryDetails {
  callingCode: string[];
  cca2: string;
  currency: string[];
  flag: string;
  name: string;
  region: string;
  subregion: string;
}

export interface GuestState {
  user: string | null;
  country: string;
  countryName: string;
  countryCode: string;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  phone: string;
  country_details: CountryDetails;
  dob: string;
}

export interface VendorState {
  State: string;
  Zipcode: string;
  actual_image: string;
  address: string;
  approval_status: number;
  bussiness_address: string;
  bussiness_name: string;
  category_id: number;
  city: string;
  country: string;
  country_code: string;
  country_details: string;
  created_at: string | null;
  deleted_at: string | null;
  deleted_by: string | null;
  description: string;
  id: number;
  latitude: number;
  longitude: number;
  mobile: string;
  owner_name: string;
  photo: string;
  region_id: number;
  status: number;
  subcategory_id: number;
  timezone: string;
  updated_at: string;
  vendor_email: string;
  vendor_id: number;
  website: string | null;
}

export interface BusinessState {
  State: string;
  bussinessAddress: string;
  bussiness_name: string;
  city: string;
  country: string;
  mobile: string;
  services: Array<{
    service_name: string;
    serviceDescription: string;
    service_image: string;
    service_vendor_map_id: number;
    startDate: string;
  }>;
  timezone: string;
  vendorEmail: string;
  vendor_id: number;
  vendorImages: string[];
  website: string;
  category: 0;
  subcategory: 0;
}

export interface ServiceSubscriptionDetails {
  subdetailscreated_at: string;
  expiry_date: string;
  service_subscribed_date: string;
  active_subscription: boolean;
  service_subscription_duration: number;
  service_subscription_id: number;
  service_subscription_name: string;
  service_subscription_status: number;
  services_count: number;
  subscription_end_date: string;
  updated_at: string;
  vendor_id: number;
  vendor_service_subscription_id: number;
}

export interface VendorMapDetailsState {
  mobile: string;
  bussiness_address: string;
  vendor_email: string;
  service_name: string;
  serviceDescription: string;
  service_image: string;
  bussiness_name: string;
  hoursOpen: string;
  vendorMapId: number;
}

export interface UserLocationDetailsState {
  latitude: number;
  longitude: number;
  place_name: string;
}

export interface SessionTokenDetails {
  token: string;
}

export interface SearchRequestDataState {
  latitude: number;
  longitude: number;
  place_name: string;
  query: string;
  category: number;
  subcategory: number;
}

export interface UserEmailAndPhoneState {
  email: string;
  phone: string;
  countryCodeDetails?: any;
}

const initialState = {
  service_dataa: [],
  header_data: header,
  dashboard_data: dashboard,
  toggleSidebar: false,
  toggleSidebar2: false,
  showLoader: true,
  mouseOverSidebar: false,
  // newsletterData: newsletter,
  ipaddressData: ipaddress,
  // banktransferData: banktransfer,
  // providersSidebar:providersSidebar,
  current_route: { base: '', page: '', last: '' },
  current_route_array: [],
  refund_request: RefundRequestData,
  // rejected_transferlist: RejectedTranferlistdata,
  review_data: ReviewData,
  review_data_type: ReviewTypes,
  sales_transactions: SalesTransactionsData,
  register_report: RegisterReportData,
  // blog_data: blogData,
  // blog_comments: commentsData,
  // states_Date: statesData,
  all_booking: bookingData,
  cancelled_booking: CancelledData,
  page_data: pageData,
  pages_data: pagesData,
  sub_categories: subCategories,
  sms_template: smstemplate,
  wallet_history: walletHistory,
  membership_transaction: membershipTransaction,
  // sales_report: SalesReport,
  // service_data: ServicesData,
  providerlogin_activity: ProviderLogin,
  providerdevice_management: ProviderDevice,
  provider_offer: ProviderOffer,
  // wallet: walletData,
  // cities: citiesData,
  // countries: countriesData,
  constantMessages: ContactMessages,
  contactMessageView: ContactMessagesViewData,
  currencies: currenciesData,
  coupons: couponsData,
  // bookingCompleted: bookingCompletedData,
  cashOnDelivery: cashOnDeliveryData,
  // customerData: customersData,
  faq: faqData,
  featureService: featureServicesData,
  // payoutRequest: payoutRequestsDate,
  pendingService: pendingServiceData,
  // successfulTransfetList: successfulTransferlistData,
  systemBackUp: systemBackUpData,
  taxRates: taxRatesData,
  // testmonials: testimonialsData,
  verficationRequest: verficationRequestData,
  // provider_wallet: providerWalletData,
  provider_coupons: providerCouponsData,
  // admin_earnings: adminEarningsData,
  announcements: announcementsData,
  // approved_transferlist: approvedTransferlistData,
  // inactive_services: inActiveServicesData,
  // admin_dashboard_1: adminDashboard1Data,
  admin_dashboard_2: adminDashboard2Data,
  inprogress_booking: inProgressBookingData,
  // providers: providersData,
  provider_sales: providerSalesData,
  device_managementdata: DeviceManagementData,
  database_backup_data: DataBackupdata,
  delete_account_request_data: DeleteAccountrequestsdata,
  deleted_services_data: DeletedServicesdata,
  email_template: emailTemplateData,
  // users: userData,
  // provider_earnings_admin: providerEarningsAdminData,
  // provider_earning: providerEarning,
  // pendingTransferList: pendingTransferlistData,
  // offer: offer,
  // customerWallet: customerWalletData,
  mobileSidebar: false,
  refund_reports: refundReportData,
  // admin_dashboard_3: adminDashboard3Data,
  // offer_data: offer,
  categoriesData: categoriesData,
  vendorId: 0,

  vendorMapDetails: {
    mobile: '',
    bussiness_address: '',
    vendor_email: '',
    service_name: '',
    serviceDescription: '',
    service_image: '',
    bussiness_name: '',
    hoursOpen: '',
    vendorMapId: 0
  } as VendorMapDetailsState,

  user: {
    address1: '',
    address2: '',
    city: '',
    country: '',
    country_code: '',
    country_details: '',
    dob: null,
    email: '',
    first_name: '',
    gender: '',
    last_name: '',
    otp_verified: 0,
    phone: '',
    profile_verified: 0,
    profilephoto: '',
    state: '',
    status: 0,
    token: '',
    user_id: 0,
    zipcode: '',
  } as UserState,

  useremail: {
    userid: 0,
    email: '',
  } as UseremailState,

  role: {
    role: 0,
  } as RoleState,

  vendorrole: {
    userrole: 0,
    id: 0,
  } as VendorroleState,

  vendor: {
    State: '',
    Zipcode: '',
    actual_image: '',
    address: '',
    approval_status: 0,
    bussiness_address: '',
    bussiness_name: '',
    category_id: 0,
    city: '',
    country: '',
    country_code: '',
    country_details: '',
    created_at: '',
    deleted_at: '',
    deleted_by: '',
    description: '',
    id: 0,
    latitude: 0,
    longitude: 0,
    mobile: '',
    owner_name: '',
    photo: '',
    region_id: 0,
    status: 0,
    subcategory_id: 0,
    timezone: '',
    updated_at: '',
    vendor_email: '',
    vendor_id: 0,
    website: '',
  } as VendorState,

  guest: {
    country: '',
    user: null,
    countryCode: '',
    countryName: '',
    country_details: {
      callingCode: [],
      cca2: '',
      currency: [],
      flag: '',
      name: '',
      region: '',
      subregion: '',
    },
    email: '',
    firstName: '',
    gender: '',
    lastName: '',
    phone: '',
    dob: '',
  } as GuestState,

  business: {
    State: '',
    bussinessAddress: '',
    bussiness_name: '',
    city: '',
    country: '',
    mobile: '',
    category: 0,
    subcategory: 0,
    services: [],
    timezone: '',
    vendorEmail: '',
    vendor_id: 0,
    vendorImages: [],
    website: '',
  } as BusinessState,

  subDetails: {
    subdetailscreated_at: "",
    active_subscription: false,
    expiry_date: "",
    service_subscribed_date: "",
    service_subscription_duration: 0,
    service_subscription_id: 0,
    service_subscription_name: "",
    service_subscription_status: 0,
    services_count: 0,
    subscription_end_date: "",
    updated_at: "",
    vendor_id: 0,
    vendor_service_subscription_id: 0,
    current_package: null,
  } as ServiceSubscriptionDetails,

  userLocationDetails: {
    latitude: 0,
    longitude: 0,
    place_name: ""
  } as UserLocationDetailsState,

  sessionTokenDetails: {    
    token: ""
  } as SessionTokenDetails,

  searchRequestData: {
    latitude: 0,//37.0902
    longitude: 0,//95.7129
    place_name: "",
    query: "",
    category: 0,
    subcategory: 0

  } as SearchRequestDataState,

  userEmailAndPhone: {
    email: '',
    phone: '',
    countryCodeDetails: {}
  } as UserEmailAndPhoneState,
}
export default initialState;

