import { useEffect, useState } from 'react';

const useMaxVisiblePages = (breakPoints: any) => {
    const [resizedValue, setresizedValue] = useState(5);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;

            for (let i = 0; i < breakPoints.length; i++) {
                if (width <= breakPoints[i].width) {
                    setresizedValue(breakPoints[i].pages);
                    break;
                }else{
                    setresizedValue(5);
                }
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [breakPoints]);

    return resizedValue;
}

export default useMaxVisiblePages;