import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import apiService from '../../../api/authentication-service';
import '../serviceList/style.css';

interface Category {
    cat_id: number;
    cat_name: string;
    subcategories: { sub_id: number; sub_name: string }[];
}

interface Props {
    categories?: Category[];
    selectedCategory: number;
    selectedSubCategory: number;
    onCategorySelect: (categoryId: number, subCategoryId: number) => void;
}

const Category: React.FC<Props> = ({ categories = [], selectedCategory, selectedSubCategory, onCategorySelect }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [expandedCategories, setExpandedCategories] = useState<number[]>([]);
    const [allCategories, setAllCategories] = useState<Category[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>(selectedCategory);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<number>(selectedSubCategory);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        fetchAllCategories();
    }, []);

    const fetchAllCategories = () => {
        apiService.getAllCategories()
            .then((response: any) => {
                const status = response?.data?.status;
                if (status === true) {
                    setAllCategories(response?.data?.data || []);
                }
            })
            .catch((error: any) => {
                console.error('Error fetching categories:', error);
            });
    };

    const toggleHeight = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleCategory = (categoryId: number) => {
        if (selectedCategoryId === categoryId) {
            setSelectedCategoryId(0);
            setSelectedSubCategoryId(0);
            onCategorySelect(0, 0);
        } else {
            setSelectedCategoryId(categoryId);
            setSelectedSubCategoryId(0);
            onCategorySelect(categoryId, 0);
        }

        setExpandedCategories(prev =>
            prev.includes(categoryId)
                ? prev.filter(id => id !== categoryId)
                : [...prev, categoryId]
        );
    };

    const handleSubcategorySelect = (subcategoryId: number) => {
        if (selectedSubCategoryId === subcategoryId) {
            setSelectedSubCategoryId(0);
            onCategorySelect(selectedCategoryId, 0);
        } else {
            setSelectedSubCategoryId(subcategoryId);
            onCategorySelect(selectedCategoryId, subcategoryId);
        }
    };

    const calculateHeight = () => {
        if (isExpanded) return 'auto';

        let height = 310;
        allCategories.forEach(category => {
            if (expandedCategories.includes(category.cat_id)) {
                height += category.subcategories.length;
            }
        });

        return height + 'px';
    };

    const filterCheckboxStyle = {
        height: calculateHeight(),
        overflow: 'hidden',
    };

    const subcategoryStyle = {
        marginLeft: '30px',
        marginTop: '14px',
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="filter-content">
            <h2>
                Categories{' '}
                <span>
                    <Icon.ChevronDown className="react-feather-custom" onClick={toggleDropdown} />
                </span>
            </h2>
            <div className="scrollable-container" style={{ marginBottom: isOpen ? '-12px' : '0' }}>
                <label className="checkboxs">
                    <input
                        type="checkbox"
                        checked={selectedCategoryId === 0}
                        onChange={() => {

                            if (selectedCategoryId !== 0) {
                                setSelectedCategoryId(0);
                                setSelectedSubCategoryId(0);
                                onCategorySelect(0, 0);
                            }
                        }}
                    />
                    <span>
                        <i />
                    </span>
                    <b className="check-content">All Categories</b>
                </label>
            </div>
            {isOpen && (
                <div className="filter-checkbox" id="fill-more" style={filterCheckboxStyle}>
                    <div className="scrollable-container">
                        <ul>
                            {allCategories.map((category) => (
                                <li key={category.cat_id}>
                                    <label className="checkboxs">
                                        <input
                                            type="checkbox"
                                            checked={selectedCategoryId === category.cat_id}
                                            onChange={() => toggleCategory(category.cat_id)}
                                        />
                                        <span>
                                            <i />
                                        </span>
                                        <b className="check-content">{category.cat_name}</b>
                                    </label>
                                    {expandedCategories.includes(category.cat_id) && (
                                        <ul className="subcategories">
                                            {category.subcategories.map((subcategory) => (
                                                <li key={subcategory.sub_id}>
                                                    <label className="checkboxs" style={subcategoryStyle}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedSubCategoryId === subcategory.sub_id}
                                                            onChange={() => handleSubcategorySelect(subcategory.sub_id)}
                                                        />
                                                        <span>
                                                            <i />
                                                        </span>
                                                        <b className="check-content">{subcategory.sub_name}</b>
                                                    </label>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
            )}
            {/* <Link to="#" id="more" className="more-view view_more_clr" onClick={toggleHeight}>
                {isExpanded ? (
                    <>
                        View Less{' '}
                        <Icon.ArrowUpCircle className="react-feather-custom ms-1" />
                    </>
                ) : (
                    <>
                        View More{' '}
                        <Icon.ArrowDownCircle className="react-feather-custom ms-1" />
                    </>
                )}
            </Link> */}
        </div>
    );
};

export default Category;
