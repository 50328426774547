import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import apiService from '../../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../../core/data/redux/store';
import en from '../../../../en/en';
import './style.css'
import { logout } from '../../../../core/data/redux/action';
import { TKN_Danger_message } from '../../../components/alert_messages/alert_messages';
import { Modal } from 'react-bootstrap';
import { ButtonTextOnly_Colored } from '../../../components/buttons/button';
import { TKN_failedmodal, TKN_successmodal } from '../../../components/modal/modal';

const ProviderDeleteAccount = () => {
    const routes = all_routes;
    const vendor_obj = useSelector((state: RootState) => state.vendor);
    const [statusbookData, setstatusbookData] = useState<number | null>(null);
    const [deleteStatusValue, setDeleteStatusValue] = useState<number | null>(null);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [failedModal, setFailedModal] = useState(false)
    const user_obj = useSelector((state: RootState) => state.user);
    const dispatch: AppDispatch = useDispatch();
    const [successModal, setSuccessModal] = useState(false);
    const [serverError, setServerError] = useState('');
    const navigateTo = useNavigate();

    useEffect(() => {
        UpdatebookingStatus();
        handleDeleteAccount();
    });

    const viewstatusdata = async (response: any) => {
        if (response) {
            const status = response?.status;
            const message = response?.message;
            const responseData = await response.data;
            setstatusbookData(responseData);
        } else {
            console.log('Error:', response?.data?.error || 'Unknown Error');
        }
    };

    const UpdatebookingStatus = () => {
        const payload = {
            vendor_id: vendor_obj.id,
        };
        apiService.checkVendoraccount(payload)
            .then((response) => {
                viewstatusdata(response?.data);
                console.log('responseData:', response);
            })
            .catch((error) => {
                console.log('An error occurred while processing your request. Please try again.')
            });
    };

    const handleBookingCancel = () => {
        navigateTo(routes.vendorBooking);
    }

    const closeModal = () => {
        setDeleteModal(false);
        navigateTo(routes.vendorDashboard);
    };

    const closeSuccessModal = () => {

        setSuccessModal(false);
        dispatch(logout());
        navigateTo(routes.login);
    };
    const sendDeleteAccountRequest = () => {

        const payload = {
            user_id: user_obj.user_id,
        };
        console.log('API request payload delete:', payload);
        apiService.deleteAccount(payload)
            .then((response) => {
                console.log('UpdateVendorStatus: ', response?.data);
                const responseData = response?.data;
                if (responseData) {
                    setSuccessModal(true);
                }
                else {
                    console.log('Deletion Failed')
                }
            })
            .catch((error) => {
                console.log('An error occurred while processing your request. Please try again.')
            });
    };

    const handleDeleteAccount = async () => {
        try {
            UpdatebookingStatus();
            if (statusbookData === 1) {
                setDeleteStatusValue(statusbookData);
                setDeleteMessage(en.DELETE_PAST_ACTIVE_BOOKINGS);
                setDeleteModal(true);
            } else if (statusbookData === 2) {

                setDeleteStatusValue(statusbookData);
                setDeleteMessage(en.DELETE_PAST_BOOKINGS);
                setDeleteModal(true);
            } else if (statusbookData === 3) {

                setDeleteStatusValue(statusbookData);
                setDeleteMessage(en.DELETE_ACTIVE_BOOKINGS);
                setDeleteModal(true);
            } else if (statusbookData === 0) {

                setDeleteStatusValue(statusbookData);
                setDeleteMessage(en.DELETE_ACCOUNT_DEFAULT);
                setDeleteModal(true);
            } else {

                console.log("invalid booking status data");
            }
        } catch (error) {

            console.error('Error fetching statusbookData:', error);
        }
    };
    const okSuccessModal = () => {
        setFailedModal(false);
        navigateTo(routes.vendorDashboard);
    }

    return (
        <div className="provider-body">
            <div className="main-wrapper">
                {deleteModal && (
                    <>
                        <Modal show={true} backdrop="static" keyboard={false} contentClassName='modal-info-custom' dialogClassName='dialog-info-custom'>
                            <div className="w-100">
                                <div className="icon-modal-info-custom">
                                    <i className="fa-solid fa-info info-icon-custom"></i>
                                </div>
                            </div>
                            <Modal.Header closeButton={false} className='border-none'>
                                <Modal.Title className='color-title'>Delete Account</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='para-modal-custom'>
                                <p>{deleteMessage}</p>
                            </Modal.Body>
                            {(deleteStatusValue === 2 || deleteStatusValue === 0) ? (<Modal.Footer className='modal-footer'>
                                <div>
                                    <ButtonTextOnly_Colored label={'Cancel'} fullWidth={false} onClick={closeModal} className='cancel-btnmodal-info-custom check-okcancel-btn-custom' />
                                </div>
                                <div>
                                    <ButtonTextOnly_Colored label={'OK'} fullWidth={false} onClick={() => sendDeleteAccountRequest()} className='ok-btnmodal-info-custom check-okcancel-btn-custom' />
                                </div>
                            </Modal.Footer>) : (
                                <Modal.Footer className='border-none modal-footer2'>
                                    <div>
                                        <ButtonTextOnly_Colored label={'OK'} fullWidth={false} onClick={handleBookingCancel} className='ok-btnmodal-info-custom check-okcancel-btn-custom' />
                                    </div>
                                </Modal.Footer>)}
                        </Modal >
                    </>

                )}

                {successModal && (
                    <TKN_successmodal title={'Account Deleted'} description={en.DELETE_ACCOUNT_MESSAGE} okbutton={'OK'} handleSuccessButton={closeSuccessModal} handleshowModal={successModal} />
                )}
                {failedModal && (
                    <TKN_failedmodal title={'Account Deletion Failed'} description={en.SERVERERROR} okbutton={'OK'} handleSuccessButton={okSuccessModal} handleshowModal={failedModal} />
                )}
                <div className="mouse-cursor cursor-outer"></div>
                <div className="mouse-cursor cursor-inner"></div>
            </div>
        </div>
    );
};
export default ProviderDeleteAccount;
