import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import * as Icon from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { set_mouseoversidebar_data } from '../../../../core/data/redux/action';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { AppState } from '../../../../core/models/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/free-solid-svg-icons';
import './dashboard';
import { logout } from '../../../../core/data/redux/action';
import { AppDispatch } from '../../../../core/data/redux/store';

const DashSidebar = () => {
  const routes = all_routes;
  const navigateTo = useNavigate();
  const location = useLocation();
  const toggle_data = useSelector((state: AppState) => state.mouseOverSidebar);
  const dispatch: AppDispatch = useDispatch();
  const [subdroptoggle2, setsubdroptoggle2] = useState(false);
  const [activeItem, setActiveItem] = useState<string | null>(null);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes('coming-soon')) {
      if (currentPath.includes('reviews')) {
        setActiveItem('reviews');
      } else if (currentPath.includes('qr-code')) {
        setActiveItem('qr-code');
      }
    } else {
      setActiveItem(null);
    }
  }, [location.pathname]);

  const handleClick = (item: any) => {
    setActiveItem(item);
  };

  const activeRouterPath = (link: string) => {
    return link === location.pathname;
  };

  const toogle = () => {
    dispatch(set_mouseoversidebar_data(toggle_data ? false : true));
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('UserDetails');
    localStorage.removeItem('VendorDetails');
    navigateTo(routes.login);
  };

  return (
    <div
      onMouseEnter={toogle}
      onMouseLeave={toogle}
      className="sidebar"
      id="sidebar"
    >
      <div className="sidebar-inner slimscroll">
        <Scrollbars>
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className={`${activeRouterPath(routes.vendorDashboard) ? 'active' : ''}`}>
                <Link to={routes.vendorDashboard}>
                  <Icon.Grid className="react-feather-icon" />{' '}
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className={`${activeRouterPath(routes.vendorservice) ? 'active' : ''}`}>
                <Link to={routes.vendorservice}>
                  <Icon.Briefcase className="react-feather-icon" />{' '}
                  <span>My Services</span>
                </Link>
              </li>
              <li className={`${activeRouterPath(routes.vendorBooking) ? 'active' : ''}`}>
                <Link to={routes.vendorBooking}>
                  <Icon.Calendar className="react-feather-icon" />{' '}
                  <span>Bookings </span>
                </Link>
              </li>
              <li className={`${activeRouterPath(routes.vendorWalkin) ? 'active' : ''}`}>
                <Link to={routes.vendorWalkin}>
                  <FontAwesomeIcon icon={faWalking} className="react-feather-icon" />{' '}
                  <span>Walk-in Bookings</span>
                </Link>
              </li>
              <li className={`${activeRouterPath(routes.vendorsubscription) ? 'active' : ''}`}>
                <Link to={routes.vendorsubscription}>
                  <Icon.DollarSign className="react-feather-icon" />
                  <span>Subscription</span>
                </Link>
              </li>
              <li
                className={activeItem === 'reviews' ? 'active' : ''}
                onClick={() => handleClick('reviews')}
              >

                <Link to={routes.vendorComingSoon}>
                  <Icon.Star className="react-feather-icon" />{' '}
                  <span>Reviews</span>
                </Link>
              </li>
              <li className={`${activeRouterPath(routes.qrCodeScreen) ? 'active' : ''}`}>
                <Link to={routes.qrCodeScreen}>
                  <Icon.Camera className="react-feather-icon" />{' '}
                  <span>QR Code</span>
                </Link>
              </li>
              <li className={`${activeRouterPath(routes.vendorReportAnIssue) ? 'active' : ''}`}>
                <Link to={routes.vendorReportAnIssue}>
                  <Icon.User className="react-feather-icon" />{' '}
                  <span>Contact Us</span>
                </Link>
              </li>
              <li className={`${activeRouterPath(routes.vendorResetPassword) ? 'active' : ''}`}>
                <Link to={routes.vendorResetPassword}>
                  <Icon.Lock className="react-feather-icon" />{' '}
                  <span>Change Password</span>
                </Link>
              </li>
              <li className={`${activeRouterPath(routes.vendorAppointmentSettings) ? 'active' : ''}`} >
                <Link to={routes.vendorAppointmentSettings}>
                  <Icon.User className="react-feather-icon" />{' '}
                  <span>Profile</span>
                </Link>
              </li>
              <li className={`${activeRouterPath(routes.vendorDeleteAccount) ? 'active' : ''}`} >
                <Link to={routes.vendorDeleteAccount}>
                  <Icon.User className="react-feather-icon" />{' '}
                  <span>Delete Account</span>
                </Link>
              </li>
              <li>
                <Link onClick={(e) => { e.preventDefault(); handleLogout(); }} to={''}>
                  <Icon.LogOut className="react-feather-icon" />{' '}
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </Scrollbars>
        <div
          className="dropdown-menu user-drop"
          id="dropboxes"
          style={{ display: subdroptoggle2 ? 'block' : 'none' }}
        >
          <div className="menu-user">
            <div className="menu-user-img avatar-online">
              <ImageWithBasePath
                src="assets/img/profiles/avatar-02.jpg"
                alt="user"
              />
            </div>
            <div className="menu-user-info">
              <h6>John Smith</h6>
              <p>Active</p>
            </div>
          </div>
          <div className="set-user">
            <p>Set as Away</p>
            <div className="status-toggle">
              <input type="checkbox" id="active-user" className="check" />
              <label htmlFor="active-user" className="checktoggle">
                checkbox
              </label>
            </div>
          </div>
          <ul className="set-menu">
            <li>
              <Link to="/provider-security-settings">
                <Icon.Settings className="react-feather-icon me-2" /> Settings
              </Link>
            </li>
            <li>
              <Link to="/provider-profile-settings">
                <Icon.User className="react-feather-icon me-2" /> Your Account
              </Link>
            </li>
            <li>
              <Link to="#">
                <Icon.CheckCircle className="react-feather-icon me-2" />{' '}
                Identity Verification
              </Link>
            </li>
          </ul>
          <ul className="help-menu">
            <li>
              <Link to="#">Help Center</Link>
            </li>
            <li>
              <Link to="/terms-condition">Terms of Condition</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div
          className="dropdown-menu user-drop"
          id="dropboxes"
          style={{ display: subdroptoggle2 ? 'block' : 'none' }}
        >
          <div className="menu-user">
            <div className="menu-user-img avatar-online">
              <ImageWithBasePath
                src="assets/img/profiles/avatar-02.jpg"
                alt="user"
              />
            </div>
            <div className="menu-user-info">
              <h6>John Smith</h6>
              <p>Active</p>
            </div>
          </div>
          <div className="set-user">
            <p>Set as Away</p>
            <div className="status-toggle">
              <input type="checkbox" id="active-user" className="check" />
              <label htmlFor="active-user" className="checktoggle">
                checkbox
              </label>
            </div>
          </div>
          <ul className="set-menu">
            <li>
              <Link to="/provider-security-settings">
                <Icon.Settings className="react-feather-icon me-2" /> Settings
              </Link>
            </li>
            <li>
              <Link to="/provider-profile-settings">
                <Icon.User className="react-feather-icon me-2" /> Your Account
              </Link>
            </li>
            <li>
              <Link to="#">
                <Icon.CheckCircle className="react-feather-icon me-2" />{' '}
                Identity Verification
              </Link>
            </li>
          </ul>
          <ul className="help-menu">
            <li>
              <Link to="#">Help Center</Link>
            </li>
            <li>
              <Link to="/terms-condition">Terms of Condition</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DashSidebar;
