export const ProviderPlanData = [
  {
    payoutDate: 'Sep 26, 2022',
    planDetails: 'Basic, Monthly',
    amount: '$29.00',
    invoice: 'Invoice 08 sep, 2022',
    status: 'Paid',
  },
  {
    payoutDate: 'Sep 26, 2022',
    planDetails: 'Basic, Monthly',
    amount: '$29.00',
    invoice: 'Invoice 08 sep, 2022',
    status: 'Paid',
  },
  {
    payoutDate: 'Sep 26, 2022',
    planDetails: 'Basic, Monthly',
    amount: '$29.00',
    invoice: 'Invoice 08 sep, 2022',
    status: 'Paid',
  },
  {
    payoutDate: 'Sep 26, 2022',
    planDetails: 'Basic, Monthly',
    amount: '$29.00',
    invoice: 'Invoice 08 sep, 2022',
    status: 'Pending',
  },
];
