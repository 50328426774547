const countryRegexPatterns: { [key: string]: RegExp } = {
    'Afghanistan': /^\+93\d{9}$/,
    'Albania': /^\+355\d{8}$/,
    'Algeria': /^\+213\d{9}$/,
    'Andorra': /^\+376\d{6}$/,
    'Angola': /^\+244\d{9}$/,
    'Antigua and Barbuda': /^\+1\d{10}$/,
    'Argentina': /^\+54\d{10}$/,
    'Armenia': /^\+374\d{8}$/,
    'Australia': /^\+61\d{9}$/,
    'Austria': /^\+43\d{8}$/,
    'Azerbaijan': /^\+994\d{9}$/,
    'Bahamas': /^\+1\d{10}$/,
    'Bahrain': /^\+973\d{8}$/,
    'Bangladesh': /^\+880\d{9}$/,
    'Barbados': /^\+1\d{10}$/,
    'Belarus': /^\+375\d{9}$/,
    'Belgium': /^\+32\d{8}$/,
    'Belize': /^\+501\d{7}$/,
    'Benin': /^\+229\d{7}$/,
    'Bhutan': /^\+975\d{8}$/,
    'Bolivia': /^\+591\d{8}$/,
    'Bosnia and Herzegovina': /^\+387\d{8}$/,
    'Botswana': /^\+267\d{8}$/,
    'Brazil': /^\+55\d{11}$/,
    'Brunei': /^\+673\d{8}$/,
    'Bulgaria': /^\+359\d{9}$/,
    'Burkina Faso': /^\+226\d{8}$/,
    'Burundi': /^\+257\d{8}$/,
    'Cambodia': /^\+855\d{8}$/,
    'Cameroon': /^\+237\d{8}$/,
    'Canada': /^\+1\d{10}$/,
    'Cape Verde': /^\+238\d{8}$/,
    'Central African Republic': /^\+236\d{8}$/,
    'Chad': /^\+235\d{8}$/,
    'Chile': /^\+56\d{8}$/,
    'China': /^\+86\d{11}$/,
    'Colombia': /^\+57\d{10}$/,
    'Comoros': /^\+269\d{8}$/,
    'Congo': /^\+242\d{8}$/,
    'Costa Rica': /^\+506\d{8}$/,
    'Croatia': /^\+385\d{8}$/,
    'Cuba': /^\+53\d{9}$/,
    'Cyprus': /^\+357\d{8}$/,
    'Czech Republic': /^\+420\d{9}$/,
    'Denmark': /^\+45\d{8}$/,
    'Djibouti': /^\+253\d{8}$/,
    'Dominica': /^\+1\d{10}$/,
    'Dominican Republic': /^\+1\d{10}$/,
    'East Timor': /^\+670\d{8}$/,
    'Ecuador': /^\+593\d{9}$/,
    'Egypt': /^\+20\d{10}$/,
    'El Salvador': /^\+503\d{8}$/,
    'Equatorial Guinea': /^\+240\d{8}$/,
    'Eritrea': /^\+291\d{8}$/,
    'Estonia': /^\+372\d{8}$/,
    'Ethiopia': /^\+251\d{9}$/,
    'Fiji': /^\+679\d{8}$/,
    'Finland': /^\+358\d{8}$/,
    'France': /^\+33\d{9}$/,
    'Gabon': /^\+241\d{8}$/,
    'Gambia': /^\+220\d{8}$/,
    'Georgia': /^\+995\d{9}$/,
    'Germany': /^\+49\d{9}$/,
    'Ghana': /^\+233\d{9}$/,
    'Greece': /^\+30\d{10}$/,
    'Grenada': /^\+1\d{10}$/,
    'Guatemala': /^\+502\d{8}$/,
    'Guinea': /^\+224\d{8}$/,
    'Guinea-Bissau': /^\+245\d{8}$/,
    'Guyana': /^\+592\d{8}$/,
    'Haiti': /^\+509\d{8}$/,
    'Honduras': /^\+504\d{8}$/,
    'Hungary': /^\+36\d{8}$/,
    'Iceland': /^\+354\d{8}$/,
    'India': /^\+91\d{10}$/,
    'Indonesia': /^\+62\d{9}$/,
    'Iran': /^\+98\d{10}$/,
    'Iraq': /^\+964\d{10}$/,
    'Ireland': /^\+353\d{9}$/,
    'Israel': /^\+972\d{9}$/,
    'Italy': /^\+39\d{10}$/,
    'Ivory Coast': /^\+225\d{8}$/,
    'Jamaica': /^\+1\d{10}$/,
    'Japan': /^\+81\d{10}$/,
    'Jordan': /^\+962\d{9}$/,
    'Kazakhstan': /^\+7\d{10,11}$/,
    'Kenya': /^\+254\d{9}$/,
    'Kiribati': /^\+686\d{8}$/,
    'Korea, North': /^\+850\d{8}$/,
    'Korea, South': /^\+82\d{9}$/,
    'Kuwait': /^\+965\d{8}$/,
    'Kyrgyzstan': /^\+996\d{9}$/,
    'Laos': /^\+856\d{8}$/,
    'Latvia': /^\+371\d{8}$/,
    'Lebanon': /^\+961\d{8}$/,
    'Lesotho': /^\+266\d{8}$/,
    'Liberia': /^\+231\d{8}$/,
    'Libya': /^\+218\d{8}$/,
    'Liechtenstein': /^\+423\d{8}$/,
    'Lithuania': /^\+370\d{8}$/,
    'Luxembourg': /^\+352\d{8}$/,
    'Macedonia': /^\+389\d{8}$/,
    'Madagascar': /^\+261\d{8}$/,
    'Malawi': /^\+265\d{8}$/,
    'Malaysia': /^\+60\d{9}$/,
    'Maldives': /^\+960\d{8}$/,
    'Mali': /^\+223\d{8}$/,
    'Malta': /^\+356\d{8}$/,
    'Marshall Islands': /^\+692\d{8}$/,
    'Mauritania': /^\+222\d{8}$/,
    'Mauritius': /^\+230\d{8}$/,
    'Mexico': /^\+52\d{10}$/,
    'Micronesia': /^\+691\d{8}$/,
    'Moldova': /^\+373\d{8}$/,
    'Monaco': /^\+377\d{8}$/,
    'Mongolia': /^\+976\d{8}$/,
    'Montenegro': /^\+382\d{8}$/,
    'Morocco': /^\+212\d{8}$/,
    'Mozambique': /^\+258\d{8}$/,
    'Myanmar': /^\+95\d{8}$/,
    'Namibia': /^\+264\d{8}$/,
    'Nauru': /^\+674\d{8}$/,
    'Nepal': /^\+977\d{9}$/,
    'Netherlands': /^\+31\d{9}$/,
    'New Zealand': /^\+64\d{9}$/,
    'Nicaragua': /^\+505\d{8}$/,
    'Niger': /^\+227\d{8}$/,
    'Nigeria': /^\+234\d{10}$/,
    'Norway': /^\+47\d{8}$/,
    'Oman': /^\+968\d{8}$/,
    'Pakistan': /^\+92\d{9}$/,
    'Palau': /^\+680\d{7}$/,
    'Palestine': /^\+970\d{9}$/,
    'Panama': /^\+507\d{8}$/,
    'Papua New Guinea': /^\+675\d{8}$/,
    'Paraguay': /^\+595\d{9}$/,
    'Peru': /^\+51\d{9}$/,
    'Philippines': /^\+63\d{10}$/,
    'Poland': /^\+48\d{9}$/,
    'Portugal': /^\+351\d{9}$/,
    'Qatar': /^\+974\d{8}$/,
    'Romania': /^\+40\d{9}$/,
    'Russia': /^\+7\d{10,11}$/,
    'Rwanda': /^\+250\d{9}$/,
    'Saint Kitts and Nevis': /^\+1\d{10}$/,
    'Saint Lucia': /^\+1\d{10}$/,
    'Saint Vincent and the Grenadines': /^\+1\d{10}$/,
    'Samoa': /^\+685\d{8}$/,
    'San Marino': /^\+378\d{8}$/,
    'Sao Tome and Principe': /^\+239\d{8}$/,
    'Saudi Arabia': /^\+966\d{9}$/,
    'Senegal': /^\+221\d{9}$/,
    'Serbia': /^\+381\d{8}$/,
    'Seychelles': /^\+248\d{8}$/,
    'Sierra Leone': /^\+232\d{8}$/,
    'Singapore': /^\+65\d{8}$/,
    'Slovakia': /^\+421\d{8}$/,
    'Slovenia': /^\+386\d{8}$/,
    'Solomon Islands': /^\+677\d{8}$/,
    'Somalia': /^\+252\d{8}$/,
    'South Africa': /^\+27\d{9}$/,
    'Spain': /^\+34\d{9}$/,
    'Sri Lanka': /^\+94\d{9}$/,
    'Sudan': /^\+249\d{8}$/,
    'Suriname': /^\+597\d{8}$/,
    'Swaziland': /^\+268\d{8}$/,
    'Sweden': /^\+46\d{9}$/,
    'Switzerland': /^\+41\d{9}$/,
    'Syria': /^\+963\d{9}$/,
    'Taiwan': /^\+886\d{9}$/,
    'Tajikistan': /^\+992\d{9}$/,
    'Tanzania': /^\+255\d{9}$/,
    'Thailand': /^\+66\d{9}$/,
    'Togo': /^\+228\d{8}$/,
    'Tonga': /^\+676\d{8}$/,
    'Trinidad and Tobago': /^\+1\d{10}$/,
    'Tunisia': /^\+216\d{8}$/,
    'Turkey': /^\+90\d{10}$/,
    'Turkmenistan': /^\+993\d{8}$/,
    'Tuvalu': /^\+688\d{8}$/,
    'Uganda': /^\+256\d{9}$/,
    'Ukraine': /^\+380\d{9}$/,
    'United Arab Emirates': /^\+971\d{9}$/,
    'United Kingdom': /^\+44\d{10}$/,
    'United States': /^\+1\d{10}$/,
    'Uruguay': /^\+598\d{9}$/,
    'Uzbekistan': /^\+998\d{9}$/,
    'Vanuatu': /^\+678\d{8}$/,
    'Vatican City': /^\+379\d{8}$/,
    'Venezuela': /^\+58\d{10}$/,
    'Vietnam': /^\+84\d{9}$/,
    'Yemen': /^\+967\d{9}$/,
    'Zambia': /^\+260\d{9}$/,
    'Zimbabwe': /^\+263\d{9}$/,
};

export const validateMobileNumber = (country: string, mobileNumber: string): boolean => {
    const pattern = countryRegexPatterns[country];
    if (!pattern) {
        return false;
    }
    return pattern.test(mobileNumber);
};
