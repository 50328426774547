import { checkBookingTimeCondition } from "./noShowBookingCheckFunction";

const useBookingStatusText = (booking: any) => {

    if (checkBookingTimeCondition(booking.booking_for + ' | ' + booking.booking_slot, booking.business_timezone) === true && booking.status !== 0 && booking.status !== 2 && booking.status !== 3) {

        return { text: 'No Show', className: 'status-no-show' };
    } else {

        switch (booking.status) {

            case 0:
                return { text: 'Cancelled', className: 'status-cancelled' };
            case 1:
                return { text: 'New', className: 'status-new' };
            case 2:
                return { text: 'Checked In', className: 'status-checked-in' };
            case 3:
                return { text: 'Checked Out', className: 'status-checked-out' };
            default:
                return { text: 'New', className: 'status-new' };
        }
    }
};

export default useBookingStatusText;