import React from "react";
import * as Icon from 'react-feather';
import { all_routes } from "../../../core/data/routes/all_routes";
import FeatureBox from "../category/categorycard";
import LinkTags from "../linkTags/linkTags";
import en from "../../../en/en";
import { featureSectionProps } from "../../../core/models/interface";
import colors from "../../../colors/colors";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../core/data/redux/store";
import { setSearchRequestData } from "../../../core/data/redux/action";
import './style.css';

const FeatureSection = (props: featureSectionProps) => {

    const routes = all_routes;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchRequestData = useSelector((state: RootState) => state.searchRequestData);

    const featuredItemClick = (categoryId: number) => {

        dispatch(setSearchRequestData({
            ...searchRequestData,
            category: categoryId,
        }));
        navigate(routes.search);
    }

    return (
        <section className="feature-section">
            <div className="stripe-notification text-center hide">
                <p>
                    To know more about us, please visit <a href="https://safalogics.com" target="_blank" rel="noopener noreferrer">SafaLogics.com</a>
                </p>
            </div>
            <div className="container">
                <div className="section-heading">
                    <div className="row align-items-center">
                        <div className="col-md-6 aos" data-aos="fade-up">
                            <h2 className="home-heading-custom">{en.FEATURED_CATEGORIES}</h2>
                            <p>{en.WHAT_TO_FIND}</p>
                        </div>
                        <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                            <LinkTags
                                to={routes.search}
                                className="btn btn-primary btn-view"
                                style={{ backgroundColor: colors.web_primary_color }}
                            >
                                {en.VIEW_ALL}
                                <Icon.ArrowRightCircle className="standard-feather ms-2" />
                            </LinkTags>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {props?.featureBoxData.map((item: { route: string; iconSrc: string; iconAlt: string; title: string; id: number, overlaySrc: string; overlayAlt: string; }, index: React.Key | null | undefined) => (
                        <div className="col-md-6 col-lg-3" key={index} onClick={() => featuredItemClick(item.id)}>
                            <FeatureBox
                                key={index}
                                route={''}
                                iconSrc={item.iconSrc}
                                iconAlt={item.iconAlt}
                                title={item.title}
                                overlaySrc={item.overlaySrc}
                                overlayAlt={item.overlayAlt}
                            />
                        </div>
                    ))}
                </div>

            </div>
        </section>
    )
};

export default FeatureSection;