import { useEffect, useState } from "react";
import apiService from "../api/authentication-service";
import { getVendorAllBookingsProps } from "../core/models/interface";

const useGetVendorAllBookings = ({ vendor_id, upcoming_bookings, all_bookings, page, limit }: getVendorAllBookingsProps) => {
    const [error, setError] = useState<any>();
    const [bookingData, setBookingData] = useState<[] | any>([]);
    const [totalCount, settotalCount] = useState(0);
    const pagesCount = Math.ceil(totalCount / limit);
    const [showloader, setShowLoader] = useState<boolean>(true);

    useEffect(() => {

        const getVendorAllBookings = async () => {

            try {

                const response = await apiService.getVendorAllBookingsDetails({
                    vendor_id: vendor_id,
                    upcoming_bookings: upcoming_bookings,
                    all_bookings: all_bookings,
                    page: page,
                    limit: limit
                });

                console.log(response.data);

                if (response.data.status === true) {

                    setBookingData(response.data.data.data);
                    settotalCount(response.data.data.total_count);
                    setShowLoader(false)
                } else {

                    setShowLoader(false)
                }
            } catch (error) {

                console.error(error);
                setError(error);
            }
        };

        if (vendor_id) {

            getVendorAllBookings();
        }
    }, [vendor_id, upcoming_bookings, all_bookings, page, limit]);

    return { pagesCount, totalCount, bookingData, error, showloader };
};

export default useGetVendorAllBookings;