export const settings = {
    dots: false,
    autoplay: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 776,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 567,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};