import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import { RootState } from '../../../core/data/redux/store';

const useAuth = () => {
  const routes = all_routes;
  const navigateTo = useNavigate();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);
  const vendor = useSelector((state: RootState) => state.vendor);

  useEffect(() => {
    const isProviderRoute = location.pathname.startsWith('/vendors');
    const isCustomerRoute = location.pathname.startsWith('/customers');

    if (isProviderRoute) {

      // Check if it's a provider route and user is not a provider
      if (!user.user_id || !vendor.id) {
        navigateTo(routes.login);
      }
    } else if (isCustomerRoute) {

      // Check if it's a customer route and user is a vendor
      if (!user.user_id || vendor.id) {
        navigateTo(routes.login);
      }
    } else {

      // Check for general routes
      if (!user.user_id) {
        navigateTo(routes.login);
      }
    }
  }, [user.user_id, vendor.id, location.pathname, navigateTo, routes]);
};

export default useAuth;