import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import FooterOne from '../../../components/footer-one';
import HomeHeader from '../../../components/header/home-header';
import { TKN_TextareaPlaceholder, TKN_PhoneInputComponent, TKN_TextInputreqired } from '../../../components/inputfields/input';
import apiService from '../../../../api/authentication-service';
import { validateMobileNumber } from '../../../../helper/mobileNumberValidatorHelper';
import en from '../../../../en/en';
import { TKN_Danger_message, TKN_Success_message } from '../../../components/alert_messages/alert_messages';
import './style.css';
import { ButtonTextOnly_Colored } from '../../../components/buttons/button';

type ErrorMessages = {
    fullName?: string;
    email?: string;
    phone?: string;
};

const Contact_Us = () => {
    const routes = all_routes;
    const [countryDetails, setCountryDetails] = useState<string>('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState<ErrorMessages>({});
    const [successresponsemsg, setSuccessResponseMsg] = useState('');
    const [errorresponsemsg, setErrorResponseMsg] = useState('');
    const [countryName, setCountryName] = useState<string>('');
    const maxLength = 200;
    const [countryCode, setCountryCode] = useState<string>('');
    const [descriptionError, setDescriptionError] = useState('');
    const [phone, setPhone] = useState('');

    const validateForm = () => {
        const newErrors: ErrorMessages = {};
        if (!fullName) newErrors.fullName = 'Full name is required';
        if (!email) {
            newErrors.email = 'Email is required';
        }
        else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
        }

        if (phone && !validateMobileNumber(countryName, phone)) {
            newErrors.phone = 'Invalid phone number for the selected country';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePhonechange = (value: any, formattedDialCode: any, countryname: any, countrydetails: any) => {
        setPhone(value);
        setCountryCode(formattedDialCode);
        setCountryName(countryname);
        setCountryDetails(countrydetails);
    };

    const handleSubmit = () => {
        if (validateForm()) {
            const payload = {
                fullname: fullName,
                email: email,
                phonenumber: phone,
                subject: subject,
                message: message
            }

            apiService.contactUsMail(payload)
                .then((response) => {
                    const responseData = response.data as { status: boolean; message?: string };
                    if (responseData.status === true) {
                        setSuccessResponseMsg("Mail Sent successfully!");
                    } else {
                        setErrorResponseMsg(responseData.message || 'Failed to sent mail. Please try again.');
                    }
                })
                .catch(error => {
                    console.error('Error while sending email:', error);
                    setErrorResponseMsg('An error occurred while processing your request. Please try again.');
                });
        }
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        if (value.length >= maxLength) {
            setDescriptionError(en.CHAR_LIMIT);
        } else {
            setDescriptionError('');
        }
        setMessage(value);
    };

    const handleCloseSuccess_msg = () => {
        setSuccessResponseMsg('');
    }

    const handleCloseDanger_msg = () => {
        setErrorResponseMsg('');
    }
    return (
        <>
            <HomeHeader type={1} />
            <div className="bg-img">
                <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
                <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
                <ImageWithBasePath
                    src="assets/img/bg/feature-bg-03.png"
                    alt="img"
                    className="bgimg3"
                />
            </div>
            {/* Breadcrumb */}
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">Contact Us</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={routes.homeOne}>Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Contact Us
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Breadcrumb */}
            <div className="content">
                <div className="container">
                    {/* Contact Details */}
                    <div className="contact-details">
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-lg-4 d-flex">
                                <div className="contact-info flex-fill">
                                    <span>
                                        <Icon.Mail className='icon-custom' />
                                    </span>
                                    <div className="contact-data">
                                        <h4>Email Address</h4>
                                        <p>
                                            {/* <Link to="mailto:support@takeano.com" >
                                                support@takeano.com
                                            </Link> */}
                                            <a href={`mailto:mailto:support@takeano.com`} className="email-link" style={{ color: '#74788d' }}> 
                                            support@takeano.com</a>
                                        </p>
                                        <p>
                                            {/*  <Link to="mailto:sales@takeano.com">
                                                sales@takeano.com
                                            </Link> */}
                                            <a href={`mailto:sales@takeano.com`} className="email-link" style={{ color: '#74788d' }}> 
                                            sales@takeano.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-flex">
                                <div className="contact-info flex-fill">
                                    <span>
                                        <Icon.MapPin className='icon-custom' />
                                    </span>
                                    <div className="contact-data">
                                        <h4>US Office</h4>
                                        <p>SAFA Logics 6545 Market Ave. North STE 100 North Canton, OH 44721</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-flex contact-address-width">
                                <div className="contact-info flex-fill">
                                    <span>
                                        <Icon.MapPin className='icon-custom' />
                                    </span>
                                    <div className="contact-data">
                                        <h4>Indian Office</h4>
                                        <p>
                                            SAFA Software solutions 25/324, Cholomkunnu, Pattambi Rd, Kunnappally, Perinthalmanna, Malappuram-679322, Kerala, India</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Contact Details */}
                    {/* Get In Touch */}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="contact-img h-100">
                                <ImageWithBasePath
                                    src="assets/img/contact-us.jpg"
                                    className="img-fluid h-100 aspect-ratio-contact"
                                    alt="img"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h2 className='contact-heading'>Get In Touch</h2>
                            {successresponsemsg && <TKN_Success_message text={successresponsemsg} onClick={handleCloseSuccess_msg} />}
                            {errorresponsemsg && <TKN_Danger_message text={errorresponsemsg} onClick={handleCloseDanger_msg} />}
                            <p className='contact-para'>Whether you are a consumer or vendor, please contact us with any questions or assistance. Don&apos;t hesitate—we&apos;re here to help!</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">Name</label>
                                        <TKN_TextInputreqired
                                            placeholder='Full name'
                                            maxLength={100}
                                            onChange={(value: string) => setFullName(value)}
                                        />
                                        {errors.fullName && <div className="error" style={{ color: 'red', fontSize: '15px' }}>{errors.fullName}</div>}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">Email</label>
                                        <TKN_TextInputreqired
                                            placeholder='Email'
                                            maxLength={100}
                                            onChange={(value: string) => setEmail(value)}
                                        />
                                        {errors.email && <div className="error" style={{ color: 'red', fontSize: '15px' }}>{errors.email}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TKN_PhoneInputComponent onChange={handlePhonechange} required={false} />
                                        {errors.phone && <div className="error" style={{ color: 'red', fontSize: '15px' }}>{errors.phone}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">Subject</label>
                                        <TKN_TextInputreqired
                                            placeholder='Subject'
                                            maxLength={100}
                                            onChange={(value: string) => setSubject(value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="col-form-label">Message</label>
                                        <TKN_TextareaPlaceholder placeholderText="Message" value={message} descriptionInputError="" maxLength={500}
                                            onChange={handleDescriptionChange}
                                        />
                                        {descriptionError && <div className="error" style={{ color: 'red' }}>{descriptionError}</div>}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button className="btn btn-primary button-custom" type="submit"
                                        onClick={handleSubmit}>
                                        Send Message
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Get In Touch */}
                </div>
            </div>
            <FooterOne />
        </>
    );
};

export default Contact_Us;
