import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { ImageWithBasePathWithUrl, ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import apiService from '../../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonTextandIconReverse_Colored, ButtonTextOnly_Colored } from '../../../components/buttons/button';
import { PhoneInputComponent, TKN_DropdownInputEdit, TKN_InputPlaceholderEdit } from '../../../components/inputfields/input';
import { CHAR_LIMIT_ADDRESS, CHAR_LIMIT_EMAIL_WEBSITE, CHAR_LIMIT_FIRST_NAME, CHAR_LIMIT_LAST_NAME, CHAR_LIMIT_PLACE, CHAR_ZIPCODE, WEB_BASE_URL } from '../../../../constants/constants';
import { validateAddress, validateDropdown, validateEmptyValue, validateMobileNumber, validateUsername, validateZipCodeByCountry } from '../../../../helper/validate';
import { setUserDetails } from '../../../../core/data/redux/action';
import { Nullable } from 'primereact/ts-helpers';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { CountryDropdown } from 'react-country-region-selector';
import countries from 'world-countries/countries.json';
import en from '../../../../en/en';
import { Spinner } from 'react-bootstrap';
import { ChevronDown } from 'react-feather';
import './style.css'

type CountryDetailsType = {
    cca2: string;
    currency: string[];
    callingCode: string[];
    region: string;
    subregion: string;
    flag: string;
    name: string;
};

const CustomerEditProfile = () => {
    const [date, setDate] = useState<Nullable<Date>>(null);
    const customer_obj = useSelector((state: any) => state.user);
    const routes = all_routes;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Genderoptions = ['Male', 'Female', 'Other'];
    const json_value = customer_obj?.country_details
    const parsed_obj = JSON.parse(json_value);
    const Country_name = parsed_obj?.name;
    const [firstName, setFirstName] = useState(customer_obj?.first_name);
    const [lastName, setLastName] = useState(customer_obj?.last_name);
    const [gender, setGender] = useState(customer_obj?.gender);
    const [email, setEmail] = useState(customer_obj?.email);
    const [address1, setAddress1] = useState(customer_obj?.address1);
    const [address2, setAddress2] = useState(customer_obj?.address2);
    const [city, setCity] = useState(customer_obj?.city);
    const [state, setState] = useState(customer_obj?.state);
    const [zipcode, setZipCode] = useState(customer_obj?.zipcode);
    const [phone, setPhone] = useState(customer_obj?.phone);
    const [countryCode, setCountryCode] = useState(customer_obj.country_code);
    const [responseMessage, setResponseMessage] = useState('');
    const [countryDetails, setCountryDetails] = useState<CountryDetailsType | null>(null);
    const [firstnameError, setFirstNameError] = useState('');
    const [lastnameError, setLastNameError] = useState('');
    const [address1Error, setAddress1Error] = useState('');
    const [address2Error, setAddress2Error] = useState('');
    const [emailError, setEmailError] = useState('');
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [zipcodeError, setZipCodeError] = useState<string | null>(null);
    const [phoneError, setPhoneError] = useState('');
    const [genderdropdownError, setGenderDropdownError] = useState('');
    const [selectedImageUri, setSelectedImageUri] = useState<string>('');
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [savedImage, setSavedImage] = useState<string | null>(null);
    const [selectedCountry, setSelectedCountry] = useState<any>(null);
    const [selectedCountryPhone, setSelectedCountryPhone] = useState<any>(Country_name);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const defaultimg = { src: 'assets/img/profiles/avatar-01.avif' }


    useEffect(() => {
        if (customer_obj.dob) {
            setDate(new Date(customer_obj.dob));
        }
    }, [customer_obj.dob]);

    useEffect(() => {
        setFirstName(customer_obj.first_name);
        setLastName(customer_obj.last_name);
        setGender(customer_obj.gender);
        setEmail(customer_obj.email);
        setAddress1(customer_obj.address1);
        setAddress2(customer_obj.address2);
        setCity(customer_obj.city);
        setState(customer_obj.state);
        setSelectedCountry(customer_obj.country);
        setZipCode(customer_obj.zipcode);
        setPhone(customer_obj.phone);
        setCountryCode(customer_obj.country_code);
        setCountryDetails(customer_obj.country_details)
    }, [customer_obj]);

    const customer_details = () => ({
        user_id: customer_obj.user_id,
        first_name: firstName,
        last_name: lastName,
        gender: gender,
        email: email,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        country: selectedCountry,
        zipcode: zipcode,
        phone: phone,
        country_code: countryCode,
        dob: date ? date.toISOString().split('T')[0] : null,
        profilephoto: selectedImage ? selectedImage.split(',')[1] : '',
        country_details: JSON.stringify(countryDetails),
    });

    const GetCustomerProfile = () => {
        const payload = customer_details();
        apiService.updatePersonalDetails(payload).then((response) => {
            if (response.status === 200) {
                dispatch(setUserDetails(response.data.data));
                handleUpdateResponse(response);
            } else {
                console.error('Unexpected response status:', response.status);
            }
        }).catch((error) => {
            console.log("Error", error);
        })
    };

    const handleUpdateResponse = (response: any) => {
        setIsLoading(false);

        if (response?.data?.status === false) {
            console.error('Update failed:', response.data.message);
        } else if (response?.status === 200) {
            console.log('Update successful:', response.data);
        } else {
            console.error('Unexpected response status:', response.status);
        }
    };

    const handlesave = () => {
        let isValid = true;
        const MobileNumber = phone.startsWith(countryCode) ? phone.slice(countryCode.length) : phone;
        const fieldsToValidate = [

            { value: firstName, errorSetter: setFirstNameError, validationFunction: [validateEmptyValue, validateUsername], fieldName: 'First name' },
            { value: lastName, errorSetter: setLastNameError, validationFunction: [validateEmptyValue, validateUsername], fieldName: 'Last name' },
            { value: address1, errorSetter: setAddress1Error, validationFunction: [validateEmptyValue, validateAddress], fieldName: 'Address' },
            { value: selectedCountry, errorSetter: setCountryError, validationFunction: [validateEmptyValue], fieldName: 'Country' },
            { value: state, errorSetter: setStateError, validationFunction: [validateEmptyValue], fieldName: 'State' },
            { value: city, errorSetter: setCityError, validationFunction: [validateEmptyValue], fieldName: 'City' },
            { value: gender, errorSetter: setGenderDropdownError, validationFunction: [validateDropdown], fieldName: 'gender' },
        ]

        fieldsToValidate.forEach((field => {
            for (const validationFunction of field.validationFunction) {
                const validationResult = validationFunction(field.value, field.fieldName);

                if (validationResult.status === 'false') {
                    field.errorSetter(validationResult.error);
                    isValid = false;
                    break;
                }
            }
        }));

        const zipCodeValidationResult = validateZipCodeByCountry(selectedCountry, zipcode);
        if (zipCodeValidationResult.status === 'false') {
            setZipCodeError(zipCodeValidationResult.error);
            isValid = false;
        } else {
            setZipCodeError('');
        };

        const mobileNumberValidateResult = validateMobileNumber(MobileNumber, countryCode, 'Country code and phone number mismatch. Please verify.');
        if (mobileNumberValidateResult.status === 'false') {
            setPhoneError(mobileNumberValidateResult.error);
            isValid = false;
        } else {
            setPhoneError('');
        };

        if (selectedCountry != selectedCountryPhone) {
            setPhoneError('Country and phone number mismatch. Please verify.');
            isValid = false;
        }

        if (isValid) {
            setIsLoading(true);
            if (selectedImage) {
                const profileImage = selectedImage ? selectedImage.split(',')[1] : '';
                sendPhotoUpdateRequest(profileImage);
            };
            GetCustomerProfile();
            navigate(routes.customerDashboard);
        }
        else {
            console.log('Input is invalid');
        };
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        if (text.length <= CHAR_LIMIT_FIRST_NAME - 1) {
            setFirstName(text);
            setFirstNameError('');
        }
        else {
            setFirstNameError(en.CHAR_LIMIT);
        }
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        if (text.length <= CHAR_LIMIT_LAST_NAME - 1) {
            setLastName(text);
            setLastNameError('');
        }
        else {
            setLastNameError(en.CHAR_LIMIT);
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        if (text.length <= CHAR_LIMIT_EMAIL_WEBSITE - 1) {
            setEmail(text)
            setEmailError('')
        }
        else {
            setEmailError(en.CHAR_LIMIT)
        }
    };

    const handleAddress1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        if (text.length <= CHAR_LIMIT_ADDRESS - 1) {
            setAddress1(text)
            setAddress1Error('')
        }
        else {
            setAddress1Error(en.CHAR_LIMIT)
        }
    };

    const handleAddress2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        if (text.length <= CHAR_LIMIT_ADDRESS - 1) {
            setAddress2(text)
            setAddress2Error('')
        }
        else {
            setAddress2Error(en.CHAR_LIMIT)
        }
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        if (text.length <= CHAR_LIMIT_PLACE - 1) {
            setCity(text)
            setCityError('')
        }
        else {
            setCityError(en.CHAR_LIMIT)
        }
    };

    const resetResponseMessage = () => {
        setResponseMessage('');
    };

    const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        if (text.length <= CHAR_LIMIT_PLACE) {
            setState(text)
            setStateError('')
        }
        else {
            setStateError(en.CHAR_LIMIT)
        }
    };

    const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value
        if (text.length <= CHAR_ZIPCODE - 1) {
            setZipCode(text)
            setZipCodeError('')
        }
        else {
            setZipCodeError(en.CHAR_LIMIT)
        }
    };

    const sendPhotoUpdateRequest = (base64Image: string) => {
        apiService.updateProfilePhoto(profileUpdateRequest(base64Image)).then((response) => {
            const responseData = response?.data;
            if (responseData) {
                const status = responseData?.status;
                if (status === true) {
                    const profilePhoto = responseData?.data?.profilephoto;
                    if (profilePhoto) {
                        customer_obj.profilephoto = profilePhoto;
                        dispatch(setUserDetails(customer_obj));
                        setSelectedImageUri(WEB_BASE_URL + profilePhoto);

                    }
                }
            }
        }).catch((error) => {
            console.log('error', error);
        });
    };

    const profileUpdateRequest = (base64Image: string) => ({
        user_id: customer_obj.user_id,
        profilephoto: base64Image
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                const Imagedata = reader.result as string;
                setSelectedImage(Imagedata);
            };

            reader.readAsDataURL(file);
            event.target.value = '';
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        setSavedImage(null);
    };

    const handleCancelButton = () => {
        navigate(routes.customerDashboard);
    };

    const handlePhoneChange = (value: any, country: any) => {
        const dialCode = country.dialCode;
        const formattedDialCode = `+${dialCode}`;
        const CountryCode = country.countryCode;
        const countryCodeUppercase = CountryCode.toUpperCase();
        const CountryDetails = countries.find(country => country.cca2 === countryCodeUppercase);

        if (CountryDetails) {

            const transformedCountryDetails = {
                cca2: CountryDetails.cca2,
                currency: Object.keys(CountryDetails.currencies),
                callingCode: dialCode,
                region: CountryDetails.region,
                subregion: CountryDetails.subregion,
                flag: `flag-${CountryDetails.cca2.toLowerCase()}`,
                name: CountryDetails.name.common
            };

            setCountryDetails(transformedCountryDetails);
        }

        setPhone(`+${value}`);
        setCountryCode(formattedDialCode);
        setSelectedCountryPhone(country.name);
    };

    const handleCountryChange = (val: string) => {
        resetResponseMessage();
        if (!val) {
            setCountryError('Unable to select country. Please try again.');
            setSelectedCountry(null);
        } else {
            setCountryError('');
            setSelectedCountry(val);
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div>
                        <div className="widget-title">
                            <h4>Account Settings</h4>
                        </div>
                        <h6 className="user-title">Profile Picture</h6>
                        <div className="pro-picture w-100">
                            <div className="pro-img" style={{ objectFit: 'cover' }}>
                                {selectedImage ? (
                                    <img
                                        src={selectedImage}
                                        alt="Selected"
                                    />
                                ) : (

                                    <React.Fragment>
                                        {
                                            customer_obj.profilephoto ? (

                                                <React.Fragment>
                                                    <ImageWithBasePathWithUrl
                                                        src={`${WEB_BASE_URL}${customer_obj.profilephoto}`}
                                                        alt="user"
                                                    /></React.Fragment>) : (

                                                <React.Fragment>
                                                    <ImageWithBasePath
                                                        src={defaultimg.src}
                                                        className="rounded-circle"
                                                        alt="User Image"
                                                        style={{ objectFit: 'cover' }}
                                                    />
                                                </React.Fragment>)
                                        }
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="pro-info">
                                {selectedImage ? (
                                    <div className="d-flex">
                                        <div className="img-upload upload-img-custom" style={{ backgroundColor: '#0445CF' }}>
                                            <ButtonTextandIconReverse_Colored label={'Upload'} icon={'UploadCloud'} route={'#'} fullWidth={false} style={{ padding: '5px 10px' }} iconclassName='upload-icon-custom' className='upload-class-custom' />
                                            <input type="file" onChange={handleFileChange} />
                                        </div>

                                        <ButtonTextOnly_Colored label={'Remove'} route={'#'} fullWidth={false} onClick={handleRemoveImage} className='cancel-customerprofile-custom' />
                                    </div>
                                ) : (<div className="d-flex">
                                    <div className="img-upload upload-img-custom" style={{ backgroundColor: '#0445CF' }}>
                                        <ButtonTextandIconReverse_Colored label={'Upload'} icon={'UploadCloud'} route={'#'} fullWidth={false} style={{ padding: '5px 10px' }} iconclassName='upload-icon-custom' className='upload-class-custom' />
                                        <input type="file" onChange={handleFileChange} />
                                    </div>
                                </div>)}
                                <p>
                                    *image size should be at least 320px big,and less then 500kb.
                                    Allowed files .png and .jpg.
                                </p>
                            </div>
                        </div>
                        <h6 className="user-title">{en.CUSTOMER_INFO}</h6>
                        <div className="general-info">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            First Name <span className="text-danger">*</span>
                                        </label>
                                        <TKN_InputPlaceholderEdit
                                            placeholderText={'Enter your first name'}
                                            value={customer_obj.first_name}
                                            onChange={handleFirstNameChange}
                                            maxLength={CHAR_LIMIT_FIRST_NAME}
                                        />
                                        {firstnameError !== '' && (
                                            <div style={{ color: 'red' }}>{firstnameError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Last Name <span className="text-danger">*</span>
                                        </label>
                                        <TKN_InputPlaceholderEdit
                                            placeholderText={'Enter your last name'}
                                            value={customer_obj.last_name}
                                            onChange={handleLastNameChange}
                                            maxLength={CHAR_LIMIT_LAST_NAME}
                                        />
                                        {lastnameError !== '' && (
                                            <div style={{ color: 'red' }}>{lastnameError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Email <span className="text-danger">*</span>
                                        </label>
                                        <TKN_InputPlaceholderEdit
                                            placeholderText={'Enter your email'}
                                            value={customer_obj.email}
                                            readOnly={true}
                                            onChange={handleEmailChange}
                                            maxLength={CHAR_LIMIT_EMAIL_WEBSITE}
                                            style={{ backgroundColor: '#f5f5f5' }}
                                        />
                                        {emailError !== '' && (
                                            <div style={{ color: 'red' }}>{emailError}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <PhoneInputComponent
                                            handlePhoneChange={handlePhoneChange}
                                            required={true}
                                            value={phone}
                                            readOnly={false}
                                            error={phoneError}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">
                                            Gender <span className="text-danger">*</span>
                                        </label>
                                        <TKN_DropdownInputEdit
                                            placeholderText={'Choose gender'}
                                            options={Genderoptions}
                                            initialValue={customer_obj.gender}
                                            onChange={(value: any) => setGender(value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-form-label">Date of birth</label>
                                        <div className="form-icon cus-profile">
                                            <Calendar
                                                id="dob"
                                                minDate={new Date(1900, 0, 1)}
                                                maxDate={new Date()}
                                                value={date}
                                                onChange={(e) => {
                                                    const selectedDate = e.value;
                                                    if (selectedDate) {
                                                        selectedDate.setHours(12, 0, 0, 0);
                                                    }
                                                    setDate(selectedDate);
                                                }}
                                                placeholder="MM/DD/YYYY"
                                                showIcon
                                                readOnlyInput={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 className="user-title">Address</h6>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">Address 1 <span className="text-danger">*</span></label>
                                    <TKN_InputPlaceholderEdit
                                        placeholderText={'Enter your address'}
                                        value={customer_obj.address1}
                                        onChange={handleAddress1Change}
                                        maxLength={CHAR_LIMIT_ADDRESS}
                                    />
                                    {address1Error !== '' && (
                                        <div style={{ color: 'red' }}>{address1Error}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">Address 2</label>
                                    <TKN_InputPlaceholderEdit
                                        placeholderText={'Enter your address'}
                                        value={customer_obj.address2}
                                        onChange={handleAddress2Change}
                                        maxLength={CHAR_LIMIT_ADDRESS}
                                    />
                                    {address2Error !== '' && (
                                        <div style={{ color: 'red' }}>{address2Error}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">State <span className="text-danger">*</span></label>
                                    <TKN_InputPlaceholderEdit
                                        placeholderText={'Enter your state'}
                                        onChange={handleStateChange}
                                        value={customer_obj.state}
                                        maxLength={CHAR_LIMIT_PLACE}
                                    />
                                    {stateError !== '' && (
                                        <div style={{ color: 'red' }}>{stateError}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">City <span className="text-danger">*</span></label>
                                    <TKN_InputPlaceholderEdit
                                        placeholderText={'Enter your city'}
                                        value={customer_obj.city}
                                        onChange={handleCityChange}
                                        maxLength={CHAR_LIMIT_PLACE}
                                    />
                                    {cityError !== '' && (
                                        <div style={{ color: 'red' }}>{cityError}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">Postal Code <span className="text-danger">*</span></label>
                                    <TKN_InputPlaceholderEdit
                                        placeholderText={'Enter your postal code'}
                                        value={customer_obj.zipcode}
                                        onChange={handleZipCodeChange}
                                        maxLength={CHAR_ZIPCODE}
                                    />
                                    {zipcodeError !== '' && (
                                        <div style={{ color: 'red' }}>{zipcodeError}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">
                                        Country <span className="text-danger">*</span>
                                    </label>
                                    <CountryDropdown
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                        classes="form-control"
                                        defaultOptionLabel="Select country"
                                        priorityOptions={['United States']}
                                    />
                                    <ChevronDown className="dropdown-icon" style={{ marginTop: '20px' }} />
                                    {countryError !== '' && (
                                        <div className='errorText'>{countryError}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="acc-submit">
                            <ButtonTextOnly_Colored
                                label={'Cancel'}
                                route={''}
                                fullWidth={false}
                                style={{
                                    backgroundColor: '#f7f7ff',
                                    color: '#0445cf',
                                    borderColor: '#f7f7ff',
                                    marginRight: '20px'
                                }}
                                onClick={handleCancelButton}
                            />
                            <ButtonTextOnly_Colored
                                label={isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : 'Save'}
                                route={''}
                                fullWidth={false}
                                onClick={handlesave}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CustomerEditProfile;