import React from "react";
import CustomerDashBoardRecentBooking from "../customerDashboardRecentBooking/customerDashBoardRecentBooking";
import CustomerDashboardRecentFavorites from "../customerDashboardRecentFavorites/customerDashboardRecentFavorites";
import { PaginationProvider } from "../../../../context/paginationContext";

const CustomerDashboardRecentSection = () => {
    return (
        <div className="row flex-fill" >
            <PaginationProvider>
                <CustomerDashBoardRecentBooking />
            </PaginationProvider>
            <CustomerDashboardRecentFavorites />
        </div>
    )
};

export default CustomerDashboardRecentSection;