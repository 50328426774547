import React from 'react';
import * as Icon from 'react-feather';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';

const ContactUs = () => {
  const routes = all_routes;
  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Customer Support</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Customer Support
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          {/* Contact Details */}
          <div className="contact-details">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-4 d-flex">
                <div className="contact-info flex-fill">
                  <span>
                    <Icon.Phone />
                  </span>
                  <div className="contact-data">
                    <h4>Phone Number</h4>
                    <p>(888) 888-8888</p>
                    <p>(123) 456-7890</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <div className="contact-info flex-fill">
                  <span>
                    <Icon.Mail />
                  </span>
                  <div className="contact-data">
                    <h4>Email Address</h4>
                    <p>
                      <Link to="mailto:support@takeano.com">
                      support@takeano.com
                      </Link>
                    </p>
                    <p>
                      <Link to="mailto:sales@takeano.com">
                      sales@takeano.com
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <div className="contact-info flex-fill">
                  <span>
                    <Icon.MapPin />
                  </span>
                  <div className="contact-data">
                    <h4>Address</h4>
                    <p>SAFA Logics 6545 Market Ave. North STE 100 North Canton, OH 44721</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Contact Details */}
          {/* Get In Touch */}
          <div className="row">
            <div className="col-md-6">
              <div className="contact-img">
                <ImageWithBasePath
                  src="assets/img/contact-us.jpg"
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-queries">
                <h2>Get In Touch</h2>
                <form action={routes.contactUs}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Name*"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Email</label>
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Enter Email Address*"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="col-form-label">Phone Number</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Phone Number"
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Message</label>
                        <textarea
                          className="form-control"
                          rows={4}
                          placeholder="Type Message"
                          defaultValue={''}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-primary" type="submit">
                        Send Message
                        <Icon.ArrowRightCircle className="standard-feather ms-2"/>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* /Get In Touch */}
        </div>
      </div>
      {/* Map */}
      {/*
     <div className="map-grid">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509170.989457427!2d-123.80081967108484!3d37.192957227641294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1669181581381!5m2!1sen!2sin"
          style={{ border: 0 }}
           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-expect-error
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="contact-map"
        />
      </div>
       */}
      {/* /Map */}
    </>
  );
};

export default ContactUs;
