import React from "react";
import { Link } from "react-router-dom";
import { ImageWithBasePath } from "../../../core/img/ImageWithBasePath";

import RatingStar from "../ratingStar/ratingStar";
import { ReviewBoxListProps, ReviewBoxProps } from "../../../core/models/interface";


const ReviewBox = (props: ReviewBoxProps) => {
    return (
        <div className={props.reviewBoxClassName ? props.reviewBoxClassName : "client-widget aos"} data-aos="fade-up">
            <div className="client-img">
                <Link to="#">
                    <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src={props.reviewerImageSrc}
                    />
                </Link>
            </div>
            <div className={props.reviewContentClassName ? props.reviewContentClassName : "client-content"}>
                <div className="rating">
                    <RatingStar Rating={props.ratingNumber ? props.ratingNumber : 5} />
                </div>
                <p>{props.reviewContent}</p>
                <h5>{props.reviewerName}</h5>
                <h6>{props.reviewerDesignation}</h6>
            </div>
        </div>
    )
};

const ReviewBoxList = (props: ReviewBoxListProps) => {

    const ComponentTag = props.reviewBoxTag || "div";

    return (
        <React.Fragment>
            <ComponentTag className={props.reviewBoxClassName ? props.reviewBoxClassName : "review-box"} >
                <div className="review-profile">
                    <div className="review-img">
                        <ImageWithBasePath
                            src={props.reviewerImageSrc}
                            className="img-fluid"
                            alt="img"
                        />
                        <div className="review-name">
                            <h6>{props.reviewerName}</h6>
                            <p>{props.reviewDateAndTime}</p>
                        </div>
                    </div>
                    <div className="rating">
                        <RatingStar Rating={props.ratingNumber ? props.ratingNumber : 5} />
                    </div>
                </div>
                <p>{props.reviewContent}</p>
            </ComponentTag>
        </React.Fragment>
    );
};

export { ReviewBox, ReviewBoxList };