import { useEffect, useState } from "react";
import apiService from "../api/authentication-service";

const useGetVendorTotalCountDetails = (vendor_id: number) => {
    const [error, setError] = useState<any>();
    const [totalBookings, setTotalBookings] = useState();
    const [totalTodaysBookings, setTotalTodaysBookings] = useState();
    const [totalServices, setTotalServices] = useState();
    const [totalActiveServices, setTotalActiveServices] = useState();
    const [totalWalkinBookings, setTotalWalkinBookings] = useState();
    const [totalTodaysWalkinBookings, setTotalTodaysWalkinBookings] = useState();

    useEffect(() => {

        const getVendorTotalCountDetails = async () => {

            try {

                const response = await apiService.getVendorTotalCountDetails({
                    vendor_id: vendor_id
                });

                if (response.data.status === true) {

                    setTotalBookings(response.data.data.total_bookings);
                    setTotalTodaysBookings(response.data.data.total_todays_bookings);
                    setTotalServices(response.data.data.total_services);
                    setTotalActiveServices(response.data.data.total_active_services);
                    setTotalWalkinBookings(response.data.data.total_walkin_bookings);
                    setTotalTodaysWalkinBookings(response.data.data.total_todays_walkin_bookings);
                }
            } catch (error) {

                console.log(error);
                setError(error);
            }
        };

        if (vendor_id) {

            getVendorTotalCountDetails();
        }
    }, [vendor_id]);

    return { totalBookings, totalTodaysBookings, totalServices, totalActiveServices, totalWalkinBookings, totalTodaysWalkinBookings, error };
};

export default useGetVendorTotalCountDetails;