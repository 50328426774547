import { useEffect, useState } from "react";
import apiService from "../api/authentication-service";

const getUserUpcomingBookings = (user_id: number, limit: number, page: number, all_upcomings_count: number) => {
    const [upcomingBookings, setupcomingBookings] = useState([]);
    const [totalCount, settotalCount] = useState(0);
    const [error, setError] = useState<any>();
    const pagesCount = Math.ceil(totalCount / limit);

    useEffect(() => {
        const sendUpcomingBookings = async () => {
            try {

                const response = await apiService.getUserUpcomingBookingsLimited({
                    user_id: user_id,
                    limit: limit,
                    page: page,
                    all_upcomings_count: all_upcomings_count
                });

                if (response.data.status === true) {

                    settotalCount(response.data.data.totalCount);
                    setupcomingBookings(response.data.data.bookings);
                }
            } catch (error) {

                console.error(error);
                setError(error);
            }
        };

        if (user_id) {

            sendUpcomingBookings();
        }
    }, [user_id, limit, page, all_upcomings_count]);

    return { pagesCount, totalCount, upcomingBookings, error };
};

export default getUserUpcomingBookings;