
import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import apiService from '../../../../api/authentication-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../core/data/redux/store';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { TKN_DropdownInputEdit, TKN_TextInputreqired, TKN_TextareaPlaceholder, TKN_TimePickerComponent } from '../../../components/inputfields/input';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { addDays, format, parse, set } from 'date-fns';
import { validateEmptyValue, validateImageUpload, validateServiceDates, validateServicename } from '../../../../helper/validate';
import './style.css';
import dayjs from 'dayjs';
import en from '../../../../en/en';
import { useNavigate } from 'react-router-dom';
import { TKN_Danger_message, TKN_Success_message } from '../../../components/alert_messages/alert_messages';
import { TKN_successmodal, TKN_modal } from '../../../components/modal/modal';
import { ButtonTextOnly_Colored } from '../../../components/buttons/button';
import { Spinner } from 'react-bootstrap';
import { converHoursToMinutes, convertTimeFormat, getTimeDifference } from '../../../../helper/Time';
import { CHAR_LIMIT_ADDRESS, CHAR_LIMIT_LONG_DESC } from '../../../../constants/constants';

const ProviderAddService: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const routes = all_routes;
  const Days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  type FieldItems = {
    value: string;
    error1: string;
    day: string;
    error2: string;
    time1: string;
    error3: string;
    time2: string;
    error4: string;
    time3: string;
    error5: string;
    time4: string;
    error6: string;
    timeInterval: string;
    error7: string;
    availableSlots: string;
    error8: string;
    [key: string]: string;
  };

  const fieldItems: FieldItems = {
    value: '',
    error1: '',
    day: '',
    error2: '',
    time1: '09:00',
    error3: '',
    time2: '18:00',
    error4: '',
    time3: '13:00',
    error5: '',
    time4: '14:00',
    error6: '',
    timeInterval: '00:15',
    error7: '',
    availableSlots: '32',
    error8: '',
  };
  const [serviceDays, setServiceDays] = useState([fieldItems]);
  const [fieldCount, setFieldCount] = useState(1);
  const [serviceName, setServiceName] = useState('');
  const [ServicenameError, setServicenameError] = useState('');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date('9999-12-31'));
  const [serviceDateFromError, setserviceDateFromError] = useState('');
  const [serviceDateToError, setserviceDateToError] = useState('');
  const [serviceDescription, setServiceDescription] = useState('');
  const [serviceDescriptionError, setServiceDescriptionError] = useState('');
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [ImageUploadError, setImageUploadError] = useState('');
  const navigateTo = useNavigate();
  const [serverError, setServerError] = useState('');
  const [statusValue, setstatusValue] = useState(1);
  const vendor = useSelector((state: RootState) => state.vendor);
  const [showmodal, setShowModal] = useState<boolean>(false)
  const [successresponsemsg, setSuccessResponseMsg] = useState('');
  const [errorresponsemsg, setErrorResponseMsg] = useState('');
  const [successmsg, setSuccessmsg] = useState('');
  const [deleteErrorMsg, setDeleteErrorMsg] = useState('');
  const [isOpeningCalendar, setIsOpeningCalendar] = useState(false);

  const handleDuplicateField = () => {
    if (fieldCount < 7) {
      const lastDayData = serviceDays[fieldCount - 1];
      if (lastDayData) {
        const newDayData = {
          value: '',
          error1: '',
          day: '',
          error2: '',
          time1: lastDayData.time1,
          error3: '',
          time2: lastDayData.time2,
          error4: '',
          time3: lastDayData.time3,
          error5: '',
          time4: lastDayData.time4,
          error6: '',
          timeInterval: lastDayData.timeInterval,
          error7: '',
          availableSlots: lastDayData.availableSlots,
          error8: '',
        };
        setServiceDays([...serviceDays, newDayData]);
        setFieldCount(fieldCount + 1);
        setDeleteErrorMsg('');
      }
    } else {
      setDeleteErrorMsg('Week days should not be more than 7');
    }
  };


  const getAvailableDays = () => {
    const selectedDays = serviceDays.map((field) => field.day).filter(Boolean);
    return Days.filter((day) => !selectedDays.includes(day));
  };

  const handleDeleteField = async () => {

    if (serviceDays.length > 1) {

      deleteServiceDay(fieldCount - 1);
      setDeleteErrorMsg('');
    } else {
      setDeleteErrorMsg('Kindly add a minimum of one day of service.');
    }
  }

  const getDisabledDates = () => {
    const disabledDates = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    for (let i = 1; i <= 3; i++) {
      const pastDate = addDays(today, -i);
      disabledDates.push(pastDate);
    }

    return disabledDates;
  };


  const deleteServiceDay = (indexToDelete: number) => {

    const updatedServiceDays = [...serviceDays];
    updatedServiceDays.splice(indexToDelete, 1);
    setServiceDays(updatedServiceDays);
    setFieldCount((prevFieldCount) => prevFieldCount - 1);
  };

  const handleInputFieldChange = (index: number, key: string, value: any) => {
    const newDayFields = [...serviceDays];
    newDayFields[index][key] = value;
    setServiceDays(newDayFields);
  }

  const validateForm = () => {
    const newFields = [...serviceDays];
    let isFormValid = true;
    const isDayValid: boolean[] = [];
    const hours_open: any = {};

    newFields.forEach((field, index) => {

      const ErrorMap = [];

      if (!field.day) {

        field.error2 = 'Please select a day';
        ErrorMap.push(false);
      } else {

        field.error2 = '';
        ErrorMap.push(true);
      }

      if (!field.time1) {

        field.error3 = 'Please select a time';
        ErrorMap.push(false);
      } else {

        field.error3 = '';
        ErrorMap.push(true);
      }

      if (!field.time2) {

        field.error4 = 'Please select a time';
        ErrorMap.push(false);
      } else {

        if (getTimeDifference(field.time1, field.time2) <= 0) {

          field.error4 = 'Service end time should be greater than service start time';
          ErrorMap.push(false);
        } else {

          field.error4 = '';
          ErrorMap.push(true);
        }
      }

      if (field.time3) {

        if (getTimeDifference(field.time1, field.time3) < 0 || getTimeDifference(field.time2, field.time3) > 0) {

          field.error5 = 'Break time should fall between service start and end time';
          ErrorMap.push(false);
        } else {

          field.error5 = '';
          ErrorMap.push(true);
        }

        if (!field.time4) {

          field.error6 = 'Break end time is missing';
          ErrorMap.push(false);
        }
      }

      if (field.time4) {

        if (getTimeDifference(field.time1, field.time4) < 0 || getTimeDifference(field.time2, field.time4) > 0) {

          field.error6 = 'Break time should fall between service start and end time';
          ErrorMap.push(false);
        } else if (getTimeDifference(field.time3, field.time4) <= 0) {

          field.error6 = 'Break end time should be greater than Break start time';
          ErrorMap.push(false);
        } else {

          field.error6 = '';
          ErrorMap.push(true);
        }
      }

      if (!field.timeInterval) {

        field.error7 = 'Please select duration';
        ErrorMap.push(false);
      } else if (field.timeInterval === '00:00') {

        field.error7 = 'Please enter duration greater than zero minutes';
        ErrorMap.push(false);
      } else {
        const intervalMinutes = converHoursToMinutes(field.timeInterval);
        if (field.time3 && field.time4) {

          const forenoonTime = getTimeDifference(field.time1, field.time3);
          const afternoonTime = getTimeDifference(field.time4, field.time2);
          if ((forenoonTime < intervalMinutes && forenoonTime != 0) || (afternoonTime < intervalMinutes && afternoonTime != 0)) {

            field.error7 = 'The duration should fall between service start and end times, accounting for break periods.';
            ErrorMap.push(false);
          } else if (forenoonTime == 0 && afternoonTime == 0) {

            field.error8 = 'Zero slot error, modify your settings';
            ErrorMap.push(false);
          } else {

            field.error7 = '';
            field.error8 = '';
            ErrorMap.push(true);
          }
        } else {

          if (getTimeDifference(field.time1, field.time2) < intervalMinutes) {

            field.error7 = 'Duration should fall between service start and end time';
            ErrorMap.push(false);
          } else {

            field.error7 = '';
            ErrorMap.push(true);
          }
        }
      }

      if (parseInt(field.availableSlots) == 0) {

        field.error8 = 'Zero slot error, modify your settings';
        ErrorMap.push(false);
      } else {

        field.error8 = '';
        ErrorMap.push(true);
      }

      newFields[index] = field;
      if (!ErrorMap.includes(false)) {

        isDayValid.push(true);
        hours_open[field.day] = {
          day: field.day,
          open_from_time: field.time1,
          open_to_time: field.time2,
          break_from_time: field.time3,
          break_to_time: field.time4,
          interval: field.timeInterval,
        };
      } else {

        isDayValid.push(false);
      }
    });
    const outputForm = {

      hoursOpen: JSON.stringify(hours_open),
    };
    if (isDayValid.includes(false)) {

      isFormValid = false;
    }
    setServiceDays(newFields);
    return { isFormValid, outputForm };
  };
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length <= CHAR_LIMIT_LONG_DESC - 1) {
      setServiceDescription(value);
      setServiceDescriptionError('');
    } else {
      setServiceDescriptionError('Maximum char limit reached.');
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    setImageUploadError('');
  };

  const sendAddServiceRequest = (outputForm: { hoursOpen: string }) => {

    const { hoursOpen } = outputForm;
    apiService.apiAddService(addServiceRequest(hoursOpen)).then((response) => {
      const responseData = response?.data;
      addServiceResponse(responseData);
      setIsLoading(false);
    }).catch((error) => {
      console.log('Error', error);
    });
  };

  const addServiceResponse = (response: any) => {

    if (response) {
      const status = response?.status;
      if (status && status === true) {

        setShowModal(true);
        setSuccessResponseMsg(" Service Added successfully");
      } else {

        let message = response?.message;
        if (!message) {

          message = "Sorry! Something went wrong. Please try again...";
        }
        setServerError(message);
      }
    }
  };

  const getFormattedDate = (date: Date | null) => {
    return date ? format(date, 'yyyy-MM-dd') : '';
  };

  const addServiceRequest = (hoursOpen: string) => ({
    service_name: serviceName,
    service_description: serviceDescription,
    service_image: selectedFile ? selectedFile.split(',')[1] : '',
    status: statusValue,
    vendor_id: vendor.id,
    category_id: vendor.category_id,
    subcategory_id: vendor.subcategory_id,
    hours_open: hoursOpen,
    start_date: getFormattedDate(startDate),
    end_date: getFormattedDate(endDate),
  });

  const handleSubmit = () => {
    const { isFormValid, outputForm } = validateForm();
    const isServicenameValid = validateServicename(serviceName, 'Service name');
    const isServiceDescriptionValid = validateEmptyValue(serviceDescription, 'Description');
    const isImageUploadValid = validateImageUpload(selectedFile);

    if (isServicenameValid["status"] === "false") {

      setServicenameError(isServicenameValid["error"]);
    } else {
      setServicenameError('');
    }

    if (isServiceDescriptionValid["status"] === "false") {
      setServiceDescriptionError(isServiceDescriptionValid["error"]);
    }

    if (startDate) {

      const isServiceDatesValid = validateServiceDates(startDate, endDate);

      if (isServiceDatesValid["status"] === "false") {
        setserviceDateToError(isServiceDatesValid["error"]);
      }
    }

    if (isImageUploadValid["status"] === "false") {

      setImageUploadError(isImageUploadValid["error"]);
    }

    if (isFormValid) {
      setIsLoading(true);
      sendAddServiceRequest(outputForm);
    }
  };

  const handleServerErrorClose = () => {
    setErrorResponseMsg('');
  }

  useEffect(() => {

    checkAndUpdateSelectedDay();
  }, [serviceDays]);

  const checkAndUpdateSelectedDay = () => {

    try {

      const availableDayIndex: any = [];
      serviceDays.map((field: any) => {

        const day = field?.day;
        if (day) {

          availableDayIndex.push(getAvailableDayINdex(day));
        }
      });
    } catch (error) {
      console.error("ERROR : ", error);
    }
  }

  const getAvailableDayINdex = (day: string) => {

    let dayIndex = 0;
    try {

      if (day) {

        dayIndex = Days.indexOf(day);
      }
    } catch (error) {

      console.error("ERROR : ", error);
    }
    return dayIndex;
  }

  const getAvailableTimeSlots = (index: number) => {
    let totalSlots = 0;
    try {

      if (serviceDays[index]) {

        const serviceStartTime = serviceDays[index]?.time1;
        const serviceEndTime = serviceDays[index].time2;
        const breakStartTime = serviceDays[index].time3;
        const brealEndTime = serviceDays[index].time4;
        const interval = serviceDays[index].timeInterval;
        if (serviceStartTime && serviceEndTime) {

          if (breakStartTime && brealEndTime) {

            const forenoonTotalTime = getTimeDifference(serviceStartTime, breakStartTime);
            const afternoonTotalTime = getTimeDifference(brealEndTime, serviceEndTime);
            const intervalMinutes = converHoursToMinutes(interval);
            let forenoonSlots = 0;
            let afternoonSlots = 0;
            if ((!(forenoonTotalTime < 0)) && intervalMinutes) {

              forenoonSlots = Math.floor(forenoonTotalTime / intervalMinutes);
            }
            if ((!(afternoonTotalTime < 0)) && intervalMinutes) {

              afternoonSlots = Math.floor(afternoonTotalTime / intervalMinutes);
            }
            totalSlots = forenoonSlots + afternoonSlots;
          } else {

            const totalTime = getTimeDifference(serviceStartTime, serviceEndTime);
            const intervalMinutes = converHoursToMinutes(interval);
            if (totalTime && intervalMinutes) {

              totalSlots = Math.floor(totalTime / intervalMinutes);
            }
          }
          handleInputFieldChange(index, "availableSlots", totalSlots);
        }
      }
    } catch (error) {

      console.error("ERROR : ", error);
    }
  };

  const ValidationErrorMessage = ({ error }: { error: string }) => {
    return (
      <div>
        {error !== '' && (
          <div style={{ color: 'red' }}>{error}</div>
        )}
      </div>
    );
  };

  const handleCancel = () => {
    navigateTo(routes.vendorservice)
  };

  const handleCalendarShow = () => {

    if (endDate && format(endDate, 'yyyy-MM-dd') === '9999-12-31') {
      setIsOpeningCalendar(true);
    }
  };

  const handleServiceNameChange = (value: string) => {
    if (value.length <= CHAR_LIMIT_ADDRESS - 1) {
      setServiceName(value);
      setServicenameError('');
    }
  }


  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <TKN_successmodal title={"Service"} description={successresponsemsg} okbutton={'OK'} handleshowModal={showmodal} handleSuccessButton={() => navigateTo(routes.vendorservice)} />
            {errorresponsemsg && <TKN_Danger_message text={errorresponsemsg} onClick={handleServerErrorClose} />}
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h3>Add Service</h3>
              </div>
            </div>
            <div className="col-md-8">
              <div className="book-title">
                {successmsg && <div className="text-danger">{successmsg}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="provider-subtitle">
            <h6>Service Information</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 width-Servicename" style={{ width: '50%' }}>
            <div className="form-group">
              <label className="col-form-label">Service Name <span className="text-danger">*</span></label>
              <TKN_TextInputreqired
                placeholder='Service name'
                maxLength={CHAR_LIMIT_ADDRESS}
                value={serviceName}
                onChange={handleServiceNameChange}
              />
              {ServicenameError !== '' && <div style={{ color: 'red' }}>{ServicenameError}</div>}

            </div>
          </div>
          <div className="col-md-4 width-endDate" style={{ width: '25%' }}>
            <div className="form-group">
              <label className="col-form-label">Start Date <span className="text-danger">*</span></label>
              <PrimeCalendar
                value={startDate}
                onChange={(e) => setStartDate(e.value as Date)}
                placeholder="MM/DD/YYYY"
                showIcon={true}
                inputClassName="p-inputtext p-calendertextfieldnew"
                style={{ border: '1px solid #ced4da', borderRadius: '4px' }}
                disabledDates={getDisabledDates()}
                minDate={new Date()}
              />
              {serviceDateFromError && <div style={{ color: 'red' }}>{serviceDateFromError}</div>}
              <span className="position-absolute" style={{ right: '10px', top: '59px', transform: 'translateY(-50%)' }}>
                <i className="feather-calendar"></i>
              </span>
            </div>
          </div>
          <div className="col-md-4 width-endDate" style={{ width: '25%' }}>
            <div className="form-group">
              <label className="col-form-label">End Date <span className="text-danger">*</span></label>
              <PrimeCalendar
                value={isOpeningCalendar ? new Date() : endDate}
                onChange={(e) => {
                  setEndDate(e.value as Date);
                  setserviceDateToError('');
                  setIsOpeningCalendar(false);
                }}
                placeholder="MM/DD/YYYY"
                showIcon
                inputClassName="p-inputtext p-calendertextfieldnew"
                style={{ border: '1px solid #ced4da', borderRadius: '4px', borderColor: 'light_grey' }}
                disabledDates={getDisabledDates()}
                minDate={new Date()}
                onShow={handleCalendarShow}
              />
              {serviceDateToError && <div style={{ color: 'red' }}>{serviceDateToError}</div>}
              <span className="position-absolute" style={{ right: '10px', top: '59px', transform: 'translateY(-50%)' }}>
                <i className="feather-calendar"></i>
              </span>
            </div>
          </div>
        </div>

        <div className="col-md-4" style={{ width: '100%' }}>
          <div className="form-group">
            <label className="col-form-label">Description <span className="text-danger">*</span></label>
            <TKN_TextareaPlaceholder
              placeholderText="Description"
              value={serviceDescription}
              descriptionInputError=""
              maxLength={CHAR_LIMIT_LONG_DESC}
              onChange={handleDescriptionChange}
            />
            {serviceDescriptionError !== '' && (
              <div style={{ color: 'red' }}>{serviceDescriptionError}</div>
            )}
          </div>
        </div>
        <div className="col-md-4" style={{ width: '100%' }}>
          <div className="form-group">
            <label className="col-form-label">Add Service Photo<span className="text-danger">*</span></label>
          </div>
        </div>
        {selectedFile && (
          <div className="col-md-4" style={{ width: '100%', marginBottom: '15px' }}>
            <img
              src={selectedFile}
              alt="Selected Service"
              style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'cover' }}
            />
          </div>
        )}
        <div className="profile-container">
          <div className="pro-info">
            <div className="d-flex" style={{ paddingTop: '15px', marginTop: '-15px' }}>
              <div className="img-upload choosefilebutton">
                Choose File
                <input type="file" accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              {ImageUploadError !== '' && (
                <div style={{ color: 'red' }}>{ImageUploadError}</div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <h6 className="user-title" style={{ paddingTop: '28px' }}>{en.SERVICE_TIMES_DETAILS}<span className="text-danger">*</span></h6>
              {serviceDays.map((field, index) => (
                <div key={index} className="business-hours-section">
                  <div className="col-md-6" style={{ width: '49%' }}>
                    <div className="form-group">
                      <div>
                        <label className="col-form-label">Select Day<span className="text-danger">*</span></label>
                      </div>
                      <div>
                        <TKN_DropdownInputEdit
                          placeholderText={(field.day) ? field.day : en.SELECT_DAYS}
                          options={getAvailableDays()}
                          onSelect={(selectedDay) => {
                            handleInputFieldChange(index, "day", selectedDay);
                            field.error2 = '';
                          }}
                          onChange={(selectedDay) => {
                            handleInputFieldChange(index, "day", selectedDay);
                            field.error2 = '';
                          }}
                        />
                      </div>
                      <ValidationErrorMessage error={field.error2} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <TKN_TimePickerComponent
                          label="Start Time"
                          use12Hours={true}
                          value={field.time1 ? (field.time1) && convertTimeFormat(field.time1) : ''}
                          onChange={(value) => {
                            if (value) {
                              const timeIn24HrFormat = dayjs(value, 'h:mm A').format('HH:mm');
                              handleInputFieldChange(index, 'time1', timeIn24HrFormat);
                            }
                            else {
                              handleInputFieldChange(index, 'time1', null);
                            }
                            getAvailableTimeSlots(index);
                            field.error3 = '';
                            field.error4 = '';
                            field.error5 = '';
                            field.error6 = '';
                            field.error7 = '';
                            field.error8 = '';
                          }}
                          required={true}
                        />
                        <ValidationErrorMessage error={field.error3} />
                      </div>
                    </div>
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <TKN_TimePickerComponent
                          label="End Time"
                          use12Hours={true}
                          value={field.time2 ? (field.time2) && convertTimeFormat(field.time2) : ''}
                          onChange={(value) => {
                            if (value) {
                              const timeIn24HrFormat = dayjs(value, 'h:mm A').format('HH:mm');
                              handleInputFieldChange(index, 'time2', timeIn24HrFormat);
                            }
                            else {
                              handleInputFieldChange(index, 'time2', null);
                            }
                            getAvailableTimeSlots(index);
                            field.error3 = '';
                            field.error4 = '';
                            field.error5 = '';
                            field.error6 = '';
                            field.error7 = '';
                            field.error8 = '';
                          }}
                          required={true}
                        />
                        <ValidationErrorMessage error={field.error4} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <TKN_TimePickerComponent
                          label="Break Start Time"
                          use12Hours={true}
                          value={field.time3 ? (field.time3) && convertTimeFormat(field.time3) : ''}
                          onChange={(value) => {
                            if (value) {
                              const timeIn24HrFormat = dayjs(value, 'h:mm A').format('HH:mm');
                              handleInputFieldChange(index, 'time3', timeIn24HrFormat);
                            }
                            else {
                              handleInputFieldChange(index, 'time3', null);
                            }
                            getAvailableTimeSlots(index);
                            field.error5 = '';
                            field.error6 = '';
                            field.error7 = '';
                            field.error8 = '';
                          }}
                        />
                        <ValidationErrorMessage error={field.error5} />
                      </div>
                    </div>
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <TKN_TimePickerComponent
                          label="Break End Time"
                          use12Hours={true}
                          value={field.time4 ? (field.time4) && convertTimeFormat(field.time4) : ''}
                          onChange={(value) => {
                            if (value) {
                              const timeIn24HrFormat = dayjs(value, 'h:mm A').format('HH:mm');
                              handleInputFieldChange(index, 'time4', timeIn24HrFormat);
                            }
                            else {
                              handleInputFieldChange(index, 'time4', null);
                            }
                            getAvailableTimeSlots(index);
                            field.error5 = '';
                            field.error6 = '';
                            field.error7 = '';
                            field.error8 = '';
                          }}
                        />
                        <ValidationErrorMessage error={field.error6} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <TKN_TimePickerComponent
                          label="Duration"
                          use12Hours={false}
                          value={field.timeInterval}
                          onChange={(value) => {
                            handleInputFieldChange(index, 'timeInterval', value);
                            getAvailableTimeSlots(index);
                            field.error3 = '';
                            field.error4 = '';
                            field.error5 = '';
                            field.error6 = '';
                            field.error7 = '';
                            field.error8 = '';
                          }} required={true}
                        />
                        <ValidationErrorMessage error={field.error7} />
                      </div>
                    </div>
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <label className="col-form-label">Available Slots</label>
                        <input
                          style={{ height: '42px' }}
                          type="text"
                          value={`${field.availableSlots}`}
                          readOnly
                          className='availableslotclass'
                        />
                        <ValidationErrorMessage error={field.error8} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {(serviceDays.length < 7) && (
                <ButtonTextOnly_Colored
                  label={'Add Day'}
                  route={''}
                  fullWidth={false}
                  style={{ backgroundColor: '#0445cf', color: 'white', borderColor: '#f7f7ff', marginRight: '20px' }}
                  onClick={handleDuplicateField}
                />
              )}
              <ButtonTextOnly_Colored
                label={'Delete Day'}
                route={''}
                fullWidth={false}
                style={{ backgroundColor: '#0445cf', color: 'white', borderColor: '#f7f7ff', marginRight: '20px' }}
                onClick={handleDeleteField}
              />
              {deleteErrorMsg && <div style={{ color: 'red', marginTop: '10px' }}>{deleteErrorMsg}</div>}
            </div>
          </div>
        </div>
        <div className="acc-submit" style={{ paddingTop: "16px;", paddingRight: "22px;", marginBottom: "20px;" }}>
          <ButtonTextOnly_Colored
            label={"Cancel"}
            fullWidth={false}
            style={{
              color: '#0445cf',
              backgroundColor: '#f8f8f8',
              marginRight: '20px'
            }}
            onClick={handleCancel}
          />
          <ButtonTextOnly_Colored
            label={isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : "Save"}
            fullWidth={false}
            onClick={handleSubmit}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>

  );
};

export default ProviderAddService;