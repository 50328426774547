import React from 'react';
import ServicesRoutes from './services.routes';
//import FooterOne from '../../components/footer-one';
import Footer from '../../components/footer/footer';
import HomeHeader from '../../components/header/home-header';

const Services = () => {
  return (
    <>
      <HomeHeader type={1} />
      <ServicesRoutes />
      <Footer />
    </>
  );
};

export default Services;
