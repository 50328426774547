import React from 'react';
import CustomersRoutes from './customers.routes';
import CustSidebar from './common/sidebar';
import ProviderHeaderTwo from './common/header-2';

const Customers = () => {
  return (
    <>
      <ProviderHeaderTwo />
      {location?.pathname == '/customers/customer-chat' ||
        location?.pathname == '/customers/notification' ||
        location?.pathname == '/customers/invoice' ? (
        <></>
      ) : (
        <CustSidebar />
      )}
      <CustomersRoutes />
    </>
  );
};

export default Customers;
