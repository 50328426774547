import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import * as Icon from 'react-feather';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import './style.css'
import { ButtonTextOnly_Colored } from '../../../components/buttons/button';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { useNavigate } from 'react-router-dom';


const ProviderAppointmentSettings = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValue2, setSelectedValue2] = useState(null);
  const [selectedValue3, setSelectedValue3] = useState(null);
  const user_obj = useSelector((state: RootState) => state.user);
  const vendor_obj = useSelector((state: RootState) => state.vendor);
  const dispatch = useDispatch();
  const routes = all_routes;
  const navigate = useNavigate();
  const value = [{ name: '30 Min' }, { name: '40 Min' }, { name: '50 Min' }];
  const value2 = [
    { name: '30 Minutes' },
    { name: '40 Minutes' },
    { name: '50 Minutes' },
  ];
  const value3 = [
    { name: '4 Months' },
    { name: '5 Months' },
    { name: '6 Months' },
  ];

  const handletoprovideredit = () => {
    navigate(routes.vendorProfileSettings)
  }
  const handletoproviderbusinessedit = () => {
    navigate(routes.vendorBusinessProfileEdit)
  }

  const formatDate = (dateStr: any) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [Year, Month, Day] = dateStr.split('-');
    const monthName = monthNames[parseInt(Month) - 1]
    return `${monthName}-${Day}-${Year}`
  }

  const formattedDOB = user_obj.dob ? formatDate(user_obj.dob) : null;
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            {/* Appointment Settings */}
            <div className="col-md-12 mx-auto">
              <div className="appointment-sec appointment-set-two">
                <div className='row'>
                  <div className="provider-subtitle">
                    <h6>Profile Details</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="coupon-title">
                      <h4>Personal Profile</h4>
                    </div>
                    <div className="provider-info provider-detls">
                      <h2 className='para-elipses-text'>
                        {user_obj.first_name} {user_obj.last_name}
                      </h2>

                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            {/*<span>
                   <Icon.Mail className="react-feather-custom"/>
                  </span>*/}
                            <div className="provide-info w-90-new">
                              <h6>Email</h6>
                              <p className='para-elipses-text'>{user_obj.email}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            {/*<span>
                   <Icon.Phone className="react-feather-custom"/>
                  </span>*/}
                            <div className="provide-info w-90-new">
                              <h6>Phone</h6>
                              <p className='para-elipses-text'> {user_obj.phone}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            {/*<span>
                   <Icon.Globe className="react-feather-custom"/>
                  </span>*/}
                            <div className="provide-info w-90-new">
                              <h6>Gender</h6>
                              <p className='para-elipses-text'>{user_obj.gender}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Date of birth</h6>
                              <p className='para-elipses-text'>{formattedDOB}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            {/*<span>
                    <Icon.MapPin className="react-feather-custom"/>
                  </span>*/}
                            <div className="provide-info w-90-new">
                              <h6>Address</h6>
                              <p className='para-elipses-text'>{user_obj.address1},</p>
                              <p className='para-elipses-text'>{user_obj.address2}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            {/*<span>
                    <Icon.BookOpen className="react-feather-custom"/>
                  </span>*/}
                            <div className="provide-info w-90-new">
                              <h6>State</h6>
                              <p className='para-elipses-text'>{user_obj.state}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>City</h6>
                              <p className='para-elipses-text'>{user_obj.city}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Postal Code</h6>
                              <p className='para-elipses-text'>{user_obj.zipcode}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Country</h6>
                              <p className='para-elipses-text'>{user_obj.country}</p>
                            </div>
                          </div>
                        </div>
                        {/*<div className="col-lg-6 col-md-12">
                 <div className="social-icon provide-social">
                   <ul>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Instagram className="react-feather-custom" />{" "}
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Twitter className="react-feather-custom" />{" "}
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Youtube className="react-feather-custom" />
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Linkedin className="react-feather-custom"/>
                     </Link>
                    </li>
                  </ul>
                      </div>
                    </div>*/}
                        <div className="acc-submit appoint-submit">
                          {/*<Link to="#" className="btn btn-secondary">Reset</Link>*/}
                          <ButtonTextOnly_Colored
                            label={'Edit'}
                            fullWidth={false}
                            onClick={handletoprovideredit} route={''} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="coupon-title">
                      <h4>Business Profile</h4>
                    </div>
                    <div className="provider-info provider-detls">
                      <h2 className='para-elipses-text'>
                        {vendor_obj.bussiness_name}
                      </h2>

                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            {/*<span>
                   <Icon.Mail className="react-feather-custom"/>
                  </span>*/}
                            <div className="provide-info w-90-new">
                              <h6>Email</h6>
                              <p className='para-elipses-text'>{vendor_obj.vendor_email}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            {/*<span>
                   <Icon.Phone className="react-feather-custom"/>
                  </span>*/}
                            <div className="provide-info w-90-new">
                              <h6>Phone</h6>
                              <p className='para-elipses-text'>{vendor_obj.mobile}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            {/*<span>
                   <Icon.Globe className="react-feather-custom"/>
                  </span>*/}
                            <div className="provide-info w-90-new">
                              <h6>Owner Name </h6>
                              <p className='para-elipses-text'>{vendor_obj.owner_name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Website</h6>
                              <p className='para-elipses-text'>{vendor_obj.website}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            {/*<span>
                    <Icon.MapPin className="react-feather-custom"/>
                  </span>*/}
                            <div className="provide-info w-90-new">
                              <h6>Business Address</h6>
                              <p className='para-elipses-text'>{vendor_obj.bussiness_address}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            {/*<span>
                    <Icon.BookOpen className="react-feather-custom"/>
                  </span>*/}
                            <div className="provide-info w-90-new">
                              <h6>State</h6>
                              <p className='para-elipses-text'>{vendor_obj.State}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>City</h6>
                              <p className='para-elipses-text'>{vendor_obj.city}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Postal Code</h6>
                              <p className='para-elipses-text'>{vendor_obj.Zipcode}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Country</h6>
                              <p className='para-elipses-text'>{vendor_obj.country}</p>
                            </div>
                          </div>
                        </div>
                        {/*<div className="col-lg-6 col-md-12">
                 <div className="social-icon provide-social">
                   <ul>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Instagram className="react-feather-custom" />{" "}
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Twitter className="react-feather-custom" />{" "}
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Youtube className="react-feather-custom" />
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Linkedin className="react-feather-custom"/>
                     </Link>
                    </li>
                  </ul>
                      </div>
                    </div>*/}
                        <div className="acc-submit appoint-submit">
                          {/*<Link to="#" className="btn btn-secondary">Reset</Link>*/}
                          <ButtonTextOnly_Colored
                            label={'Edit'}
                            route={''}
                            onClick={handletoproviderbusinessedit}
                            fullWidth={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="appointment-setting-list">
                      <div className="appoint-wrapper">
                        <h5>Appointment Time Intervals</h5>
                        <p>Set the appointment time intervals</p>
                      </div>
                      <div className="form-group">
                      <Dropdown
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.value)}
                        options={value}
                        optionLabel="name"
                        placeholder="30 Min"
                        className="select provider-select"
                      />
                      </div>
                    </div>*/}
                {/*<div className="appointment-setting-list">
                      <div className="appoint-wrapper">
                        <h5>Allow Multiple Booking for Same Time Slot</h5>
                        <p>Set the appointment time intervals</p>
                      </div>
                      <div className="status-toggle grey-tog d-flex align-items-center text-dark fw-500">
                        <input type="checkbox" id="status" className="check" defaultChecked />
                        <label htmlFor="status" className="checktoggle">checkbox</label>
                      </div>
                    </div>*/}
                {/*<div className="appointment-setting-list">
                      <div className="appoint-wrapper">
                        <h5>Appointment Auto Confirm</h5>
                        <p>Enable/disable appointment auto confirm</p>
                      </div>
                      <div className="status-toggle grey-tog d-flex align-items-center text-dark fw-500">
                        <input type="checkbox" id="status-one" className="check" defaultChecked />
                        <label htmlFor="status-one" className="checktoggle">checkbox</label>
                      </div>
                    </div>*/}
                {/*<div className="appointment-setting-list">
                      <div className="appoint-wrapper">
                        <h5>Minimum Advance Booking Time</h5>
                        <p>Minimum advance booking time (1 hours to 10 days)</p>
                      </div>
                      <div className="form-group">
                      <Dropdown
                        value={selectedValue2}
                        onChange={(e) => setSelectedValue2(e.value)}
                        options={value2}
                        optionLabel="name"
                        placeholder="30 Minutes"
                        className="select provider-select"
                      />
                      </div>
                    </div>*/}
                {/*<div className="appointment-setting-list">
                      <div className="appoint-wrapper">
                        <h5>Maximum Advance Booking Time</h5>
                        <p>Set the maximum advance booking time (1 Month to 5 years)</p>
                      </div>
                      <div className="form-group">
                      <Dropdown
                        value={selectedValue3}
                        onChange={(e) => setSelectedValue3(e.value)}
                        options={value3}
                        optionLabel="name"
                        placeholder="4 Months"
                        className="select provider-select"
                      />
                      </div>
                    </div>*/}
              </div>
            </div>
            {/* /Appointment Settings */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderAppointmentSettings;
