import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import Footer from '../../../components/footer/footer';
import PagesAuthHeader from './common/header';
import { Nullable } from 'primereact/ts-helpers';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { TKN_DropdownInputvalue, TKN_TextInputreqired, TKN_TextInput, PhoneInputComponent, TKN_InputPlaceholderEdit } from '../../../components/inputfields/input';
import { CountryDropdown } from "react-country-region-selector";
import { validateMobileNumber, validateZipCodeByCountry } from "../../../../helper/validate";
import apiService from '../../../../api/authentication-service';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux'
import { setUserRole, setVendorRole } from '../../../../core/data/redux/action';
import en from '../../../../en/en';
import { ButtonTextandIconReverse_Colored, ButtonTextOnly_Colored } from '../../../components/buttons/button';
import { dateFormatFunction } from '../../../../helper/Date';
import { format } from 'date-fns';
import { CHAR_LIMIT_EMAIL_WEBSITE } from '../../../../constants/constants';
import countries from 'world-countries/countries.json';
import { ChevronDown } from 'react-feather';
import './style/addprofilestyle.css'
import { TKN_modal, TKN_successmodal } from '../../../components/modal/modal';

type ErrorMessages = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  gender?: string;
  address1?: string;
  address2?: string;
  state?: string;
  city?: string;
  postcode?: string;
  selectedCountry?: string;
};

type Option = {
  label: string;
  value: number;
};

const UserProfile = () => {
  const routes = all_routes;
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const userLoginDetails = useSelector((state: RootState) => state.userEmailAndPhone);

  const [errors, setErrors] = useState<ErrorMessages>({});
  const [error, setError] = useState('');
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [selectedbirthDate, setSelectedbirthDate] = useState<any>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [genderoption, setGenderoption] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<any>(userLoginDetails.countryCodeDetails.name);
  const [responseMessage, setResponseMessage] = useState('');
  const [countryError, setCountryError] = useState('');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState(userLoginDetails.phone);
  const [email, setEmail] = useState('');
  const [countryDetails, setCountryDetails] = useState<any>(null);
  const [countryCode, setCountryCode] = useState('');
  const [selectedCountryPhone, setSelectedCountryPhone] = useState<any>(userLoginDetails.countryCodeDetails.name);
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const userEmail = useSelector((state: RootState) => state.useremail);
  const gender: Option[] = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Other', value: 3 }
  ];

  const resetResponseMessage = () => {
    setResponseMessage('');
  };

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: undefined }));
    }
  };

  const handleLastNameChange = (value: string) => {
    setLastName(value);
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: undefined }));
    }
  };

  const handleAddressChange = (value: string) => {
    setAddress1(value);
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, address1: undefined }));
    }
  };

  const handleAddress2Change = (value: string) => {
    setAddress2(value);
  };

  const handleStateChange = (value: string) => {
    setState(value);
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, state: undefined }));
    }
  };

  const handleCityChange = (value: string) => {
    setCity(value);
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, city: undefined }));
    }
  };

  const handlePostalcodeChange = (value: string) => {
    setPostcode(value);
    const zipCodeValidationResult = validateZipCodeByCountry(selectedCountry, value);
    if (value.trim() !== '' && zipCodeValidationResult.status === 'true') {
      setErrors((prevErrors) => ({ ...prevErrors, postcode: undefined }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, postcode: zipCodeValidationResult.error }));
    }
  };

  const handleSelectgender = (selectedGender: Option) => {
    setGenderoption(selectedGender.label);
    if (selectedGender.label) {
      setErrors((prevErrors) => ({ ...prevErrors, gender: undefined }));
    }
  };

  const handleCountryChange = (val: string) => {
    resetResponseMessage();

    if (!val) {
      setCountryError('Unable to select country. Please try again.');
      setSelectedCountry(null);
    } else {
      setCountryError('');
      setSelectedCountry(val);
      setErrors((prevErrors) => ({ ...prevErrors, selectedCountry: undefined }));
    };
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const Imagedata = reader.result as string;
        setSelectedImage(Imagedata);
      };

      reader.readAsDataURL(file);
      event.target.value = '';
    }
  };

  const handleSubmit = () => {
    const newErrors: ErrorMessages = {};
    const MobileNumber = phone.startsWith(countryCode) ? phone.slice(countryCode.length) : phone;

    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!genderoption) newErrors.gender = 'Gender is required';
    if (!address1) newErrors.address1 = 'Address is required';
    if (!state) newErrors.state = 'State is required';
    if (!city) newErrors.city = 'City is required';

    if (!postcode) {
      newErrors.postcode = 'Zip code is required';
    } else {
      const zipCodeValidationResult = validateZipCodeByCountry(selectedCountry, postcode);

      if (zipCodeValidationResult.status === 'false') {
        newErrors.postcode = zipCodeValidationResult.error;
      }
    };

    if (!selectedCountry) newErrors.selectedCountry = 'Country is required';

    const mobileNumberValidateResult = validateMobileNumber(MobileNumber, countryCode, 'Country code and phone number mismatch. Please verify.');
    if (mobileNumberValidateResult.status === 'false') {
      newErrors.phone = mobileNumberValidateResult.error;
    }

    if (selectedCountry != selectedCountryPhone && selectedCountry && selectedCountryPhone) {
      newErrors.phone = 'Country and phone number mismatch. Please verify.';
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      sendProfileRequest();
    };
  };

  const sendProfileRequest = () => {
    const payload = profileRequest();

    apiService.addProfile(payload)
      .then((response: any) => {
        getProfileResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching User details', error);
        setError('Failed to fetch user details');
      });
  };

  const profileRequest = () => ({
    user_id: userEmail.userid,
    email: userEmail.email?.toLowerCase(),
    first_name: firstName,
    last_name: lastName,
    gender: genderoption,
    dob: birthDate ? format(birthDate, 'yyyy-MM-dd') : null,
    address1: address1,
    address2: address2,
    country: selectedCountry,
    state: state,
    city: city,
    zipcode: postcode,
    profile_verified: 1,
    profile_photo: selectedImage ? selectedImage.split(',')[1] : '',
  });

  const getProfileResponse = (response: any) => {
    setIsLoading(false);

    if (response) {
      const status = response?.status;

      if (status === true) {
        const user_role = response?.data?.userrole;
        const U_id = response?.data?.id;

        const userRoleDetails = {
          userrole: user_role,
          uid: U_id
        };

        if (user_role === 2) {
          localStorage.removeItem('UserEmail');
          localStorage.removeItem('userRole');
          setModalTitle(en.ACCNT_CREATION_SUCCESS);
          setModalMessage(en.ACCNT_CREATION_SUCCESS_MESSAGE);
          setShowModal(true);
        } else {
          dispatch(setVendorRole(userRoleDetails));
          navigateTo(routes.vendorBusinessProfile);
        }
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigateTo(routes.login);
  };

  const handleCancelButton = () => {
    navigateTo(-1)
  };
  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  const handlePhoneChange = (value: any, country: any) => {
    const dialCode = country.dialCode;
    const formattedDialCode = `+${dialCode}`;
    const CountryCode = country.countryCode;
    const countryCodeUppercase = CountryCode.toUpperCase();
    const CountryDetails = countries.find(country => country.cca2 === countryCodeUppercase);

    if (CountryDetails) {

      const transformedCountryDetails = {
        cca2: CountryDetails.cca2,
        currency: Object.keys(CountryDetails.currencies),
        callingCode: dialCode,
        region: CountryDetails.region,
        subregion: CountryDetails.subregion,
        flag: `flag-${CountryDetails.cca2.toLowerCase()}`,
        name: CountryDetails.name.common
      };

      setCountryDetails(transformedCountryDetails);
    }

    setPhone(`+${value}`);
    setCountryCode(formattedDialCode);
    setSelectedCountryPhone(country.name);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value
    if (text.length <= CHAR_LIMIT_EMAIL_WEBSITE - 1) {
      setEmail(text)
      setEmailError('')
    }
    else {
      setEmailError(en.CHAR_LIMIT)
    }
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="widget-title">
                <h4>Add Profile</h4>
              </div>
              <h6 className="user-title">Profile Picture</h6>
              <div className="pro-picture w-100">
                <div className="pro-img">
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{ objectFit: 'cover' }}
                    />
                  ) : (
                    <ImageWithBasePath
                      src='assets/img/profiles/avatar-01.avif'
                      alt="user"
                      style={{ objectFit: 'cover' }}
                    />
                  )}
                </div>
                <div className="pro-info">
                  {selectedImage ? (
                    <div className="d-flex">
                      <div className="img-upload btn-upload-custom upload-img-custom">
                        <ButtonTextandIconReverse_Colored
                          label={'Upload'}
                          icon={'UploadCloud'}
                          route={'#'}
                          fullWidth={false}
                          style={{ padding: '5px 10px' }}
                          iconclassName='upload-icon-custom'
                          className='upload-class-custom'
                        />
                        <input type="file" accept="image/*" onChange={handleFileChange} />
                      </div>
                      <ButtonTextOnly_Colored
                        label={'Remove'}
                        route={'#'}
                        fullWidth={false}
                        className='btn-remove-custom profileadd-remove-custom'
                        onClick={handleRemoveImage}
                      />
                    </div>
                  ) : (
                    <div className="d-flex">
                      <div className="img-upload btn-upload-custom">
                        <ButtonTextandIconReverse_Colored
                          label={'Upload'}
                          icon={'UploadCloud'}
                          route={'#'}
                          fullWidth={false}
                          style={{ padding: '5px 10px' }}
                          iconclassName='upload-icon-custom'
                          className='upload-class-custom'
                        />
                        <input type="file" accept="image/*" onChange={handleFileChange} />
                      </div>
                    </div>
                  )}
                  <p>
                    *image size should be at least 320px big,and less then 500kb.
                    Allowed files .png and .jpg.
                  </p>
                </div>
              </div>
              <h6 className="user-title">{en.CUSTOMER_INFO}</h6>
              <div className="general-info">
                <div className="row">
                  <div className="col-md-6">
                    <TKN_TextInputreqired onChange={handleFirstNameChange} label="First Name" placeholder='Enter first name' />
                    {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                  </div>
                  <div className="col-md-6">
                    <TKN_TextInputreqired onChange={handleLastNameChange} label="Last Name" placeholder='Enter last name' />
                    {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <TKN_InputPlaceholderEdit
                        placeholderText={'Enter your email'}
                        value={userLoginDetails.email}
                        readOnly={true}
                        onChange={handleEmailChange}
                        maxLength={CHAR_LIMIT_EMAIL_WEBSITE}
                        style={{ backgroundColor: '#f5f5f5' }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <PhoneInputComponent
                        handlePhoneChange={handlePhoneChange}
                        required={true}
                        value={phone}
                        readOnly={true}
                        error={errors.phone}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Gender<span className="text-danger">*</span></label>
                      <TKN_DropdownInputvalue
                        placeholderText="Select gender"
                        options={gender}
                        onSelect={handleSelectgender}
                        descriptionError={errors.gender}
                      />
                      {errors.gender && <div className="text-danger">{errors.gender}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Date of birth</label>
                      <div className="form-icon cus-profile">
                        <PrimeCalendar
                          value={birthDate}
                          minDate={new Date(1900, 0, 1)}
                          maxDate={new Date()}
                          onChange={(e) => {
                            if (e.value) {
                              const date = new Date(e.value);
                              setBirthDate(date);
                            }
                          }}
                          placeholder="MM/DD/YYYY"
                          showIcon
                          inputClassName="p-inputtext"
                          readOnlyInput={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h6 className="user-title">Address</h6>
                <div className="general-info">
                  <div className="row">
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handleAddressChange} label="Address 1" placeholder='Enter your address' />
                      {errors.address1 && <div className="text-danger">{errors.address1}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInput onChange={handleAddress2Change} label="Address 2" placeholder='Enter your address' />
                      {errors.address2 && <div className="text-danger">{errors.address2}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handleStateChange} label="State" placeholder='Enter your state' />
                      {errors.state && <div className="text-danger">{errors.state}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handleCityChange} label="City" placeholder='Enter your city' />
                      {errors.city && <div className="text-danger">{errors.city}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handlePostalcodeChange} label="Zip Code" placeholder='Enter postal code' />
                      {errors.postcode && <div className="text-danger">{errors.postcode}</div>}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Country<span className="text-danger">*</span></label>
                        <CountryDropdown
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          classes="d- flex form-control"
                          defaultOptionLabel="Select country"
                          priorityOptions={['United States']}
                        />
                        <ChevronDown className="dropdown-icon" style={{ marginTop: '20px' }} />
                        {errors.selectedCountry && <div className="text-danger">{errors.selectedCountry}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="acc-submit">
                <ButtonTextOnly_Colored
                  label={"Cancel"}
                  fullWidth={false}
                  onClick={handleCancelButton}
                  className='btn-cancel-custom'
                />
                <ButtonTextOnly_Colored
                  label={isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : "Save"}
                  fullWidth={false}
                  className='btn-save-custom'
                  disabled={isLoading}
                  onClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        {showModal && (<TKN_successmodal title={modalTitle} description={modalMessage} okbutton={'OK'} handleshowModal={showModal} handleSuccessButton={handleCloseModal} />)}
      </div>
      <Footer />
    </>
  );
};

export default UserProfile;
