import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { ImageWithBasePathWithUrl } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import Slider from 'react-slick';
import apiService from '../../../../api/authentication-service';
import { WEB_BASE_URL } from '../../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import en from '../../../../en/en';
import colors from '../../../../colors/colors';
import Aos from 'aos';
import { RootState } from '../../../../core/data/redux/store';
import Loader from '../../loader_spinner/loader_spinner';
import { setvendorId } from '../../../../core/data/redux/action';

interface RelatedBusinessProps {
    vid: number;
    category: any;
    subcategory: any;
}

const RelatedBusiness: React.FC<RelatedBusinessProps> = ({ vid, category, subcategory }) => {
    const dispatch = useDispatch();
    const routes = all_routes;
    const navigate = useNavigate();
    const searchRequestData = useSelector((state: RootState) => state.searchRequestData);
    const userLocationDetails = useSelector((state: RootState) => state.userLocationDetails);
    const [searchLatitude, setSearchLatitude] = useState(searchRequestData.latitude ?? userLocationDetails.latitude ?? 0);
    const [searchLongitude, setSearchLongitude] = useState(searchRequestData.longitude ?? userLocationDetails.longitude ?? 0);

    const [businessDetails, setBusinessDetails] = useState<any[]>([]);
    const vendorOrNot = useSelector((state: any) => state.vendor.vendor_id);
    const [showloader, setShowLoader] = useState<boolean>(true);

    useEffect(() => {
        Aos.init();
        Aos.refresh();
    }, [businessDetails]);

    const handleBookNow = (vendor_id: number) => {
        dispatch(setvendorId(vendor_id));
        navigate(routes.businessDetails);
        window.scrollTo(0, 0);
        fetchRelatedBusiness(searchLatitude, searchLongitude, category, subcategory, vendor_id);
    };

    useEffect(() => {

        fetchRelatedBusiness(searchLatitude, searchLongitude, category, subcategory, vid);
    }, []);

    const fetchRelatedBusiness = (latitude?: number, longitude?: number, category?: any, subcategory?: any, vid?: number) => {

        const category_id = category;
        const subcategory_id = subcategory;
        const payload = { latitude, longitude, category_id, subcategory_id, vendor_id: vid };

        apiService.getRelatedBusiness(payload)
            .then((response: any) => {

                if (response?.data?.status === true) {

                    setBusinessDetails(response.data.data || []);
                    setShowLoader(false);
                } else {

                    console.error('Error fetching business details:', response?.data?.message);
                    setBusinessDetails([]);
                    setShowLoader(false);
                }
            })
            .catch((error: any) => {
                console.error('Error fetching business details:', error);
                setBusinessDetails([]);
            });
    };

    const sliderSettings = {
        dots: false,
        autoplay: false,
        slidesToShow: 2,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 776,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 567,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <React.Fragment>
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="service-wrap">
                        <h5>Business near you</h5>
                    </div>
                </div>
                <div className="col-md-6 text-md-end">
                    <div className="owl-nav mynav" />
                </div>
            </div>
            {showloader ? (

                <Loader showloader={showloader} />
            ) : (
                <React.Fragment>
                    {businessDetails && businessDetails.length > 0 ? (
                        <Slider {...sliderSettings} className="related-slider">
                            {businessDetails.map((business, index) => (
                                <div className="service-widget aos relatedbusiness-width-custom" data-aos="fade-up" key={index}>
                                    <div className="service-img">
                                        <ImageWithBasePathWithUrl
                                            className="img-fluid serv-img slider-img"
                                            alt="Service Image"
                                            src={`${WEB_BASE_URL}${business.temp_name}`}
                                        />
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title">
                                            {business.bussiness_name}
                                        </h3>
                                        <p>
                                            {' '}
                                            <Icon.MapPin className="standard-feather" />
                                            {en.LOCATION}
                                            <span className="rate">
                                                <i className="fas fa-star filled" />
                                                {en.RATE}
                                            </span>
                                        </p>
                                        <div className="serv-info">
                                            <button
                                                className="btn btn-book"
                                                style={{ color: colors.web_primary_color, width: '400%', borderRadius: '5px', height: '40px' }}
                                                onClick={() => {
                                                    {
                                                        vendorOrNot === 0 && handleBookNow(business.id)
                                                    }
                                                }}
                                            >
                                                {en.BOOK_NOW}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <div className="col-md-12">
                            <div className="popular-slider" style={{ textAlign: 'center' }}>
                                <h1 style={{ color: '#adb5bd', fontSize: '22px', marginTop: '30px', marginBottom: '30px' }}>No Related Services</h1>
                            </div>
                        </div>)}

                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default RelatedBusiness;
