import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import * as Icon from 'react-feather';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import ServiceNameSection from '../../../components/serviceNameSection/serviceNameSection';
import ProvideInfoBox from '../../../components/provideInfoBox/provideInfoBox';
import TimeSlot from '../../../components/timeSlot/timeSlot';

const Booking1 = () => {
  const routes = all_routes;
  const [value, onChange] = useState(new Date());
  const [selectedCity, setselectedCity] = useState(null);
  const [selectedState, setselectedState] = useState(null);
  const [selectedCountry, setselectedCountry] = useState(null);

  const city = [
    { name: 'Select City' },
    { name: 'Tornoto' },
    { name: 'Texas' },
  ];
  const state = [
    { name: 'Select State' },
    { name: 'Tornoto' },
    { name: 'Texas' },
  ];
  const country = [{ name: 'Select Country' }, { name: 'US' }, { name: 'UK' }];

  const availableSlots = ["10:00", "11:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"]

  const handleDateChange = (newValue: Date) => {
    onChange(newValue);
  };

  return (
    <div>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Booking */}
            <div className="col-lg-10 mx-auto">
              {/* Booking Step */}
              <ul className="step-register row">
                <li className="active col-md-4">
                  <div className="multi-step-icon">
                    <ImageWithBasePath src="assets/img/icons/calendar-icon.svg" alt="img" />
                  </div>
                  <div className="multi-step-info">
                    <h6>Appointment</h6>
                    <p>Choose time &amp; date for the service</p>
                  </div>
                </li>
                <li className="col-md-4">
                  <div className="multi-step-icon">
                    <ImageWithBasePath src="assets/img/icons/wallet-icon.svg" alt="img" />
                  </div>
                  <div className="multi-step-info">
                    <h6>Payment</h6>
                    <p>Select Payment Gateway</p>
                  </div>
                </li>
                <li className="col-md-4">
                  <div className="multi-step-icon">
                    <ImageWithBasePath src="assets/img/icons/book-done.svg" alt="img" />
                  </div>
                  <div className="multi-step-info">
                    <h6>Done </h6>
                    <p>Completion of Booking</p>
                  </div>
                </li>
              </ul>
              {/* /Booking Step */}
              {/* Appointment */}
              <div className="booking-service">
                <div className="row align-items-center">
                  <ServiceNameSection
                    vendorServiceName={'Car Wash'}
                    vendorBusinessName={'Car Repair Services'}
                    vendorName={'Thomas Herzberg'}
                    serviceImageSrc={'assets/img/booking.jpg'}
                    vendorImageSrc={'assets/img/profiles/avatar-01.jpg'}
                  />
                  <div className="col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-md-7 col-sm-6">
                        <ProvideInfoBox icon={Icon.Phone} iconName="Phone" info="+1 888 888 8888" />
                        <ProvideInfoBox icon={Icon.Mail} iconName="Email" info="thomasherzberg@example.com" />
                      </div>
                      <div className="col-md-5 col-sm-6">
                        <ProvideInfoBox icon={Icon.MapPin} iconName="Address" info="Hanover, Maryland" />
                        <ProvideInfoBox icon={'assets/img/icons/service-icon.svg'} iconName="Website" info="www.cult.fit" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="book-form">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="col-form-label">City</label>
                      <Dropdown
                        value={selectedCity}
                        onChange={(e) => setselectedCity(e.value)}
                        options={city}
                        optionLabel="name"
                        placeholder="Select City"
                        className="select w-100"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="col-form-label">State</label>
                      <Dropdown
                        value={selectedState}
                        onChange={(e) => setselectedState(e.value)}
                        options={state}
                        optionLabel="name"
                        placeholder="Select State"
                        className="select w-100"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="col-form-label">Country</label>
                      <Dropdown
                        value={selectedCountry}
                        onChange={(e) => setselectedCountry(e.value)}
                        options={country}
                        optionLabel="name"
                        placeholder="Select Country"
                        className="select w-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Appointment */}
              {/* Appointment Date & Time */}
              <div className="row">
                <div className="col-lg-4">
                  <div className="book-title">
                    <h5>Appointment Date</h5>
                  </div>
                  <div id="datetimepickershow">
                    <Calendar
                      onChange={(value: any) => handleDateChange(value)}
                      value={value}
                      // className={"react-calendar-style"}
                      // prev2Label={null}
                      // next2Label={null}
                    />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="book-title">
                        <h5>Appointment Time</h5>
                      </div>
                    </div>
                  </div>
                  <div className="token-slot mt-2">
                    <TimeSlot availableSlots={availableSlots} />
                  </div>
                  <div className="book-submit text-end">
                    <Link to="#" className="btn btn-secondary">
                      Cancel
                    </Link>
                    <Link to={routes.bookingPayment} className="btn btn-primary">
                      Book Appointment
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Appointment Date & Time */}
            </div>
            {/* /Booking */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking1;
