import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import './style.css'
import { TKN_PasswordInput } from '../../../components/inputfields/input';
import apiService from '../../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../../core/data/redux/store';
import { logout } from '../../../../core/data/redux/action';
import { validateOldPassword_NewPassword_ConfirmPassword } from '../../../../../src/helper/validate';
import en from '../../../../en/en';
import { Spinner } from 'react-bootstrap';

const ProviderResetPassword = () => {
    const routes = all_routes;
    const [oldpassword, setOldPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [serverError, setServerError] = useState('');
    const [passwordResponce, setPasswordResponce] = useState({
        passwordResponceText: '',
        passwordResponceKey: '',
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch: AppDispatch = useDispatch();
    const navigateTo = useNavigate();

    const minLength = 8;
    const maxLength = 15;

    const resetErrors = () => {
        setOldPasswordError('');
        setPasswordError('');
        setConfirmPasswordError('');
        setServerError('');
    };

    const handleSubmit = () => {
        resetErrors();
        let isValid = true;
        const ispasswordValid = validateOldPassword_NewPassword_ConfirmPassword(oldpassword, password, confirmPassword);

        if (ispasswordValid["status"] === "false") {

            setOldPasswordError(ispasswordValid["oldPasswordError"]);
            setPasswordError(ispasswordValid['newPasswordError']);
            setConfirmPasswordError(ispasswordValid["confirmPasswordError"]);
            isValid = false;
        };

        if (oldpassword && password && oldpassword === password) {
            setServerError("New password cannot be the same as the old password.");
            isValid = false;
        }

        if (isValid) {
            setIsLoading(true);
            setServerError('');
            sendchangeOldPasswordRequest();
        }
    };

    const sendchangeOldPasswordRequest = () => {
        apiService.changeOldPassword(changeOldPasswordRequest()).then((response) => {
            changeOldPasswordResponse(response?.data);
            console.log('RESPONSE', response);
        }).catch((error) => {
            console.log('error', error);
        });
    }

    const userId = useSelector((state: any) => {
        return state.user.user_id
    });

    const changeOldPasswordRequest = () => ({
        old_password: oldpassword,
        new_password: password,
        confirm_password: confirmPassword,
        user_id: userId
    });

    const handleLogout = () => {
        dispatch(logout());
        // Optional: clear local storage or any other storage
        localStorage.removeItem('UserDetails');
        localStorage.removeItem('VendorDetails');
        // Redirect to login or home page
        navigateTo('../authentication/login');

    };

    const changeOldPasswordResponse = (response: any) => {
        setIsLoading(false);
        
        if (response) {
            const status = response?.status;
            if (status && status === true) {

                try {
                    handleLogout();
                } catch (error) {

                    console.error('Error resetting password:', error);
                }
            } else {
                if (response?.message) {
                    setServerError(response.message);
                } else {
                    setServerError('Unable to reset your password. Please try again.');
                }
            }

        } else {

            setServerError("Old password is wrong...Current password is wrong...check once and try again!");
        };
    };

    const onChangePassword = (password: string) => {
        setPassword(password);

        if (password.match(/^$|\s+/)) {
            setPasswordResponce({
                passwordResponceText: en.PASSWORD_CONDITION,
                passwordResponceKey: '',
            });
        } else if (password.length === 0) {
            setPasswordResponce({
                passwordResponceText: '',
                passwordResponceKey: '',
            });
        } else if (password.length < 8) {
            setPasswordResponce({
                passwordResponceText: en.PASSWORD_CONDITION,
                passwordResponceKey: '0',
            });
        } else if (
            password.search(/[a-z]/) < 0 ||
            password.search(/[A-Z]/) < 0 ||
            password.search(/[0-9]/) < 0
        ) {
            setPasswordResponce({
                passwordResponceText: en.PASSWORD_CONDITION,
                passwordResponceKey: '1',
            });
        } else if (password.search(/(?=.*?[#?!@$%^&*-])/) < 0) {
            setPasswordResponce({
                passwordResponceText: en.PASSWORD_CONDITION,
                passwordResponceKey: '2',
            });
        } else {
            // All conditions met, clear the message
            setPasswordResponce({
                passwordResponceText: '',
                passwordResponceKey: '3',
            });
        }
    };

    return (
        <div className="provider-body">
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid" style={{ width: '97.5%' }}>
                        <div className="col-lg-6" style={{ maxWidth: 420 }}>
                            <div className="widget-title">
                                <h4>Reset Password</h4>
                                <p>Your new password must be different from previous passwords.</p>
                                <div className="log-form">
                                    <div className="form-group">
                                        <TKN_PasswordInput
                                            label={'Old Password'}
                                            value={oldpassword}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const { value } = e.target;
                                                setOldPassword(value);
                                                if (value.length < maxLength) {
                                                    setOldPasswordError('');
                                                } else {
                                                    setOldPasswordError(en.CHAR_LIMIT);
                                                }
                                            }}
                                            required={true}
                                            serverError={oldPasswordError}
                                        />
                                        <TKN_PasswordInput
                                            label={'New Password'}
                                            value={password}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const { value } = e.target;
                                                setPassword(value);
                                                if (value.length < maxLength) {
                                                    setPasswordError('');
                                                } else {
                                                    setPasswordError(en.CHAR_LIMIT);
                                                }
                                                onChangePassword(value);
                                            }}
                                            required={true}
                                            serverError={passwordError}
                                        />
                                        <div className={`password-strength ${passwordResponce.passwordResponceKey === '0' ? 'poor-active' : passwordResponce.passwordResponceKey === '1' ? 'avg-active' : passwordResponce.passwordResponceKey === '2' ? 'strong-active' : passwordResponce.passwordResponceKey === '3' ? 'heavy-active' : ''}`} id="passwordStrength">
                                            <span id="poor" className="active"></span>
                                            <span id="weak" className="active"></span>
                                            <span id="strong" className="active"></span>
                                            <span id="heavy" className="active"></span>
                                        </div>
                                        <div className={`password-strength ${passwordResponce.passwordResponceKey === '0' ? 'weak' : passwordResponce.passwordResponceKey === '1' ? 'average' : passwordResponce.passwordResponceKey === '2' ? 'good' : passwordResponce.passwordResponceKey === '3' ? 'great' : ''}`} id="passwordStrength">
                                            <p style={{ color: 'red' }}>{passwordResponce.passwordResponceText}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <TKN_PasswordInput
                                            label={'Confirm Password'}
                                            value={confirmPassword}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const { value } = e.target;
                                                setConfirmPassword(value);
                                                if (value.length < maxLength) {
                                                    setConfirmPasswordError('');
                                                } else {
                                                    setConfirmPasswordError(en.CHAR_LIMIT);
                                                }
                                            }}
                                            required={true}
                                            serverError={confirmPasswordError}
                                        />
                                    </div>
                                    <div className="error-message" style={{ color: 'red', fontSize: 18, marginBottom: '5px' }}>
                                        {serverError}
                                    </div>
                                </div>
                                <button
                                    className="btn btn-primary w-100 login-btn"
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : "Save Change"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mouse-cursor cursor-outer"></div>
                <div className="mouse-cursor cursor-inner"></div>
            </div>
        </div>
    );
};

export default ProviderResetPassword;