import React from 'react'
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import {ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const ProviderDetails = () => {
  const routes = all_routes;
  return (
    <>
  <>
  {/* Breadcrumb */}
  <div className="breadcrumb-bar">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="breadcrumb-title mb-0">John Smith</h2>
        </div>
      </div>
    </div>
  </div>
  {/* /Breadcrumb */}
  {/* Content */}
  <div className="content">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-5">
          <div className="provider-img">
            <ImageWithBasePath
              src="assets/img/provider/provider.png"
              className="img-fluid"
              alt="img"
            />
            <span>10 Years Experience</span>
          </div>
        </div>
        <div className="col-md-7">
          <div className="provider-info">
            <h2>John Smith</h2>
            <h5>Carpenter</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo dolor in reprehenderit in voluptate
              consequat.{" "}
            </p>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="provide-box">
                  <span>
                   <Icon.Mail className="react-feather-custom"/>
                  </span>
                  <div className="provide-info">
                    <h6>Email</h6>
                    <p>thomash@example.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="provide-box">
                  <span>
                   <Icon.Phone className="react-feather-custom"/>
                  </span>
                  <div className="provide-info">
                    <h6>Phone</h6>
                    <p>+1 888 888 8888</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="provide-box">
                  <span>
                    <Icon.MapPin className="react-feather-custom"/>
                  </span>
                  <div className="provide-info">
                    <h6>Address</h6>
                    <p>Hanover, Maryland</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="provide-box">
                  <span>
                   <Icon.Globe className="react-feather-custom"/>
                  </span>
                  <div className="provide-info">
                    <h6>Website</h6>
                    <p>wwww.examplewebsite.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="provide-box">
                  <span>
                    <Icon.BookOpen className="react-feather-custom"/>
                  </span>
                  <div className="provide-info">
                    <h6>Language</h6>
                    <p>English, Arabic</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="social-icon provide-social">
                  <ul>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Instagram className="react-feather-custom" />{" "}
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Twitter className="react-feather-custom" />{" "}
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Youtube className="react-feather-custom" />
                     </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Icon.Linkedin className="react-feather-custom"/>
                     </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="provider-details">
            <h5>Service Details</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt.
            </p>
            <p>
              At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum deleniti atque corrupti quos
              dolores et quas molestias excepturi sint occaecati cupiditate non
              provident, similique sunt in culpa qui officia deserunt mollitia
              animi, id est laborum et dolorum fuga. Et harum quidem rerum
              facilis est et expedita distinctio. Nam libero tempore, cum soluta
              nobis est eligendi optio cumque nihil impedit quo minus id quod
              maxime placeat facere possimus, omnis voluptas assumenda est,
              omnis dolor repellendus.
            </p>
          </div>
          <div className="provider-details provide-area">
            <h5>Areas of Expertise</h5>
            <p>
              At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum deleniti atque corrupti quos
              dolores et quas molestias excepturi sint occaecati cupiditate non
              provident, similique sunt in culpa qui officia deserunt mollitia
              animi, id est laborum et dolorum fuga. Et harum quidem rerum
              facilis est et expedita distinctio. Nam libero tempore, cum soluta
              nobis est eligendi optio cumque nihil impedit quo minus id quod
              maxime placeat facere possimus, omnis voluptas assumenda est,
              omnis dolor repellendus.
            </p>
            <p>
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
              aut fugit, sed quia consequuntur magni dolores eos qui ratione
              voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
              ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia
              non numquam eius modi tempora incidunt ut labore et dolore magnam
              aliquam quaerat voluptatem.
            </p>
            <div className="row">
              <div className="col-lg-2 col-md-4 col-sm-6 d-flex">
                <div className="construct-box flex-fill">
                  <ImageWithBasePath src="assets/img/icons/feature-icon-01.svg" alt="img" />
                  <h6>Construction</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 d-flex">
                <div className="construct-box flex-fill">
                  <ImageWithBasePath src="assets/img/icons/feature-icon-02.svg" alt="img" />
                  <h6>Car Wash</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 d-flex">
                <div className="construct-box flex-fill">
                  <ImageWithBasePath src="assets/img/icons/feature-icon-03.svg" alt="img" />
                  <h6>Electrical</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 d-flex">
                <div className="construct-box flex-fill">
                  <ImageWithBasePath src="assets/img/icons/feature-icon-04.svg" alt="img" />
                  <h6>Cleaning</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 d-flex">
                <div className="construct-box flex-fill">
                  <ImageWithBasePath src="assets/img/icons/feature-icon-05.svg" alt="img" />
                  <h6>Carpentry</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 d-flex">
                <div className="construct-box flex-fill">
                  <ImageWithBasePath src="assets/img/icons/feature-icon-06.svg" alt="img" />
                  <h6>Plumbing</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="provider-details">
            <h5>Benefits of Member</h5>
            <p>
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
              aut fugit, sed quia consequuntur magni dolores eos qui ratione
              voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
              ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia
              non numquam eius modi tempora incidunt ut labore et dolore magnam
              aliquam quaerat voluptatem.
            </p>
            <div className="row">
              <div className="col-md-6">
                <ul>
                  <li>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt.
                  </li>
                  <li>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt.
                  </li>
                  <li>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="mb-0">
                  <li>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt.
                  </li>
                  <li>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt.
                  </li>
                  <li>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Get In Touch */}
          <div className="contact-queries">
            <h2>Get In Touch</h2>
            <form action={routes. contactUs}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <div className="form-icon">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Your Full Name"
                      />
                      <span className="cus-icon">
                      <Icon.User className="react-feather-custom"/>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">Email</label>
                    <div className="form-icon">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Enter Email Address"
                      />
                      <span className="cus-icon">
                       <Icon.Mail className="react-feather-custom"/>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="col-form-label">Phone Number</label>
                    <div className="form-icon">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Phone Number"
                      />
                      <span className="cus-icon">
                       <Icon.Phone className="react-feather-custom"/>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="col-form-label">Message</label>
                    <div className="form-icon form-msg">
                      <textarea
                        className="form-control"
                        rows={4}
                        placeholder="Enter your Comments"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <button className="btn btn-primary" type="submit">
                    Send Message
                    <i className="feather-arrow-right-circle ms-2" />
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/* /Get In Touch */}
        </div>
      </div>
    </div>
  </div>
  {/* /Content */}
</>

    </>

    
  )
}

export default ProviderDetails
