import React from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import ReviewBoxSlider from '../reviewBoxSliders/reviewBoxSlider';

const ReviewBoxSection = () => {
  return (
    <section className="client-section hide">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="section-heading aos" data-aos="fade-up">
              <h2>What our client says</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ReviewBoxSlider />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewBoxSection;