import React, { useEffect, useState } from 'react';
import apiService from '../../../api/authentication-service';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import { useSelector } from 'react-redux';
import { First } from 'react-bootstrap/esm/PageItem';
import { TKN_modal } from '../modal/modal';
import Loader from '../loader_spinner/loader_spinner';
import './style.css'

const PricingPlanSection = () => {
    const [serviceSubscriptions, setServiceSubscriptions] = useState([]);
    const [userrole, setUserRole] = useState<any>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showloader, setShowLoader] = useState<boolean>(true);
    const navigateTo = useNavigate();
    const routes = all_routes;
    const user = useSelector((state: any) => state.user);
    const userid = user.user_id;
    const getUpdatePersonalDetails = () => {
        const payload = {
            first_name: user.first_name,
            last_name: user.last_name,
            gender: user.gender,
            address1: user.address1,
            country: user.country,
            state: user.state,
            city: user.city,
            zipcode: user.zipcode,
            user_id: user.user_id
        }
        apiService.updatePersonalDetails(payload).then((response) => {
            const status = response?.data?.status;
            if (status === true) {
                const user_role = response?.data?.data?.userrole;
                setUserRole(user_role);
            }
        }).catch((error) => {
            console.log("Error = ", error)
        });
    }

    useEffect(() => {
        getUpdatePersonalDetails();
        sendGetAllServiceSubscriptionPackageRequest();
    }, []);

    const sendGetAllServiceSubscriptionPackageRequest = () => {
        apiService.getAllServiceSubscriptionPackages()
            .then((response) => getAllSubscriptionPackageResponse(response?.data))
            .catch((error) => console.log('error', error));
    };

    const getAllSubscriptionPackageResponse = (response: any) => {
        if (response && response.status === true) {

            const allPackages = response?.data;
            setServiceSubscriptions(allPackages || []);
            setShowLoader(false)
        } else {

            setServiceSubscriptions([]);
            setShowLoader(false)
        }
    };

    const handleOnClick = () => {
        if (userrole === 0) {
            navigateTo(routes.vendorSignup);
        } else {
            if (userrole === 3) {
                navigateTo(routes.vendorsubscription);
            } else {
                setShowModal(true);
            }
        }
    }
    const handleCloseButton = () => {
        setShowModal(false)
    }
    return (
        <section className="subscription-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="section-heading">
                            <h2>Pricing Plans</h2>
                            <h5 className='aboutus-subheading'>Choose the best plan that fits your business needs!!!</h5>
                            <p className='aboutus-para'>Access our site for free, no credit card or hidden charges! Vendors can also enjoy an exclusive 3-month free trial on our site.</p>
                        </div>
                    </div>
                </div>
                {showloader ? (

                    <Loader showloader={showloader} />
                ) : (
                    <div className="row justify-content-center">
                        {serviceSubscriptions.map((subPackage: any, index) => (
                            <div className={`col-md-3 pricing-card-box-width ${index === 2 ? 'highlight' : ''}`} key={index}>
                                <div className={`pricing-card ${index === 2 ? 'featured' : ''}`}>
                                    <h1 className="plan-name" style={{ fontSize: '30px' }}>
                                        {subPackage?.service_subscription_name}
                                    </h1>
                                    <div className="price">
                                        <h5 className="inline-new" style={{ color: '#EB1561' }}>${subPackage?.service_subscription_amt}/ month</h5>

                                    </div>
                                    <div className="feature-box" >
                                        <ul className="plan-features">
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>
                                                {subPackage.services_count} Services
                                            </li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>
                                                {subPackage.service_subscription_duration} Month{(subPackage.service_subscription_duration > 1) ? 's' : ''} Validity
                                            </li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>
                                                Email Support
                                            </li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>QR Code</li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>Unlimited Visitors</li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>Unlimited Logins</li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>Service Management</li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>Walk-in Booking</li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>Phone Support</li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>Unlimited Bookings</li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>Unlimited Customers</li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>No Hidden Charges</li>
                                            <li><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>Worldwide Booking</li>
                                        </ul>
                                    </div>
                                    <button className="btn pricingplan-button" onClick={handleOnClick}>Get Started</button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

            </div>
            <TKN_modal handleshowModal={showModal} title={'Feature unavailable'} description={'This feature is not available for Customers..!'} okbutton={'OK'} handleSuccessButton={handleCloseButton} />
        </section >
    );
};

export default PricingPlanSection;
