import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ProviderHeader from '../common/header';
import ProviderSidebar from '../common/sidebar';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import OtpInput from 'react-otp-input';

const routes = all_routes;

const Verification = () => {
  const [otp, setOtp] = useState('');

  return (
    <>
        <ProviderHeader />
        <ProviderSidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              {/* Security Settings */}
              <div className="col-md-12">
                <div className="widget-title">
                  <h4>Profile Verification</h4>
                </div>
                <div className="linked-item">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="linked-acc" id="phone-veri">
                        <span className="link-icon">
                          <ImageWithBasePath
                            src="assets/img/icons/phone-call.svg"
                            alt="Icons"
                          />
                        </span>
                        <div className="linked-info">
                          <h6>
                            <Link to="#">Phone Number Verification</Link>
                          </h6>
                          <p>Not Yet Verified </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-md-end">
                      <div className="verfification-modal-block">
                        <Link
                          to="#"
                          className="link-close"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          data-bs-title="Not Verified"
                        >
                          <i className="fa-solid fa-circle-xmark" />
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary btn-connect"
                          data-bs-toggle="modal"
                          data-bs-target="#change-phone"
                        >
                          Change
                        </Link>
                        <Link to="#" className="btn-acc">
                          Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="linked-item">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="linked-acc">
                        <span className="link-icon">
                          <ImageWithBasePath
                            src="assets/img/icons/mail-envelope.svg"
                            alt="Icons"
                          />
                        </span>
                        <div className="linked-info">
                          <h6>
                            <Link to="#">Email Verification</Link>
                          </h6>
                          <p> Verified </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-md-end">
                      <div className="verfification-modal-block">
                        <Link
                          to="#"
                          className="link-close"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          data-bs-title="Verified"
                        >
                          <i className="fa-solid fa-circle-check" />
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary btn-connect"
                          data-bs-toggle="modal"
                          data-bs-target="#change-mail"
                        >
                          Change
                        </Link>
                        <Link to="#" className="btn-acc">
                          Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="linked-item">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="linked-acc">
                        <span className="link-icon">
                          <ImageWithBasePath
                            src="assets/img/icons/document-pdf.svg"
                            alt="Icons"
                          />
                        </span>
                        <div className="linked-info">
                          <h6>
                            <Link to="#">Document Verification</Link>
                          </h6>
                          <p>Not Yet Verified </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-md-end">
                      <div className="verfification-modal-block">
                        <Link
                          to="#"
                          className="link-close"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          data-bs-title="Not Verified"
                        >
                          <i className="fa-solid fa-circle-xmark" />
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary btn-connect"
                          data-bs-toggle="modal"
                          data-bs-target="#change-document"
                        >
                          Change
                        </Link>
                        <Link to="#" className="btn-acc">
                          Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Security Settings */}
            </div>
          </div>
        </div>
      {/* Change Phone  */}
      <div className="modal fade custom-modal verify-modal" id="change-phone">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header verfication-modal-head">
              <h5 className="modal-title">Verify Your Phone Number</h5>
              <p>You will receive a 4 digit code to verify next</p>
            </div>
            <div className="modal-body">
              <form>
                <div className="wallet-add">
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="phone"
                      type="text"
                      placeholder="Enter Phone Number"
                    />
                  </div>
                  <div className="modal-submit">
                    <Link
                      to=""
                      className="btn btn-primary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#otp"
                    >
                      Change Number
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Change Phone  */}
      {/* OTP  */}
      <div className="modal fade custom-modal verify-modal" id="otp">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header verfication-modal-head">
              <h5 className="modal-title">Enter OTP</h5>
              <p>Code is sent to +1 23456789</p>
            </div>
            <div className="modal-body">
              <form
               
                className="digit-group"
              >
                <div className="wallet-add">
                  <div className="otp-box">
                    <div className="forms-block text-center">
                    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={4}
      renderSeparator={<span> </span>}
      renderInput={(props) => <input {...props} />}
    />
                    </div>
                  </div>
                  <div className="otp-timer text-center">
                    <h6>00:29</h6>
                  </div>
                  <div className="resend-code-otp text-center">
                    <p>
                      Don’t <span> receive </span> code?{' '}
                      <Link to=""> Resend Code </Link>
                    </p>
                  </div>
                  <div className="modal-submit">
                    <Link
                      to=""
                      className="btn btn-primary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#success"
                    >
                      Verify
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* OTP  */}
      {/* Success phone */}
      <div className="modal fade success-modal verify-modal" id="success">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body success-body text-center">
              <span>
                <i className="fa-regular fa-circle-check" />
              </span>
              <h3>Success</h3>
              <p className="success-modal-body">
                Your phone number has been successfully verified
              </p>
            </div>
            <div className="popup-btn text-center">
              <Link to={routes.vendorDashboard} className="btn btn-primary verify-phone">
                Go to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Success  */}
      {/* Change mail  */}
      <div className="modal fade custom-modal verify-modal" id="change-mail">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header verfication-modal-head">
              <h5 className="modal-title">Verify Your Email Address</h5>
              <p>Check your inbox for an verification link</p>
            </div>
            <div className="modal-body">
              <form>
                <div className="wallet-add">
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="phone"
                      type="text"
                      placeholder="Enter email address"
                    />
                  </div>
                  <div className="mail-check-verify">
                    <p>
                      Didnot Receive verification email? Please check your spam
                      folder or try to send the email
                    </p>
                  </div>
                  <div className="modal-submit">
                    <Link
                      to=""
                      className="btn btn-primary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#success-mail"
                    >
                      Continue
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Change Phone  */}
      {/* Success mail */}
      <div className="modal fade success-modal verify-modal" id="success-mail">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body success-body text-center">
              <span>
                <i className="fa-regular fa-circle-check" />
              </span>
              <h3>Success</h3>
              <p className="success-modal-body">
                Your Email address has been successfully verified
              </p>
            </div>
            <div className="popup-btn text-center">
              <Link to={routes.vendorDashboard} className="btn btn-primary verify-mail">
                Go to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Success  */}
      {/* Change Document */}
      <div
        className="modal fade custom-modal verify-modal"
        id="change-document"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header verfication-modal-head">
              <h5 className="modal-title">Verify Your Identity</h5>
              <p>Upload document</p>
            </div>
            <div className="modal-body">
              <form>
                <div className="wallet-add">
                  <div className="form-group">
                    <label>Document Name</label>
                    <input className="form-control" name="phone" type="text" />
                  </div>
                  <div className="file-uploaded-mail">
                    <ImageWithBasePath
                      src="./assets/img/icons/upload-icon-02.svg"
                      className="img-fluid"
                      alt="Upload"
                    />
                    <h4>
                      Drag &amp; drop files or <span> Browse </span>
                    </h4>
                    <p>Supported formats: PDF</p>
                    <input
                      type="file"
                      id="file-input"
                      className="image-upload"
                    />
                  </div>
                  <div className="document-upload-file">
                    <p>
                      {' '}
                      <ImageWithBasePath
                        src="./assets/img/icons/file-pdf.svg"
                        className="img-fluid"
                        alt="Pdf"
                      />{' '}
                      licence.pdf{' '}
                    </p>
                    <span>
                      <Link to="#">
                        <ImageWithBasePath
                          src="./assets/img/icons/trash-alt.svg"
                          className="img-fluid"
                          alt="Trash"
                        />
                      </Link>
                    </span>
                  </div>
                  <div className="document-update-success">
                    <p>
                      <i className="fa-regular fa-circle-check" /> Document
                      uploaded successfully{' '}
                    </p>
                  </div>
                  <div className="modal-submit">
                    <Link
                      to=""
                      className="btn btn-primary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#success-document"
                    >
                      Continue
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Change Phone  */}
      {/* Success mail */}
      <div
        className="modal fade success-modal verify-modal"
        id="success-document"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body success-body text-center">
              <span>
                <i className="fa-regular fa-circle-check" />
              </span>
              <h3>Success</h3>
              <p className="success-modal-body">
                Document is sent for approval for the admin once approved you
                will notified.
              </p>
            </div>
            <div className="popup-btn text-center">
              <Link to={routes.vendorDashboard} className="btn btn-primary verify-document">
                Go to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Success  */}
    </>
  );
};

export default Verification;
