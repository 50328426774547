import React, { useState } from 'react';



interface TKN_messageprops {
    text: any;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

}

const TKN_Success_message: React.FC<TKN_messageprops> = (Props) => {
    return (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
            <i className="fa-solid fa-check"></i>
            <strong style={{ marginLeft: '10px' }}>Success : </strong>{Props.text}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={Props.onClick}></button>
        </div>
    );
}

const TKN_Danger_message: React.FC<TKN_messageprops> = (Props) => {
    return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <i className="fa-solid fa-ban"></i>
            <strong style={{ marginLeft: '10px' }}>Error : </strong>{Props.text}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={Props.onClick} ></button>
        </div>
    );
}
const TKN_Warning_message: React.FC<TKN_messageprops> = (Props) => {
    return (
        <div className="alert alert-warning alert-dismissible fade show" role="alert" >
            <i className="fa-solid fa-triangle-exclamation"></i>
            <strong style={{ marginLeft: '10px' }}>Warning : </strong>{Props.text}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={Props.onClick}></button>
        </div>
    );
}
const TKN_Info_message: React.FC<TKN_messageprops> = (Props) => {
    return (
        <div className="alert alert-info alert-dismissible fade show" role="alert" >
            <i className="fa-solid fa-circle-info"></i>
            <strong style={{ marginLeft: '10px' }}>Info : </strong>{Props.text}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={Props.onClick}></button>
        </div>
    );
}

export { TKN_Success_message, TKN_Danger_message, TKN_Info_message, TKN_Warning_message }